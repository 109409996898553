import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    height: 550,
    borderRadius: '8px',
    display: 'flex',
    //alignItems: "stretch",
    flexDirection: 'column',
    backgroundColor: '#1E1E1E;',
    padding: '2rem 2rem',
    wordBreak: 'keep-all!important',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.down(440)]: {
      width: 330,
    },
  },
  modalTitle: {
    fontSize: '1.8rem',
    color: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  separater: {
    [theme.breakpoints.down(440)]: {
      margin: '1rem 0',
    },
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    margin: '1.5rem 0',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  inputNameContainer: {
    [theme.breakpoints.down(440)]: {
      width: '7.5rem',
    },
    width: '9rem',
    display: 'flex',
    alignItems: 'center',
  },
  inputName: {
    width: '6rem',
    fontSize: '0.8rem',
    alignItems: 'flex-start',
    color: '#fff',
  },
  inputButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#818181',
    padding: '0.2rem',
  },
  inputButton: {
    [theme.breakpoints.down(440)]: {
      width: '2.5rem',
    },
    width: '4rem',
    fontSize: '0.8rem' /* Ensure the font size fits in the buttons */,
    textTransform: 'none' /* Prevent uppercase transformation */,
    whiteSpace: 'nowrap' /* Prevent text from wrapping */,
    backgroundColor: '#818181!important',
    color: '#000!important',
    border: 'none!important',
  },
  selected: {
    backgroundColor: '#F0F0F0!important',
    borderRadius: '0.2rem!important',
  },
  sliderContainer: {
    [theme.breakpoints.down(440)]: {
      width: '8rem',
    },
    width: '12rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  slider: {
    '& .MuiSlider-valueLabel': {
      fontSize: '0.8rem',
      top: -20,
      backgroundColor: 'unset',
      color: theme.palette.text.primary,
      '&::before': {
        display: 'none',
      },
      '& *': {
        background: 'transparent',
        color: '#fff',
      },
    },
    '& .MuiSlider-mark': {
      backgroundColor: '#515151',
    },
    '& .MuiSlider-markActive': {
      backgroundColor: '#CAFF73',
    },
    margin: '0.5rem',
  },
  upgradeDialog: {
    '& .MuiPaper-root': {
      backgroundColor: '#1E1E1E',
      padding: '19px 24px',
      borderRadius: '24px',
    },
  },
  dialogTitle: {
    color: '#fff',
    textAlign: 'center',
    fontSize: '15px',
    paddingTop: '8px',
  },
  dialogText: {
    color: '#AAAAAA',
    textAlign: 'center',
    fontWeight: 300,
    fontSize: '14pz',
    paddingTop: '9px',
    maxWidth: '288px',
  },
  upgradeButton: {
    width: 'fit-content',
    padding: '10px 16px',
    fontSize: '12px',
    backgroundColor: '#CAFF73',
    borderRadius: '3px',
    cursor: 'pointer',
    margin: '0 auto',
    marginTop: '1rem',
  },
  tooltipImg: {
    width: '20px',
    margin: '0 10px 0 10px',
  },
  tooltipTextCustom: {
    [theme.breakpoints.down(440)]: {
      fontSize: '12px',
      margin: '2px 0',
      lineHeight: '15px',
    },
    fontSize: '15px',
    fontWeight: '300',
    margin: '4px 0',
    color: '#FFFFFF',
    lineHeight: '18px',
  },
  indentation: { marginLeft: '16px' },
}));

export { useStyles };
