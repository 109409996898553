import React from 'react';
import { useDispatch } from 'react-redux';
import { appActions } from '../../actions/app.actions';
import { useStyles } from './MessageModalV2.styles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import textLogoWhite from '../../img/textLogo.png';
import BaseModalLayout from './BaseModalLayout';

export default function MessageModalV2(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const onClose = () => {
    if (props.reloadOnClose) {
      window.location.reload();
    } else {
      dispatch(appActions.closeMessageModalV2());
    }
  };

  return (
    <BaseModalLayout open={!!props.open} onClose={onClose} sizeMid>
      {props.showHeaderLogo && <img className={classes.sorisoriAIImg} src={textLogoWhite} alt="sorisoriAI" />}
      <div className={classes.messageOneContainer}>
        <div className={classes.messageOne}>{props.message}</div>
      </div>
      {props.showProfileButton && (
        <div
          className={classes.button}
          onClick={() => {
            navigate('/profile');
            dispatch(appActions.closeMessageModalV2());
          }}
        >
          {t('modal.gotoProfile')}
        </div>
      )}
    </BaseModalLayout>
  );
}
