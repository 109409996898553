import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.5rem',
    },
    paddingLeft: '0.2rem',
    fontSize: '2.5rem',
    color: '#fff',
  },
  separater: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    margin: '1.5rem 0',
  },
  sliderContainer: {
    paddingTop: '3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioButtonContainer: {
    [theme.breakpoints.down('sm')]: {
      height: '4rem',
    },
    display: 'flex!important',
    flexDirection: 'row!important',
    alignItems: 'center',
    justifyContent: 'start',
    height: '1rem',
  },
  radioButtonLabel: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.85rem!important',
    },
    marginRight: '1rem',
    fontWeight: '300',
    fontSize: '1.1rem!important',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
  },
  sliderText: {
    width: '9rem',
    color: '#fff',
    fontWeight: '300',
    display: 'flex',
    alignItems: 'center',
  },
  tooltipImg: {
    marginLeft: '0.5rem',
    width: '1.3rem',
  },
  tooltipContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '1rem',
    color: '#161616',
  },
  tooltipHeader: {
    alignSelf: 'center',
    fontSize: '1.5rem',
    fontWeight: '500',
    marginBottom: '1rem',
  },
  tooltipSubeader: {
    marginTop: '0.8rem',
    marginBottom: '0.2rem',
    fontSize: '1.1rem',
    fontWeight: '500',
  },
  tooltipTextContainer: {
    display: 'flex',
  },
  tooltipText: {
    fontSize: '1.1rem',
    fontWeight: '300',
    margin: '0.3rem 0',
    lineHeight: '1.3rem',
  },
  tooltipTextLink: {
    fontSize: '1rem',
    fontWeight: '500',
    margin: '0.3rem 0',
    lineHeight: '1.3rem',
    color: 'inherit',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  tooltipTextNumber: {
    marginRight: '0.2rem',
  },
  tooltipTextBullet: {
    marginLeft: '0.5rem',
    marginRight: '0.8rem',
  },
  stepHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.4rem',
    },
    fontSize: '1.6rem',
    marginBottom: '0.5rem',
    color: '#fff',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
  },
  stepSubHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.83rem',
    },
    marginTop: '0.4rem',
    fontSize: '1rem',
    color: '#fff',
    fontWeight: '300',
  },
  closeIcon: {
    position: 'relative',
    width: '1.8rem',
    height: '1.8rem',
    marginRight: '0.3rem',
    marginBottom: '0.6rem',
  },
  inputSourceButtonContainer: {
    marginTop: '1rem',
    backgroundColor: '#818181',
    padding: '0.2rem',
    //width: "27rem",
    maxWidth: '100%',
  },
  inputSourceButton: {
    [theme.breakpoints.down('xs')]: {
      padding: '0.2rem 1rem!important',
    },
    fontWeight: '400',
    backgroundColor: '#818181!important',
    color: '#000!important',
    padding: '0.2rem 2rem!important',
    border: 'none!important',
  },
  selected: {
    backgroundColor: '#F0F0F0!important',
    borderRadius: '0.2rem!important',
  },
  dragAndDrop: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0.2rem 0',
    justifyContent: 'center',
  },
  selectModelContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem 0',
    justifyContent: 'center',
    '&:hover': {
      borderColor: '#fff',
      color: '#fff',
      textColor: '#fff',
      fontSize: '40px',
    },
  },
  dragAndDropText: {
    fontWeight: '300',
    fontSize: '1rem',
    color: '#8A8A8A',
  },
  dragAndDropButton: {
    marginTop: '0.8rem',
    fontWeight: '300',
    fontSize: '1rem',
    color: '#F2F2F2',
    backgroundColor: '#202020',
    borderRadius: '0.4rem',
    padding: '0.5rem 1.5rem',
    color: '#1D1D1D',
    backgroundColor: '#F0F0F0',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonRemainingText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.6rem',
      height: '1.2rem',
      paddingTop: '0.15rem',
      marginLeft: '0.4rem',
    },
    paddingTop: '0.2rem',
    height: '1.3rem',
    fontSize: '0.7rem',
    marginLeft: '0.8rem',
    marginRight: '0.2rem',
    color: '#fff',
    justifyContent: 'center',
    alighItem: 'center',
  },
  buttonSubText: {
    fontWeight: '300',
    marginTop: '0.5rem',
    color: '#fff',
    fontSize: '0.9rem',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      padding: '0.8rem',
      fontSize: '0.8rem',
      width: '5.5rem',
      height: '2.5rem',
    },
    width: '7rem',
    height: '2.8rem',
    backgroundColor: '#CAFF73',
    padding: '0.7rem',
    fontSize: '0.9rem',
    borderRadius: '8px',
    cursor: 'pointer',
    marginLeft: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    //flexWrap: 'wrap',
  },
  buttonGrid: {
    display: 'flex',
    justifyContent: 'start',
    marginBottom: '5rem',
    marginLeft: '1rem',
  },
  adminButtonGrid: {
    marginBottom: '0',
  },
  laodingContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  laodingText: {
    marginLeft: '1rem',
    color: '#fff',
    fontWeight: '300',
  },
  recordingButton: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '8.8rem',
    },
    maxWidth: '11rem',
    padding: '0.7rem 0rem',
    width: '100%',
    borderRadius: '0.5rem',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  recordContainer: {
    marginTop: '1rem',
  },
  recordButtonContainer: {
    //zindex: -1,
    width: '26.2rem',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
  selectModelOuterContainer: {
    //zindex: -1,
    maxWidth: '49rem',
    [theme.breakpoints.down('sm')]: {},
    height: '6.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    //marginTop: '1rem',
    '&:hover': {
      '& $selectModelButton': {
        borderColor: '#fff',
      },
      '& $dragAndDropText': {
        fontSize: '1.15rem',
        color: '#fff',
      },
      '& $addButtonImg': {
        fontSize: '1.6rem',
      },
    },
  },
  youtubeInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: '#fff',
    fontWeight: '400',
  },
  youtubeInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  youtubeInfoDeleteIcon: {
    width: '1.2rem',
    marginLeft: '0.5rem',
    cursor: 'pointer',
  },
  youtubeLinkInput: {
    maxWidth: '20rem',
    width: '100%',
    height: '2.5rem',
    backgroundColor: '#fff',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 9,
    borderBottomLeftRadius: 9,
  },
  imgContainer: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0.5rem',
    },
    width: '28rem',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '0.8rem',
    marginTop: '2rem',
    marginBottom: '1rem',
  },
  mdxContainer: {
    paddingTop: '1rem!important',
    paddingBottom: '0!important',
  },
  noMargin: {
    margin: '0',
  },
  noMargin: {
    margin: '0',
  },
  addButtonImg: {
    border: 1,
    stroke: '#ffffff',
    strokeWidth: 1,
    color: '#303030',
  },
  selectModelButton: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: '8px',
    borderColor: '#303030',
    borderStyle: 'dashed',
    color: '#bdbdbd',
    //outline: "none",
    //transition: "border .24s ease-in-out",
    fontSize: '14px',
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: '#141414',
  },

  cardGrid: {
    //border: "1px solid #fafafa",
    columnGap: '15px',
  },
  card: {
    //justifyContent: "stretch",
    display: 'flex',
    border: '0.1rem solid #fafafa',
    backgroundColor: '#161616',
    padding: '.7rem',
    paddingBottom: '.5rem',
    borderRadius: '0.5rem',
    color: '#fff',
    fontSize: '0.9rem',
    fontWeight: '400',
    maxWidth: '49rem',
    //marginBottom: '1rem',
    //marginRight: "1rem",
    minHeight: '2rem',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '49rem',
      marginRight: '0',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
    },
    [theme.breakpoints.down(476)]: {
      fontSize: '0.75rem',
      padding: '.4rem',
      paddingBottom: '.3rem',
    },
  },
  cardSecondColumn: {
    flex: 'vertical',
  },
  cardLabelUsername: {
    display: 'grid',

    //placeItems: "center",
    textOverflow: 'ellipsis',
  },
  cardLabel: {
    flex: 1,
    marginLeft: '0.6rem',
    display: 'flex',
    alignItems: 'top',
    fontSize: '1.3rem',
    flexWrap: 'wrap',
    height: '3.02rem',
    display: 'inline-block',
    overflow: 'hidden',
    overflowWrap: 'break-word',
    //whiteSpace: "nowrap",
    //textOverflow: "ellipsis",
  },
  cardUsername: {
    alightItems: 'flex-end',
    display: 'flex',
    marginLeft: '0.6rem',
    flex: 1,
    fontSize: '0.9rem',
    textOverflow: 'ellipsis',
    color: '#d3d3d3',
    height: '0.8rem',
  },
  editIcon: {
    width: '1.2rem!important',
    marginLeft: '0.3rem',
    cursor: 'pointer',
  },
  cardTimestamp: {
    //marginTop: "3.3rem",
    alightItems: 'bottom',
    display: 'flex',
    marginLeft: '0.6rem',
    flex: 1,
    fontSize: '0.7rem',
    color: '#d3d3d3',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    //whiteSpace: "nowrap",
  },
  cardLikes: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: '1.2rem',
  },
  cardBottomRight: {
    justifyContent: 'flex-end',
  },
  numberPlayed: {
    color: '#d3d3d3',
    fontSize: '0.8rem',
    //marginLeft: "",
    //marginTop: ".2rem",
    marginLeft: '0.3rem',
  },
  creations: {
    color: '#d3d3d3',
    fontSize: '0.6rem',
    //marginLeft: "",
    //marginTop: ".2rem",
    marginLeft: '.2rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginBottom: '0.07rem',
    //textOverflow: "ellipsis",
  },
  creationsGrid: {
    alignItems: 'flex-end',
    marginBotton: '0.5rem',
  },
  cardStatus: {
    textAlign: 'center',
    marginLeft: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: '0.8rem',

    [theme.breakpoints.down('xs')]: {
      maxWidth: '4rem',
      marginLeft: '0rem',
    },
  },

  cardAction: {
    cursor: 'pointer',
    width: '2.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '0.3rem',
    [theme.breakpoints.down('xs')]: {
      width: '2.5rem',
    },
  },
  imageContainer: {
    alignItems: 'flex-end',
    diplay: 'flex',
  },
  coverImg: {
    userSelect: 'none',
    width: '5.5rem',
    height: '5.5rem',
    display: 'inline-block',
    fill: 'currentcolor',
    flexShrink: '1',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontSize: '1.5rem',
    borderRadius: '0.3rem',
    border: '0.07rem solid #808080',
  },
  playButtonImg: {
    userSelect: 'none',
    //display: "inline-block",
    //marginLeft: "0.5rem",
    //fill: "currentcolor",
    flexShrink: '0',
    //transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontSize: '50px',
    cursor: 'pointer',
    borderRadius: '0.1rem',
  },
  playButtonGrid: {
    direction: 'row',
    justifyContent: 'flex-end',
  },
  likeButtonImg: {
    marginTop: '3.2rem',
    userSelect: 'none',
    display: 'inline-block',
    marginLeft: '0.5rem',
    //fill: "currentcolor",
    flexShrink: '0',
    //transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontSize: '2px',
    cursor: 'pointer',
    borderRadius: '0.1rem',
  },
  favoriteImg: {
    userSelect: 'none',
    //display: "inline-block",
    //fill: "currentcolor",
    flexShrink: '0',
    //transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontSize: '2rem',
    cursor: 'pointer',
    borderRadius: '0.1rem',
  },
  favoriteFullImg: {
    userSelect: 'none',
    //display: "inline-block",
    //fill: "currentcolor",
    flexShrink: '0',
    //transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontSize: '2rem',
    borderRadius: '0.1rem',
    //color: "#ff0000",
    color: '#d32f2f',
  },
  numberLikes: {
    //marginTop: "3.5rem",
    fontSize: '0.8rem',
    marginLeft: '.1rem',
    marginRight: '.1rem',
  },
  cancelButtonImg: {
    cursor: 'pointer',
  },
  ttsTextArea: {
    width: '49rem',
    [theme.breakpoints.down('sm')]: {
      //width: '100%',
      //marginRight: '0',
    },
    fontSize: '16px',
    backgroundColor: 'white',
    color: 'black',
    padding: '12px',
    borderRadius: '8px',
    marginLeft: '14px',
    marginRight: '15px',
    marginBottom: '16px',
    resize: 'vertical',
  },
  settingGrid: {},
  settingButton: {
    [theme.breakpoints.down('xs')]: {
      height: '2.5rem',
      fontSize: '0.8rem',
      width: '5.5rem',
    },
    height: '2.8rem',
    width: '7rem',
    backgroundColor: '#141414',
    padding: '0.5rem',
    fontSize: '0.8rem',
    borderRadius: '8px',
    cursor: 'pointer',
    marginRight: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    border: '1px solid #fafafa',
  },
  settingTypo: {
    [theme.breakpoints.down(600)]: {
      fontSize: '0.7rem !important',
      marginLeft: '0.2rem',
    },
    marginLeft: '0.5rem',
    fontSize: '0.8rem !important',
    color: '#fff',
  },
  submitTypo: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
    },
    fontSize: '0.8rem',
  },
  tuneIcon: {
    '& svg': {
      fontSize: '20rem',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '1.3rem',
    },
    color: '#fff',
  },
}));

export { useStyles };
