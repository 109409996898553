import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './EditLabelModal.styles.js';
import CircularProgress from '@mui/material/CircularProgress';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useDispatch } from 'react-redux';
import { labelLength, tabPrefix } from '../../constants/app.constants.js';
import BaseModalLayout from './BaseModalLayout.js';
import { showMessageV2 } from '../../utils/page.utils.js';

export default function EditLabelModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [label, setLabel] = useState('');

  const { loading, alignment } = props;
  const dispatch = useDispatch();

  useEffect(() => setLabel(props.label), [props.label]);

  const onClick = res => {
    if (!res) return props.onClose();

    const maxLength = labelLength[alignment];
    if (label.length < maxLength) {
      props.onEdit(label);
    } else {
      const messageKey = `${tabPrefix[alignment]}.modal.longLabel`;
      showMessageV2(dispatch, t(messageKey));
    }
  };
  return (
    <BaseModalLayout {...props} sizeMax>
      {!loading && (
        <>
          <div className={classes.label}>
            <OutlinedInput
              onChange={e => setLabel(e.target.value)}
              value={label}
              className={classes.labelInput}
              style={{ height: '40px' }}
            />
          </div>
          <div className={classes.buttonContainer}>
            <div className={`${classes.button} ${classes.yesButton}`} onClick={() => onClick(true)}>
              {t('historyTab.changeButton')}
            </div>
            <div className={`${classes.button} ${classes.noButton}`} onClick={() => onClick(false)}>
              {t('historyTab.cancelButton')}
            </div>
          </div>
        </>
      )}
      {loading && <CircularProgress className={classes.loading} />}
    </BaseModalLayout>
  );
}
