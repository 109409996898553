import React from 'react';
import { useStyles } from './FreeTrainingMessageModal.styles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BaseModalLayout from './BaseModalLayout';

export default function FreeTrainingMessageModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const messages = t('freeTrainingMessageModal.messages', { returnObjects: true });

  return (
    <BaseModalLayout {...props} showHeaderLogo>
      <div className={classes.messageContainer}>
        {messages.map(message => (
          <div className={classes.message}>{message}</div>
        ))}
      </div>
      <div className={classes.button} onClick={() => navigate('/subscription')}>
        {t('freeTrainingMessageModal.button')}
      </div>
    </BaseModalLayout>
  );
}
