import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  loading: {
    alignSelf: 'center',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: '3px',
    borderRadius: '3px',
    color: '#c4c4c4',
    height: '40px',
    width: '135px',
    fontSize: '15px',
  },
  yesButton: {
    margin: '0 8px 0 0',
    border: '1px solid #c4c4c4',
    color: '#191919',
    backgroundColor: '#c4c4c4',
    '&:hover': {
      background: '#d1fe75',
      color: '#1e1e1e',
      border: '1px solid #d1fe75',
    },
  },
  noButton: {
    margin: '0 0 0 8px',
    border: '1px solid #a4a4a4',
    '&:hover': {
      background: '#d1fe75',
      color: '#1e1e1e',
      border: '1px solid #d1fe75',
    },
  },
  buttonContainer: {
    display: 'flex',
  },
  label: {
    display: 'flex',
    marginBottom: '32px',
    marginTop: '10px',
  },
  labelInput: {
    flex: '1',
    backgroundColor: '#fff',
    width: '285px',
    outline: 'none',
    borderRadius: '6px',
    marginTop: '5px',
    fontSize: '13px!important',
  },
}));

export { useStyles };
