import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  languageButton: {
    fontSize: '16px',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    cursor: 'pointer',
  },
}));

export { useStyles };
