import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { useDropzone } from 'react-dropzone';
import 'react-jinke-music-player/assets/index.css';
import HtmlTooltip from '../HtmlTooltip';
import tooltipImg from '../../img/tooltip.png';

import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import CircularProgress from '@material-ui/core/CircularProgress';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { AudiotrackSharp as AudioIcon, Delete as DeleteIcon } from '@material-ui/icons';

import {
  fetchSubscriptionInfo,
  getYoutubeInfo,
  checkVocalExtractCompletion,
  uploadVocalExtract,
  uploadYoutubeVocalExtract,
  checkVocalExtractCompletionById,
  checkVocalExtractCompletionByIdArr,
  checkValidUploadStatusVocalExtract,
} from '../../services/page.services';
import { useStyles } from './VocalExtractorPage.styles';
import { dragAndDropStyle } from './shared.styles';
import { checkValidLoginStatus } from '../../utils/user.utils';
import { appActions } from '../../actions/app.actions.js';
import { userActions } from '../../actions/user.actions.js';
import { getFilesDuration, showMessageV2 } from '../../utils/page.utils';
import FreeVocalExtractorMessageModal from '../layout/FreeVocalExtractorMessageModal.js';
import { labelLength, statusCheckInterval } from '../../constants/app.constants.js';

function VocalExtractorPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const userId = user?.id;
  const subscriptionTypes = ['PREMIUM', 'PRO', 'ADMIN', 'CUSTOM'];

  const intervalIDRef = React.useRef(null);
  const [loadingText, setLoadingText] = useState('');
  const [alignment, setAlignment] = React.useState('upload');
  const [vocalExtractRemainingCount, setVocalExtractRemainingCount] = useState();
  const [vocalExtractInProgress, setVocalExtractInProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState({});

  const [outputFormat, setOutputFormat] = React.useState('mp3');
  const [showOutputFormatTooltip, setShowOutputFormatTooltip] = useState(false);

  const [fileUploadNum, setFileUploadNum] = useState(0);
  const [maxUploadNum, setMaxUploadNum] = useState(0);
  const [files, setFiles] = useState([]);

  const [openFreeVocalExtractorModal, setOpenFreeVocalExtractorModal] = useState(false);

  const [label, setLabel] = useState([]);

  useEffect(() => () => intervalIDRef.current && clearInterval(intervalIDRef.current), []);

  useEffect(() => {
    if (userId) (async () => initPage())();
  }, [userId]);

  useEffect(() => {
    if (content?.name) {
      setLabel([handleLabel(content.name)]);
    } else if (files.length) {
      const copiedFileLabel = files.map(file => {
        return handleLabel(file.name);
      });
      setLabel(copiedFileLabel);
    } else {
      setLabel([]);
    }
  }, [content, files]);

  const checkVocalExtractInProgress = async () => {
    let vocalExtract = await checkVocalExtractCompletion();
    const inProgress = _.some(vocalExtract, { status: 'PENDING' });
    setVocalExtractInProgress(inProgress);
  };

  const checkRemainingCount = async () => {
    const subscriptionInfo = await fetchSubscriptionInfo();
    dispatch(userActions.updateSubscription(subscriptionInfo));
    const vocalExtractRemaining = subscriptionInfo?.vocalExtractRemaining;
    setVocalExtractRemainingCount(vocalExtractRemaining || 0);

    const type = subscriptionInfo?.type;
    let maxUploadNum;
    if (type === 'PREMIUM' || type === 'ADMIN' || type === 'CUSTOM') maxUploadNum = 10;
    else if (type === 'PRO') maxUploadNum = 5;
    else maxUploadNum = 1;
    setMaxUploadNum(maxUploadNum);
    setFileUploadNum(Math.min(vocalExtractRemaining, maxUploadNum));
    return subscriptionInfo;
  };

  const initPage = async () => {
    try {
      await checkVocalExtractInProgress();
      await checkRemainingCount();
    } catch (e) {
      showMessageV2(dispatch, t('modal.pageLoadFail'), { reloadOnClose: true });
    }
  };

  const onDropAccepted = async acceptedFiles => {
    if (checkValidLoginStatus(userId, dispatch) && acceptedFiles.length > 0) {
      const maxDuration = await getFilesDuration(files.concat(acceptedFiles));
      const type = user.subscription?.type;
      const fileNum = files.length + acceptedFiles.length;

      if (maxDuration > 3600) {
        showMessageV2(dispatch, t('vocalExtractorTab.modal.exceedMaxDuration'));
      } else if ((type === 'PREMIUM' || type === 'ADMIN' || type === 'CUSTOM') && fileNum > maxUploadNum) {
        dispatch(appActions.openCustomMessageModal('vocalExtractorManyFilesError', [10]));
      } else if (type === 'PRO' && fileNum > maxUploadNum) {
        dispatch(appActions.openCustomMessageModal('vocalExtractorManyFilesError', [5]));
      } else if ((type === 'BASIC' || type === 'FREE') && fileNum > maxUploadNum) {
        setOpenFreeVocalExtractorModal(true);
      } else if (fileNum > fileUploadNum) {
        showMessageV2(dispatch, t('vocalExtractorTab.modal.lackRemaining'));
      } else {
        setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
      }
    }
  };

  const onDropRejected = async input => {
    if (checkValidLoginStatus(userId, dispatch)) {
      const type = user.subscription?.type;
      const error = input[0].errors[0].code;

      const message = (() => {
        switch (error) {
          case 'file-invalid-type':
            return t('vocalExtractorTab.modal.notSupportedFileType');
          case 'file-too-large':
            return t('vocalExtractorTab.modal.exceedMaxSize');
        }
      })();

      if (message) {
        showMessageV2(dispatch, message);
      }

      if (error === 'too-many-files') {
        if (type === 'FREE' || type === 'BASIC') setOpenFreeVocalExtractorModal(true);
        else if (fileUploadNum < maxUploadNum) showMessageV2(dispatch, t('vocalExtractorTab.modal.lackRemaining'));
        else if (type === 'PREMIUM' || type === 'ADMIN' || type === 'CUSTOM') {
          dispatch(appActions.openCustomMessageModal('vocalExtractorManyFilesError', [10]));
        } else if (type === 'PRO') {
          dispatch(appActions.openCustomMessageModal('vocalExtractorManyFilesError', [5]));
        }
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'audio/mp3': ['.mp3'],
      'audio/wav': ['.wav'],
      'audio/mpeg-4': ['.m4a'],
      'audio/flac': ['.flac'],
      'audio/ogg': ['.ogg'],
      // "video/mpeg-4":[".mp4"],
      // "video/x-matroska ":[".mkv"],
    },
    maxFiles: fileUploadNum,
    maxSize: 78643200,
    onDropAccepted,
    onDropRejected,
    disabled: loadingText,
    multiple: true,
  });

  const checkYoutubeLink = async () => {
    setLoading(true);
    try {
      const { duration, valid, name } = await getYoutubeInfo(content.youtubeLink);
      if (!valid) {
        setContent({ ...content, youtubeLink: '', name: null });
        showMessageV2(dispatch, t('vocalExtractorTab.modal.invalidYoutubeLink'));
      } else if (duration > 600) {
        setContent({ ...content, youtubeLink: '', name: null });
        showMessageV2(dispatch, t('vocalExtractorTab.modal.youtubeLinkTooLong'));
      } else {
        setContent({
          ...content,
          youtubeInfo: { duration, name },
          name,
        });
      }
    } catch (e) {
      showMessageV2(dispatch, t('vocalExtractorTab.submit.tryAgain'));
    } finally {
      setLoading(false);
    }
  };

  const setUploadType = uploadType => {
    setAlignment(uploadType);
    setContent({
      ...content,
      youtubeLink: '',
      file: null,
      youtubeInfo: null,
      name: null,
    });
    setFiles([]);
    setLabel([]);
  };

  const checkCompleteForm = () => {
    let message = '';
    if (vocalExtractInProgress) {
      message = t('vocalExtractorTab.modal.inProgress');
    } else if (vocalExtractRemainingCount <= 0) {
      message = t('vocalExtractorTab.modal.noRemaining');
    } else if (alignment === 'youtube' && !content.youtubeInfo) {
      message = t('vocalExtractorTab.modal.emptyYoutubeLink');
    } else if (alignment === 'upload' && files.length === 0) {
      message = t('vocalExtractorTab.modal.noFiles');
    } else if (label[0].length === 0) {
      message = t('vocalExtractorTab.modal.noLabel');
    } else if (label[0].length > labelLength.vocalExtract) {
      message = t('vocalExtractorTab.modal.longLabel');
    }

    if (message) {
      showMessageV2(dispatch, message);
      return false;
    }
    return true;
  };

  const handleSubmit = async alignment => {
    // frontend check
    if (!checkCompleteForm()) return;

    // backend check
    const fileNum = alignment === 'upload' ? files.length : 1;
    const uploadStatus = await checkValidUploadStatusVocalExtract(fileNum);
    if (!uploadStatus.valid) {
      showMessageV2(dispatch, t('vocalExtractorTab.modal.uploadStatusNotValid'));
      await initPage();
      return;
    }

    if (alignment === 'upload') {
      const vocalExtractId = [];
      const failedLabel = [];
      const processFiles = async () => {
        for (const [index, file] of files.entries()) {
          if (index === 0) setLoadingText(t('vocalExtractorTab.submit.uploading'));
          const uploadLabel = label[index];
          const sourceLabel = file.name;
          try {
            const vocalExtract = await uploadVocalExtract(userId, file, uploadLabel, sourceLabel, outputFormat);
            vocalExtractId.push(vocalExtract.id);
          } catch (e) {
            console.log(e);
            failedLabel.push(sourceLabel);
          }
          if (index === 0) setLoadingText(t('vocalExtractorTab.submit.inProgress'));
        }
      };
      await processFiles();

      if (failedLabel.length > 0) {
        console.log(failedLabel);
        dispatch(
          appActions.openCustomMessageModal('vocalExtractFailed', [t('vocalExtractorTab.modal.tryAgain'), failedLabel])
        );
      }

      if (vocalExtractId.length > 0) {
        const tick = async () => {
          try {
            const status = await checkVocalExtractCompletionByIdArr(vocalExtractId);
            if (!status.includes('PENDING')) {
              clearInterval(intervalIDRef.current);
              await initPage();
              setLoadingText('');
            }
          } catch (e) {}
        };
        intervalIDRef.current = setInterval(tick, statusCheckInterval);
      } else {
        await initPage();
        setLoadingText('');
      }
    } else if (alignment === 'youtube') {
      let vocalExtract;
      const uploadLabel = label[0];
      setLoadingText(t('vocalExtractorTab.submit.uploading'));
      try {
        vocalExtract = await uploadYoutubeVocalExtract(userId, content, uploadLabel, outputFormat);
      } catch (e) {
        showMessageV2(dispatch, t('vocalExtractorTab.submit.tryAgain'));
        await initPage();
        setLoadingText('');
        return;
      }
      setLoadingText(t('vocalExtractorTab.submit.inProgress'));

      if (vocalExtract) {
        const tick = async () => {
          const id = vocalExtract.id;
          try {
            const status = await checkVocalExtractCompletionById(id);
            if (status !== 'PENDING') {
              clearInterval(intervalIDRef.current);
              await initPage();
              setLoadingText('');
            }
          } catch (e) {}
        };
        intervalIDRef.current = setInterval(tick, statusCheckInterval);
      }
    }
  };

  const handleWavButtonClick = () => {
    if (checkValidLoginStatus(userId, dispatch)) {
      if (!subscriptionTypes.includes(user.subscription?.type)) {
        setShowOutputFormatTooltip(true);
      } else {
        setOutputFormat('wav');
        setShowOutputFormatTooltip(false);
      }
    }
  };

  const uploadTextMap = {
    10: t('vocalExtractorTab.stepOne.maxUploadNumText.premiumCustomAdmin'),
    5: t('vocalExtractorTab.stepOne.maxUploadNumText.pro'),
    1: t('vocalExtractorTab.stepOne.maxUploadNumText.basicFree'),
    0: '',
  };

  const handleLabel = name => {
    let lastDotIndex = name.lastIndexOf('.');
    if (lastDotIndex == -1) lastDotIndex = name.length;
    const maxIndex = Math.min(labelLength.vocalExtract, lastDotIndex);
    return name.substring(0, maxIndex).trim();
  };

  const classes = useStyles();
  return (
    <div>
      <FreeVocalExtractorMessageModal
        open={openFreeVocalExtractorModal}
        onClose={() => setOpenFreeVocalExtractorModal(false)}
      />
      <div className={classes.pageTitle}>{t('vocalExtractorTab.title')}</div>
      <div className={classes.separater} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.stepHeader}>{t('vocalExtractorTab.stepOne.title')}</div>
          <div className={classes.stepSubHeader}>{t('vocalExtractorTab.stepOne.vocalExtractorSubTitles.0')}</div>
          <ButtonGroup className={classes.inputSourceButtonContainer}>
            <Button
              className={`${classes.inputSourceButton} ${alignment == 'upload' ? classes.selected : ''}`}
              onClick={() => setUploadType('upload')}
            >
              {t('vocalExtractorTab.stepOne.tabs.fileUpload.title')}
            </Button>
            <Button
              className={`${classes.inputSourceButton} ${alignment == 'youtube' ? classes.selected : ''}`}
              onClick={() => setUploadType('youtube')}
            >
              {t('vocalExtractorTab.stepOne.tabs.youtube.title')}
            </Button>
          </ButtonGroup>
          {alignment === 'upload' && (
            <HtmlTooltip
              title={
                <div className={'globalTooltipContainer'}>
                  <div className={'globalTooltipTextContainer'}>
                    <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                    <div className={'globalTooltipText'}>{t('vocalExtractorTab.stepOne.tooltip.contents.0')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                    <div className={'globalTooltipText'}>{t('vocalExtractorTab.stepOne.tooltip.contents.1')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                    <div className={'globalTooltipText'}>{t('vocalExtractorTab.stepOne.tooltip.contents.2')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                    <div className={'globalTooltipText'}>{t('vocalExtractorTab.stepOne.tooltip.contents.3')}</div>
                  </div>
                </div>
              }
            >
              <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
            </HtmlTooltip>
          )}
          {alignment === 'upload' && (
            <>
              <div className={classes.recordButtonContainer}>
                <div {...getRootProps({ style: dragAndDropStyle })}>
                  <input {...getInputProps()} />
                  <div className={classes.dragAndDrop}>
                    <div className={classes.dragAndDropText}>
                      <div>{uploadTextMap[maxUploadNum]}</div>
                      <div>
                        {t('vocalExtractorTab.stepOne.tabs.fileUpload.dragAndDropText')}
                        {t('vocalExtractorTab.stepOne.tabs.fileUpload.dragAndDropDuration')}
                      </div>
                    </div>
                    <div className={classes.dragAndDropButton}>
                      {t('vocalExtractorTab.stepOne.tabs.fileUpload.dragAndDropButton')}
                    </div>
                  </div>
                </div>
              </div>
              {files.length > 0 && (
                <div>
                  <List className={classes.dragAndDropList}>
                    {files.map((file, index) => (
                      <ListItem key={index} className={classes.listItem}>
                        <ListItemAvatar className={classes.listItemAvatar}>
                          <Avatar className={classes.avatar}>
                            <AudioIcon className={classes.audioIcon} />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText className={classes.listItemText} primary={handleLabel(file.name)} />
                        <IconButton
                          onClick={async () => {
                            const newFileList = files.filter((_file, i) => i !== index);
                            setFiles(() => newFileList);
                          }}
                          edge="end"
                          aria-label="delete"
                        >
                          <DeleteIcon style={{ color: '#fff' }} />
                        </IconButton>
                      </ListItem>
                    ))}
                  </List>
                </div>
              )}
            </>
          )}
          {alignment === 'youtube' && (
            <div className={classes.recordButtonContainer}>
              {content.youtubeInfo ? (
                <div className={classes.youtubeInfoContainer}>
                  <div className={classes.youtubeInfo}>
                    {content.youtubeInfo.name} ({Math.floor(content.youtubeInfo.duration / 60)}
                    {t('vocalExtractorTab.stepOne.tabs.youtube.durationUnit.0')}
                    {content.youtubeInfo.duration % 60}
                    {t('vocalExtractorTab.stepOne.tabs.youtube.durationUnit.1')}
                    )
                    <DeleteIcon
                      className={classes.youtubeInfoDeleteIcon}
                      onClick={() =>
                        setContent({
                          ...content,
                          youtubeInfo: null,
                          youtubeLink: '',
                          name: null,
                        })
                      }
                    />
                  </div>
                </div>
              ) : (
                <>
                  <OutlinedInput
                    placeholder={t('vocalExtractorTab.stepOne.tabs.youtube.linkPlaceholder')}
                    onChange={e => setContent({ ...content, youtubeLink: e.target.value })}
                    value={content.youtubeLink}
                    className={classes.youtubeLinkInput}
                    onFocus={e => {
                      if (!checkValidLoginStatus(userId, dispatch)) {
                        e.target.blur();
                      }
                    }}
                  />
                  <Button
                    // defining style here because for some reason some styles
                    // doesn't get applied correctly on production
                    style={{
                      backgroundColor: '#242A2D',
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      borderTopRightRadius: 9,
                      borderBottomRightRadius: 9,
                      boxShadow: 'none',
                      width: '7.5rem',
                      cursor: 'pointer',
                      color: '#fff',
                    }}
                    disabled={!content.youtubeLink || loading}
                    onClick={checkYoutubeLink}
                  >
                    {loading ? (
                      <CircularProgress
                        // defining style here because for some reason some styles
                        // doesn't get applied correctly on production
                        style={{ width: '1rem', height: '1rem' }}
                      />
                    ) : (
                      <div className={classes.buttonText}>{t('vocalExtractorTab.stepOne.tabs.youtube.linkbutton')}</div>
                    )}
                  </Button>
                </>
              )}
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <div className={`${classes.separater} ${classes.noMargin}`} />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          {files.length <= 1 && (
            <>
              <Grid item xs={12} sm={12} md={12}>
                <div className={classes.stepHeader}>{t('vocalExtractorTab.stepTwo.title')}</div>
                <div className={classes.stepSubHeader}>{t('vocalExtractorTab.stepTwo.subTitles')}</div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} className={classes.labelInputContainer}>
                <input
                  className={classes.labelInput}
                  placeholder={t('vocalExtractorTab.stepTwo.labelInput.placeHolder')}
                  value={label}
                  onChange={e => {
                    if (checkValidLoginStatus(userId, dispatch)) {
                      setLabel([`${e.target.value}`]);
                    }
                  }}
                />
              </Grid>
            </>
          )}
          <ButtonGroup className={classes.formatInputSourceButtonContainer}>
            <Button
              className={`${classes.inputSourceButton} ${outputFormat == 'mp3' ? classes.selected : ''}`}
              onClick={() => setOutputFormat('mp3')}
            >
              {'MP3'}
            </Button>
            <HtmlTooltip
              title={
                <div className={'globalTooltipContainer'}>
                  <div className={'globalTooltipTextContainer'}>
                    <div className={'globalTooltipText'}>
                      {t('vocalExtractorTab.stepTwo.tooltip.outputFormatMessage')}
                    </div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <Link to="/subscription" className={'globalTooltipTextLink'}>
                      {user.subscription?.type === 'BASIC'
                        ? t('vocalExtractorTab.stepTwo.tooltip.upgradePrompt')
                        : t('vocalExtractorTab.stepTwo.tooltip.subscribePrompt')}
                    </Link>
                  </div>
                </div>
              }
              interactive
              leaveDelay={200}
              open={showOutputFormatTooltip && !subscriptionTypes.includes(user.subscription?.type)}
              onClose={() => setShowOutputFormatTooltip(false)}
            >
              <Button
                className={`${classes.inputSourceButton} ${outputFormat === 'wav' ? classes.selected : ''}`}
                onClick={handleWavButtonClick}
              >
                {'WAV'}
              </Button>
            </HtmlTooltip>
          </ButtonGroup>
        </Grid>
        <Grid className={classes.buttonGrid} item xs={12} sm={12} md={12}>
          {!loadingText ? (
            <div className={classes.buttonContainer}>
              <div className={classes.button} onClick={() => handleSubmit(alignment)}>
                {vocalExtractInProgress
                  ? t('vocalExtractorTab.submit.alreadyInProgress')
                  : userId && vocalExtractRemainingCount <= 0
                    ? t('vocalExtractorTab.submit.noRemaining')
                    : t('vocalExtractorTab.submit.start')}
              </div>
              {userId &&
                (user.subscription?.status === 'past_due' || user.subscription?.type !== 'PREMIUM') &&
                vocalExtractRemainingCount > 0 && (
                  <div className={classes.buttonRemainingText}>
                    {t('vocalExtractorTab.submit.remainingCountText')}: {vocalExtractRemainingCount}
                    {t('vocalExtractorTab.submit.remainingCountUnit')}
                  </div>
                )}
            </div>
          ) : (
            <div className={classes.laodingContainer}>
              <CircularProgress size="1.4rem" />
              <span className={classes.laodingText}>{loadingText}</span>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
export default VocalExtractorPage;
