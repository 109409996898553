import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '75%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '85%',
    },
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    height: 'fit-content',
    borderRadius: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#1E1E1E;',
    padding: '2rem 2rem',
    wordBreak: 'keep-all!important',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 1px rgba(0,0,0,0.1)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
    },
  },
  progressContainer: {
    [theme.breakpoints.up('xs')]: {
      whiteSpace: 'nowrap',
    },
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1rem',
  },
  renderingText: {
    display: 'flex',
    flexDirection: 'column',
    textWrap: 'wrap',
    gap: '0.2rem',
    fontWeight: 300,
    fontSize: '0.85rem',
  },
  progressIcon: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    color: 'inherit',
  },
  titleContainer: {
    marginTop: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
    marginRight: '2rem',
    fontSize: '1.7rem',
    color: '#fff',
  },
  tooltipImg: {
    marginRight: '0.6rem',
    width: '1.2rem',
  },
  startButtonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  startButton: {
    backgroundColor: '#CAFF73!important',
    color: '#0E0E0E!important',
    fontSize: '0.8rem!important',
    fontFamily: 'inherit!important',
    fontWeight: '400!important',
    padding: '0.4rem 0.8rem!important',
    '&.Mui-disabled': {
      color: '#818181!important',
      backgroundColor: '#A4A4A4!important',
    },
  },
  separater: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    margin: '1.2rem 0',
  },
  subTitle: {
    fontSize: '1rem',
    fontWeight: 300,
    color: '#fff',
  },
  labelInput: {
    [theme.breakpoints.down('544')]: {
      width: '100%',
    },
    '&::placeholder': {
      color: '#818181',
      fontSize: '0.9rem',
      fontWeight: '400',
      border: 'none!important',
    },
    marginBottom: '1.2rem',
    width: '25rem',
    maxWidth: '-webkit-fill-available',
    height: '2.1rem',
    padding: '1rem',
    borderRadius: '0.2rem',
    border: 'none!important',
    color: '#000',
    fontSize: '16px',
    fontWeight: '400',
    outline: 'none',
  },
  optionContainer: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontSize: '0.9rem',
    fontWeight: 300,
  },
  disabledCheckbox: {
    color: '#818181',
    fontSize: '0.9rem',
    fontWeight: 300,
  },
  checkbox: {
    padding: '0!important',
    marginLeft: '0.4rem!important',
    color: '#CAFF73!important',
    '&.Mui-disabled': {
      color: '#CAFF7350!important',
    },
  },
  success: {
    color: '#818181',
    fontWeight: 300,
    fontSize: '0.8rem',
    marginBottom: '1rem',
  },
  downloadContainer: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    color: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1rem',
  },
  downloadButton: {
    minWidth: '0!important',
    color: '#000!important',
    backgroundColor: '#CAFF73!important',
    padding: '0.3rem!important',
  },
}));

export { useStyles };
