import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#191919',
    color: '#c4c4c4',
    position: 'absolute',
    fontSize: '16px',
    fontWeight: '400',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '275px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '24px 0 23px 0',
    wordBreak: 'keep-all!important',
    borderRadius: '24px',
    outline: 'none',
  },
  sizeMid: {
    width: '300px',
  },
  sizeMax: {
    width: '350px',
  },
  sorisoriAIImg: {
    width: '200px',
    marginBottom: '24px',
  },
}));

export { useStyles };
