import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkValidLoginStatus } from '../../utils/user.utils';
import { getEmailPreferences, updateEmailPreferences } from '../../services/page.services';
import _ from 'lodash';

import { useStyles } from './EmailPreferencePage.styles';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { CircularProgress } from '@material-ui/core';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { showMessageV2 } from '../../utils/page.utils';

function EmailPreferencePage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const userId = user?.id;

  const PreferenceTypes = {
    ANNOUNCEMENT: 'announcement',
    NOTIFICATION: 'notification',
    NEWSLETTER: 'newsletter',
    PROMOTION: 'promotion',
  };

  const [emailPreferences, setEmailPreferences] = useState({
    [PreferenceTypes.ANNOUNCEMENT]: true,
    [PreferenceTypes.NOTIFICATION]: true,
    [PreferenceTypes.NEWSLETTER]: true,
    [PreferenceTypes.PROMOTION]: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    fetchEmailPreferences();
  }, [userId]);

  const fetchEmailPreferences = async () => {
    if (userId) {
      const preferences = await getEmailPreferences(userId);
      setEmailPreferences(preferences);
    }
  };

  const handleEmailPreferenceChange = async event => {
    if (!checkValidLoginStatus(userId, dispatch)) return;

    if (!isLoading && !isSuccess) {
      setEmailPreferences({
        ...emailPreferences,
        [event.target.value]: event.target.checked,
      });
    }
  };

  const handleEmailPreferenceSubmit = async () => {
    if (!checkValidLoginStatus(userId, dispatch)) return;

    setIsLoading(true);

    try {
      await updateEmailPreferences(userId, emailPreferences);
      setIsLoading(false);
      setIsSuccess(true);
      showMessageV2(dispatch, t('emailPreferenceTab.modal.success'), { reloadOnClose: true });
    } catch (e) {
      setIsLoading(false);
      showMessageV2(dispatch, t('emailPreferenceTab.modal.error'), { reloadOnClose: true });
    }
  };

  return (
    <>
      <div className={classes.pageTitle}>{t('emailPreferenceTab.title')}</div>
      <div className={classes.separater} />
      <FormGroup className={classes.radioButtonContainer}>
        <div className={classes.header}>{t('emailPreferenceTab.header')}</div>
        {Object.values(PreferenceTypes).map(type => (
          <FormControlLabel
            className={classes.radioButtonLabel}
            control={
              <Checkbox
                className={classes.checkbox}
                checked={emailPreferences[type]}
                onChange={handleEmailPreferenceChange}
                value={type}
                disabled={isLoading}
              />
            }
            label={t(`emailPreferenceTab.choiceLabels.${type}`)}
            key={type}
          />
        ))}
      </FormGroup>

      <div className={classes.buttonContainer}>
        <div className={classes.button} onClick={handleEmailPreferenceSubmit}>
          {isLoading && <CircularProgress size="1.2rem" />}
          {!isLoading && isSuccess && <CheckCircleOutlineIcon style={{ fontSize: '1.2rem' }} />}
          {!isLoading && !isSuccess && t('emailPreferenceTab.submit.save')}
        </div>
      </div>
    </>
  );
}

export default EmailPreferencePage;
