import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { useDispatch } from 'react-redux';
import { useStyles } from './CustomMessageModal.styles.js';
import { appActions } from '../../actions/app.actions';
import { useTranslation } from 'react-i18next';

export default function CustomMessageModal(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [fontStyle, setFontStyle] = useState('TheJamsil');
  const { t } = useTranslation();

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  return (
    <Modal className={fontStyle} open={!!props.open} onClose={() => dispatch(appActions.closeCustomMessageModal())}>
      <div className={classes.customMessageContainer}>
        {props.usage === 'vocalExtractorManyFilesError' && (
          <div className={classes.customMessage}>
            {props.customMessage[0] === 10 ? (
              <div>{t('vocalExtractorTab.modal.premiumCustomManyFiles')}</div>
            ) : (
              // props.customMessage[0] === 5
              <div>{t('vocalExtractorTab.modal.proManyFiles')}</div>
            )}
            <div>{`${props.customMessage[0]} ${t('vocalExtractorTab.modal.manyFiles')}`}</div>
          </div>
        )}
        {props.usage === 'vocalExtractFailed' && (
          <>
            <div className={classes.customMessage}>{props.customMessage[0]}</div>
            {props.customMessage[1].map(item => (
              <div className={classes.customMessage}>{item}</div>
            ))}
          </>
        )}
        {props.usage === 'ttiNSFWError' && (
          <>
            {props.customMessage[0] === 3 ? (
              <div className={classes.customMessage}>
                <div>{t('ttiInferenceTab.submit.nsfwBlock.0')}</div>
                <div>{t('ttiInferenceTab.submit.nsfwBlock.1')}</div>
              </div>
            ) : (
              <div className={classes.customMessage}>
                <div>{t('ttiInferenceTab.submit.nsfwWarning.0')}</div>
                <div>{t('ttiInferenceTab.submit.nsfwWarning.1')}</div>
                <div className={classes.customMessage}> {`${props.customMessage[0]}/3`}</div>
              </div>
            )}
          </>
        )}
        {props.usage === 'ttiNSFWBlock' && (
          <div className={classes.customMessage}>
            <div>{t('ttiInferenceTab.submit.nsfwBlock.0')}</div>
            <div>{t('ttiInferenceTab.submit.nsfwBlock.1')}</div>
          </div>
        )}
      </div>
    </Modal>
  );
}
