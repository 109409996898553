import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.5rem',
    },
    paddingLeft: '1rem',
    fontSize: '2.5rem',
    color: '#fff',
    alignItems: 'center',
    display: 'flex',
  },
  pageSubTitle: {
    marginTop: '1rem',
    paddingLeft: '1rem',
    fontSize: '1rem',
    color: '#fff',
    fontWeight: '300',
  },
  separater: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    margin: '1.5rem 0',
  },
  stepHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  stepHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.4rem',
    },
    fontSize: '1.6rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    color: '#fff',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
  },
  scrollButton: {
    border: 'none',
    background: 'none',
    minWidth: '1rem!important',
    minHeight: '1rem!important',
    padding: '0!important',
    '&:disabled': {
      background: 'none',
    },
  },
  disabledArrow: {
    color: 'grey',
    fontSize: '1.8rem!important',
  },
  activeArrow: {
    color: '#fff',
    fontSize: '1.8rem!important',
    cursor: 'pointer',
  },
  subTitleOneContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  scrollContainer: {
    [theme.breakpoints.down('xs')]: {
      gap: '0.53rem',
    },
    display: 'flex',
    gap: '1rem',
    overflowX: 'auto',
    scrollBehavior: 'smooth',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  hotCard: {
    [theme.breakpoints.down('xs')]: {
      width: `6rem`,
      height: '6rem',
      fontSize: '1rem',
    },
    flex: '0 0 auto',
    width: `10rem`,
    height: '10rem',
    border: '0.1rem solid #fafafa',
    backgroundColor: '#161616',
    borderRadius: '0.5rem',
    color: '#fff',
    fontSize: '1.5rem',
    fontWeight: '400',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
  },
  hotPlayButtonImg: {
    position: 'absolute',
    zIndex: '3',
    userSelect: 'none',
    cursor: 'pointer',
    color: '#fff',
    top: '0.3rem',
    right: '0.2rem',
    background: 'radial-gradient(circle, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 72%)',
    '&.MuiSvgIcon-root': {
      [theme.breakpoints.down('xs')]: {
        width: '1.5rem',
        height: '1.5rem',
      },
      width: '2rem',
      height: '2rem',
      padding: '0',
      margin: '0',
    },
  },
  hotCoverImg: {
    [theme.breakpoints.down('xs')]: {
      width: `5.83rem`,
      height: '5.83rem',
    },
    position: 'absolute',
    zIndex: '0',
    borderRadius: '0.4rem',
    width: `9.8rem`,
    height: '9.8rem',
  },
  hotCardContent: {
    [theme.breakpoints.down('xs')]: {
      padding: '0.4rem 0.5rem',
    },
    position: 'absolute',
    width: '100%',
    zIndex: '2',
    display: 'flex',
    alightItems: 'center',
    gap: '1rem',
    borderRadius: '0 0 0.5rem 0.5rem',
    bottom: '0',
    backgroundColor: 'rgb(0, 0, 0, 0.6)',
    padding: '0.7rem 0.8rem',
  },
  hotCardLabel: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
      lineHeight: '1.1rem',
    },
    flex: 1,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    overflowWrap: 'anywhere',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cardGrid: {
    columnGap: '15px',
  },
  card: {
    display: 'flex',
    border: '0.1rem solid #fafafa',
    backgroundColor: '#161616',
    padding: '.7rem',
    paddingBottom: '.5rem',
    borderRadius: '0.5rem',
    color: '#fff',
    fontSize: '0.9rem',
    fontWeight: '400',
    maxWidth: '25rem',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '25rem',
      marginRight: '0',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
    },
    [theme.breakpoints.down(476)]: {
      fontSize: '0.75rem',
      padding: '.4rem',
      paddingBottom: '.3rem',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  cardSecondColumn: {
    flex: 'vertical',
  },
  cardLabel: {
    flex: 1,
    marginLeft: '0.3rem',
    display: 'flex',
    alignItems: 'top',
    fontSize: '1.3rem',
    flexWrap: 'wrap',
    height: '3.02rem',
    display: 'inline-block',
    overflow: 'hidden',
    overflowWrap: 'anywhere',
  },
  cardUsername: {
    alightItems: 'flex-end',
    display: 'flex',
    marginLeft: '0.3rem',
    flex: 1,
    fontSize: '0.9rem',
    textOverflow: 'ellipsis',
    color: '#d3d3d3',
    height: '0.8rem',
    [theme.breakpoints.down(476)]: {
      fontSize: '0.8rem',
    },
  },
  cardTimestamp: {
    alightItems: 'bottom',
    display: 'flex',
    marginLeft: '0.3rem',
    flex: 1,
    fontSize: '0.7rem',
    color: '#d3d3d3',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down(476)]: {
      fontSize: '0.6rem',
    },
  },
  cardLikes: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: '1.2rem',
  },
  numberPlayed: {
    color: '#d3d3d3',
    fontSize: '0.8rem',
    marginLeft: '0.3rem',
    [theme.breakpoints.down(476)]: {
      fontSize: '0.7rem',
    },
  },
  creations: {
    color: '#d3d3d3',
    fontSize: '0.6rem',
    marginLeft: '.2rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginBottom: '0.07rem',
    [theme.breakpoints.down(476)]: {
      fontSize: '0.5rem',
    },
  },
  creationsGrid: {
    alignItems: 'flex-end',
    marginBotton: '0.5rem',
  },
  searchbar: {
    [theme.breakpoints.down('s')]: {
      padding: '0.2rem 0.5rem!important',
      width: '10rem',
      height: '5rem',
    },
    '&::placeholder': {
      color: '#818181',
      fontWeight: '400',
      border: 'none!important',
    },
    margin: '0.3rem 0 0.5rem 0',
    width: '25rem',
    maxWidth: '-webkit-fill-available',
    height: '2.5rem',
    borderRadius: '0.2rem',
    border: 'none!important',
    padding: '1rem',
    color: '#000',
    fontSize: '16px',
    fontWeight: '400',
    outline: 'none',
  },
  pagination: {
    fontSize: '1.5rem',
    fontWeight: '500',
    color: '#ffffff',
    margin: '1rem 0',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },

    [theme.breakpoints.up('m')]: {
      marginLeft: '50rem',
    },
    [theme.breakpoints.down(1134)]: {
      width: '6.3rem',
    },

    backgroundColor: '#CAFF73',
    padding: '0.6rem 0 0 0',
    margin: '.3rem 0 .5rem 0',
    width: '7.0rem',
    fontSize: '1.1rem',
    borderRadius: '0.2rem',
    textAlign: 'center',
    cursor: 'pointer',
    height: '2.5rem',
    verticalTextAlign: 'center',
  },
  searchButtonContainer: {
    [theme.breakpoints.down('s')]: {
      maxWidth: '25rem',
    },
    [theme.breakpoints.up(1135)]: {
      maxWidth: '51rem',
    },
    [theme.breakpoints.up(1550)]: {
      maxWidth: '77rem',
    },
    [theme.breakpoints.up(1965)]: {
      maxWidth: '103rem',
    },
    maxWidth: '25rem',

    display: 'flex',
    justifyContent: 'space-between',
  },
  coverImg: {
    userSelect: 'none',
    width: '5.5rem',
    height: '5.5rem',
    display: 'inline-block',
    fill: 'currentcolor',
    flexShrink: '1',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontSize: '1.5rem',
    borderRadius: '0.3rem',
    border: '0.07rem solid #808080',
  },
  playButtonImg: {
    userSelect: 'none',
    flexShrink: '0',
    fontSize: '50px',
    cursor: 'pointer',
    borderRadius: '0.1rem',
  },
  playButtonGrid: {
    direction: 'row',
    justifyContent: 'flex-end',
  },
  favoriteImg: {
    userSelect: 'none',
    flexShrink: '0',
    fontSize: '2rem',
    cursor: 'pointer',
    borderRadius: '0.1rem',
  },
  favoriteFullImg: {
    userSelect: 'none',
    flexShrink: '0',
    fontSize: '2rem',
    cursor: 'pointer',
    borderRadius: '0.1rem',
    color: '#d32f2f',
  },
  numberLikes: {
    fontSize: '0.8rem',
    marginLeft: '.1rem',
    marginRight: '.1rem',
  },
  tagAutoComplete: {
    [theme.breakpoints.down('s')]: {
      padding: '0.2rem 0.5rem!important',
      width: '10rem',
      height: '5rem',
    },
    '&::placeholder': {
      color: '#818181',
      fontSize: '0.9rem',
      fontWeight: '400',
      border: 'none!important',
    },
    width: '25rem',
    maxWidth: '-webkit-fill-available',
    borderRadius: '0.2rem',
    border: 'none!important',
    color: '#000',
    fontSize: '0.9rem',
    fontWeight: '400',
    outline: 'none',
    backgroundColor: '#fff',
  },
  sortSelect: {
    backgroundColor: '#fff',
    height: '2.5rem',
    [theme.breakpoints.down(1134)]: {
      width: '6',
    },
    width: '7',
  },
  filterSortContainer: {
    [theme.breakpoints.down('s')]: {
      maxWidth: '25rem',
    },
    [theme.breakpoints.up(1135)]: {
      maxWidth: '51rem',
    },
    [theme.breakpoints.up(1550)]: {
      maxWidth: '77rem',
    },
    [theme.breakpoints.up(1965)]: {
      maxWidth: '103rem',
    },
    maxWidth: '25rem',
    display: 'flex',
    marginBottom: '1rem',
    justifyContent: 'space-between',
  },
}));

export { useStyles };
