import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LanguageIcon from '@mui/icons-material/Language';
import ExpandIcon from '@mui/icons-material/ExpandMore';
import discordIcon from '../../../img/discord-mark-white.png';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

import { useStyles } from './ResponsiveDrawer.styles';
import { getDrawerItems } from './drawerItems';

import { appActions } from '../../../actions/app.actions';
import { getFormattedLanguage } from '../../../utils/page.utils';
import logo from '../../../img/textLogo.png';
import betaImg from '../../../img/beta.png';
import { Link } from 'react-router-dom';

const ResponsiveDrawer = props => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useSelector(state => state.user);
  const { pathname } = useLocation();
  const [fontStyle, setFontStyle] = useState('TheJamsil');
  const [openTTS, setOpenTTS] = React.useState(false);
  const [openAICover, setOpenAICover] = React.useState(false);
  const [openHelp, setOpenHelp] = React.useState(false);
  const [openFaceSwap, setOpenFaceSwap] = React.useState(false);
  const [openTTI, setOpenTTI] = React.useState(false);

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  const drawerItems = getDrawerItems(t);
  const formattedLanguage = getFormattedLanguage();
  const openLanguageModal = () => dispatch(appActions.openLanguageModal());

  const handleClickTTS = () => {
    setOpenTTS(!openTTS);
  };
  const handleClickAICover = () => {
    setOpenAICover(!openAICover);
  };
  const handleClickHelp = () => {
    setOpenHelp(!openHelp);
  };
  const handleClickFaceSwap = () => {
    setOpenFaceSwap(!openFaceSwap);
  };

  const drawer = (
    <div className={`${classes.drawer} ${fontStyle}`} style={{ color: 'red!important' }}>
      <div>
        <Link to={'/'}>
          <img className={classes.logo} src={logo} alt="Logo" />
        </Link>
        <List>
          {drawerItems.sectionProduction.map(({ text, icon }, index) => (
            <ListItem key={index} className={classes.drawerListItem} onClick={handleClickAICover}>
              {openAICover ? (
                <ExpandLess fontSize="26px" className={classes.expandIcon} />
              ) : (
                <ExpandMore fontSize="26px" className={classes.expandIcon} />
              )}
              <div className={classes.drawerListText}>{text}</div>
            </ListItem>
          ))}
          <Collapse in={openAICover} timeout="auto" unmountOnExit>
            <List component="div" disablePadding style={{ marginTop: '-0.1rem', marginLeft: '0.4rem' }}>
              {drawerItems.sectionOne.map(({ text, icon, to }, index) => (
                <ListItem
                  selected={pathname === `/${to}`}
                  component={!!to ? RouterLink : null}
                  to={`/${to}`}
                  button={!!to}
                  key={index}
                  className={classes.subDrawerListItem}
                  onClick={() => props.open && props.handleDrawerToggle()}
                >
                  <img className={classes.subDrawerListImg} src={icon} alt="list Icon" />
                  <div className={classes.subDrawerListText}>{text}</div>
                </ListItem>
              ))}
            </List>
          </Collapse>
          {drawerItems.sectionBeta.map(({ text, icon }, index) => (
            <ListItem key={index} className={classes.drawerListItem} onClick={handleClickTTS}>
              {openTTS ? (
                <ExpandLess fontSize="26px" className={classes.expandIcon} />
              ) : (
                <ExpandMore fontSize="26px" className={classes.expandIcon} />
              )}
              <div className={classes.drawerListText}>{text}</div>
            </ListItem>
          ))}
          <Collapse in={openTTS} timeout="auto" unmountOnExit>
            <List component="div" disablePadding style={{ marginLeft: '0.4rem' }}>
              {drawerItems.sectionSubBeta.map(({ text, icon, to }, index) => (
                <ListItem
                  sx={{ pl: 4 }}
                  selected={pathname === `/${to}`}
                  component={!!to ? RouterLink : null}
                  to={`/${to}`}
                  button={!!to}
                  key={index}
                  className={classes.subDrawerListItem}
                  onClick={() => props.open && props.handleDrawerToggle()}
                >
                  <img className={classes.subDrawerListImg} src={icon} alt="list Icon" />
                  <div className={classes.subDrawerListText}>{text}</div>
                </ListItem>
              ))}
            </List>
          </Collapse>
          {drawerItems.sectionSubBetaTwo.map(({ text, icon, to }, index) => (
            <ListItem
              selected={pathname === `/${to}`}
              component={!!to ? RouterLink : null}
              to={`/${to}`}
              button={!!to}
              key={index}
              className={classes.drawerListItem}
              onClick={() => props.open && props.handleDrawerToggle()}
            >
              <img className={classes.drawerListImg} src={icon} alt="list Icon" />
              <div className={classes.drawerListText}>{text}</div>
              <img className={classes.drawerBetaImg} src={betaImg} alt="beta Icon" />
            </ListItem>
          ))}
          {drawerItems.sectionVideo.map(({ text, icon, to }, index) => (
            <ListItem
              selected={pathname === `/${to}`}
              component={!!to ? RouterLink : null}
              to={`/${to}`}
              button={!!to}
              key={index}
              className={classes.drawerListItem}
              onClick={() => props.open && props.handleDrawerToggle()}
            >
              <img className={classes.drawerListImg} src={icon} alt="list Icon" />
              <div className={classes.drawerListText}>{text}</div>
            </ListItem>
          ))}
          {drawerItems.sectionFaceSwap.map(({ text, icon, to }, index) => (
            <ListItem
              sx={{ pl: 4 }}
              selected={pathname === `/${to}`}
              component={!!to ? RouterLink : null}
              to={`/${to}`}
              button={!!to}
              key={index}
              className={classes.subDrawerListItem}
              onClick={() => props.open && props.handleDrawerToggle()}
            >
              <img className={classes.drawerListImg} src={icon} alt="list Icon" />
              <div className={classes.drawerListText}>{text}</div>
              <img className={classes.drawerBetaImg} src={betaImg} alt="beta Icon" />
            </ListItem>
          ))}
        </List>

        <div className={classes.separater} />
        <List>
          {drawerItems.sectionTwo.map(({ text, icon, to }, index) => (
            <ListItem
              selected={pathname === `/${to}`}
              component={!!to ? RouterLink : null}
              to={`/${to}`}
              button={!!to}
              key={index}
              className={classes.drawerListItem}
              onClick={() => props.open && props.handleDrawerToggle()}
            >
              <img className={classes.drawerListImg} src={icon} alt="list Icon" />
              <div className={classes.drawerListText}>{text}</div>
            </ListItem>
          ))}
        </List>

        <div className={classes.separater} />
        <List>
          {drawerItems.sectionHelp.map(({ text, icon }, index) => (
            <ListItem key={index} className={classes.drawerListItem} onClick={handleClickHelp}>
              {openHelp ? (
                <ExpandLess fontSize="26px" className={classes.expandIcon} />
              ) : (
                <ExpandMore fontSize="26px" className={classes.expandIcon} />
              )}
              <div className={classes.drawerListText}>{text}</div>
            </ListItem>
          ))}
          <Collapse in={openHelp} timeout="auto" unmountOnExit>
            <List component="div" disablePadding style={{ marginTop: '-0.1rem', marginLeft: '0.4rem' }}>
              {drawerItems.sectionSubHelp.map(({ text, icon, to }, index) => (
                <ListItem
                  selected={pathname === `/${to}`}
                  component={!!to ? RouterLink : null}
                  to={`/${to}`}
                  button={!!to}
                  key={index}
                  className={classes.subDrawerListItem}
                  onClick={() => props.open && props.handleDrawerToggle()}
                >
                  <img className={classes.subDrawerListImg} src={icon} alt="list Icon" />
                  <div className={classes.subDrawerListText}>{text}</div>
                </ListItem>
              ))}
              <ListItem
                button={true}
                key={drawerItems.sectionTwo.length}
                onClick={() => window.open(t('supportTab.link'), '_blank')}
                className={classes.subDrawerListItem}
              >
                <img className={classes.discordImg} src={discordIcon} alt="discord Icon" />
                <div className={classes.subDrawerListText}>{t('supportTab.title')}</div>
              </ListItem>
            </List>
          </Collapse>
        </List>
      </div>
      <div>
        <div className={classes.languageContainer} onClick={openLanguageModal}>
          <LanguageIcon />
          <div className={classes.selectedLanguage}>{formattedLanguage}</div>
          <ExpandIcon />
        </div>
        {!user.loading &&
          (user.loggedIn ? (
            <div className={classes.drawerLogin} onClick={() => navigate('/profile')}>
              <AccountBoxIcon />
              <span className={classes.drawerLoginText}>{user.name.toUpperCase()}</span>
            </div>
          ) : (
            <div className={classes.drawerLogin} onClick={() => dispatch(appActions.openLoginModal())}>
              <AccountBoxIcon style={{ border: '1px solid #fafafa' }} />
              <span className={classes.drawerLoginText}>{t('signIn')}</span>
            </div>
          ))}
      </div>
    </div>
  );

  return (
    <nav>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          open={props.open}
          onClose={props.handleDrawerToggle}
          classes={{ paper: classes.drawerPaper }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer classes={{ paper: classes.drawerPaper }} variant="permanent">
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default ResponsiveDrawer;
