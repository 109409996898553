import Modal from '@material-ui/core/Modal';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useStyles } from './CommunityUploadModal.styles';
import { useDropzone } from 'react-dropzone';

import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { Grid, Button, OutlinedInput, CircularProgress, ButtonGroup } from '@material-ui/core';
import { AudiotrackSharp as AudioIcon, Delete as DeleteIcon } from '@material-ui/icons';

import { dragAndDropStyle } from '../pages/shared.styles';

import {
  checkTrainingCompletion,
  uploadErrorMessage,
  fetchTrainings,
  shareVoice,
  uploadCommunityItemMethodOne,
  fetchTypeTags,
} from '../../services/page.services';

import { checkValidLoginStatus } from '../../utils/user.utils';
import { labelLength } from '../../constants/app.constants.js';
import { showMessageV2 } from '../../utils/page.utils.js';

export default function CommunityUploadModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const onClick = res => {
    if (res) props.onEdit(label);
    else props.onEditModalClose();
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const userId = user?.id;

  const [uploadType, setUploadType] = useState('train');
  const [loading, setLoading] = useState(false);
  const { open } = props;
  const [externalFile, setExternalFile] = useState({});
  const [label, setLabel] = useState('');
  const [voices, setVoices] = useState([]);
  const [image, setImage] = useState();
  const [selectedModel, setSelectedModel] = useState(
    'selectedModelId' in props
      ? voices.find(voice => {
          return voice.id === props.selectedModelId;
        })
      : null
  );

  const [gender, setGender] = React.useState('male');
  const [countryTags, setCountryTags] = useState([]);
  const [genreTags, setGenreTags] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState();
  const [selectedCountries, setSelectedCountries] = useState();

  const [anonymous, setAnonymous] = useState(false);
  const [fontStyle, setFontStyle] = useState('TheJamsil');

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  useEffect(() => {
    if (userId) (async () => initPage())();
  }, [userId]);

  const handleFemaleClick = () => {
    setGender('female');
  };

  useEffect(() => {
    if (props.selectedModelId) {
      setSelectedModel(
        voices.find(voice => {
          return voice.id === props.selectedModelId;
        })
      );
    }
  }, [props]);

  const initPage = async (isOnSubmit = false) => {
    const fetchedCountryTags = await fetchTypeTags('country');
    const fetchedGenreTags = await fetchTypeTags('genre');
    setCountryTags(fetchedCountryTags);
    setGenreTags(fetchedGenreTags);

    await checkTrainingCompletion();
    const trainings = await fetchTrainings();
    const availableTraining = _.sortBy(
      _.map(
        _.filter(trainings, function (o) {
          return o.status === 'COMPLETE' && !o.shared;
        }),
        v => ({
          ...v,
          category: 'my voices',
        })
      ),
      ['label']
    );
    setVoices(availableTraining);

    if (props.selectedModelId) {
      setSelectedModel(
        availableTraining.find(voice => {
          return voice.id === props.selectedModelId;
        })
      );
    }

    setImage(null);
    setExternalFile({});
    setLabel('');
  };

  const onPathDropAccepted = async acceptedFiles => {
    if (checkValidLoginStatus(userId, dispatch)) {
      setExternalFile({ ...externalFile, path: acceptedFiles[0] });
    }
  };

  const onImageDropAccepted = async acceptedFiles => {
    if (checkValidLoginStatus(userId, dispatch) && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setImage(file);
    }
  };

  const onIndexDropAccepted = async acceptedFiles => {
    if (checkValidLoginStatus(userId, dispatch)) {
      setExternalFile({ ...externalFile, index: acceptedFiles[0] });
    }
  };

  const onDropRejected = async input => {
    if (checkValidLoginStatus(userId, dispatch)) {
      const error = input[0].errors[0];
      if (error.code === 'file-invalid-type') {
        showMessageV2(dispatch, t('trainingTab.modal.notSupportedFileType'));
      }
    }
  };

  const imgDropzone = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/webp': [],
      'image/heic': [],
      'image/jfif': [],
      'image/jpg': [],
    },
    maxFiles: 1,
    onDropAccepted: onImageDropAccepted,
    onDropRejected,
    disabled: loading,
  });
  const pathDropzone = useDropzone({
    accept: {
      '*/*': ['.pth'],
    },
    onDropAccepted: onPathDropAccepted,
    onDropRejected,
    disabled: loading,
  });

  const indexDropzone = useDropzone({
    accept: {
      '*/*': ['.index'],
    },
    onDropAccepted: onIndexDropAccepted,
    onDropRejected,
    disabled: loading,
  });

  const handleExternalUpload = async () => {
    try {
      if (checkValidLoginStatus(userId, dispatch)) {
        if (!label) {
          showMessageV2(dispatch, t('trainingTab.modal.noLabel'));
          return;
        } else if (!externalFile.path || !externalFile.index) {
          showMessageV2(dispatch, t('trainingTab.modal.missingFile'));
          return;
        } else if (!selectedGenres || !selectedCountries) {
          showMessageV2(dispatch, 'Please select at least one tag for each field');
          return;
        }
        setLoading(true);
        const files = [externalFile.path, externalFile.index];
        await uploadCommunityItemMethodOne(
          userId,
          image,
          files,
          label,
          gender,
          selectedGenres,
          selectedCountries,
          anonymous
        );
        setImage(null);
        setSelectedModel(null);
        setSelectedGenres([]);
        setSelectedCountries([]);
        setAnonymous(false);
        await initPage();
        window.location.reload();
      }
    } catch (e) {
      if (e.message) {
        const codeMessage = `code: ${e.code}, message: ${e.message}, config: ${JSON.stringify(e.config)}`;
        await uploadErrorMessage(codeMessage);
      } else {
        await uploadErrorMessage('no message - ' + JSON.stringify(e));
      }
      showMessageV2(dispatch, t('modal.fileUploadFail'));
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = async () => {
    try {
      setLoading(true);
      if (!image) {
        showMessageV2(dispatch, t('communityUploadTab.modal.noImage'));
        return;
      } else if (!selectedModel) {
        showMessageV2(dispatch, t('communityUploadTab.modal.noModel'));
        return;
      } else if (label.trim().length === 0) {
        showMessageV2(dispatch, t('communityUploadTab.modal.noLabel'));
        return;
      } else if (label.trim().length > labelLength.voice) {
        showMessageV2(dispatch, t('communityUploadTab.modal.longLabel'));
        return;
      } else if (!selectedGenres || !selectedCountries) {
        showMessageV2(dispatch, t('communityUploadTab.modal.noTag'));
        return;
      }
      console.log(`anonymous: ${anonymous}`);
      await shareVoice(userId, image, selectedModel.id, label, gender, selectedGenres, selectedCountries, anonymous);
      setImage(null);
      setSelectedModel(null);
      setSelectedGenres([]);
      setSelectedCountries([]);
      setAnonymous(false);
      window.location.reload();
      //await initPage();
    } catch (e) {
      if (e.message) {
        const codeMessage = `code: ${e.code}, message: ${e.message}, config: ${JSON.stringify(e.config)}`;
        //await uploadErrorMessage(codeMessage);
      } else {
        //await uploadErrorMessage("no message - " + JSON.stringify(e));
      }
      showMessageV2(dispatch, t('modal.fileUploadFail'));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async e => {
    if (checkValidLoginStatus(userId, dispatch)) {
      await handleUpload();
    }
    props.onCommunityModalClose();
  };

  const translator = option => {
    if (t('languageDetector') === 'ko') {
      return option.korean;
    } else {
      return option.name;
    }
  };

  return (
    <Modal className={fontStyle} open={!!open} onClose={() => props.onCommunityModalClose()}>
      <div className={classes.container}>
        {!loading && (
          <div>
            <div className={classes.pageTitle}>{t('communityUploadTab.title')}</div>
            <div className={classes.separater} />

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <div className={classes.stepHeader}>{t('communityUploadTab.stepOne.title')}</div>
                <div className={classes.stepSubHeader}>{t('communityUploadTab.stepOne.subTitles.0')}</div>
                {!image && (
                  <div className={classes.recordButtonContainer}>
                    <div {...imgDropzone.getRootProps({ style: dragAndDropStyle })}>
                      <input {...imgDropzone.getInputProps()} />
                      <div className={classes.dragAndDrop}>
                        <div className={classes.dragAndDropText}>
                          {t('communityUploadTab.stepOne.tabs.fileUpload.dragAndDropText')}
                        </div>
                        <div className={classes.dragAndDropText}>{'jpeg/png/webp/heic/jfif/jpg'}</div>
                        <div className={classes.dragAndDropButton}>
                          {t('inferenceTab.stepOne.tabs.fileUpload.dragAndDropButton')}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {image && (
                  <div className={classes.imageContainer}>
                    <img src={URL.createObjectURL(image)} className={classes.coverImg} />
                    <DeleteIcon onClick={() => setImage(null)} className={classes.deleteButtonImg} />
                  </div>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <div className={`${classes.separater}`} />
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <div className={classes.stepHeader}>{t('communityUploadTab.stepTwo.title')}</div>
                <ButtonGroup className={classes.inputSourceButtonContainer}>
                  <Button
                    className={`${classes.inputSourceButton} ${uploadType == 'train' ? classes.selected : ''}`}
                    onClick={() => setUploadType('train')}
                  >
                    {t('communityUploadTab.tabs.0')}
                  </Button>
                  <Button
                    className={`${classes.inputSourceButton} ${uploadType == 'external' ? classes.selected : ''}`}
                    onClick={() => setUploadType('external')}
                  >
                    {t('communityUploadTab.tabs.1')}
                  </Button>
                </ButtonGroup>
                <div className={classes.stepSubHeader}>
                  {uploadType === 'train' && (
                    <div className={classes.stepSubHeader}>{t('communityUploadTab.stepTwo.trainingSubTitles.0')}</div>
                  )}
                  {uploadType === 'external' && (
                    <div className={classes.stepSubHeader}>{t('communityUploadTab.stepTwo.externalSubTitles.0')}</div>
                  )}
                </div>
                {uploadType === 'train' ? (
                  <Grid style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }} item xs={12} sm={12} md={6}>
                    <Autocomplete
                      disablePortal
                      style={{ backgroundColor: '#fff' }}
                      id="combo-box-demo"
                      options={voices}
                      sx={{
                        borderRadius: '0.2rem',
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        maxWidth: 320,
                        borderRadius: '0.2rem',
                      }}
                      value={selectedModel}
                      onChange={(event, newValue) => {
                        setSelectedModel(newValue);
                      }}
                      //groupBy={(option) => option.category}
                      renderInput={params => (
                        <TextField {...params} placeholder={t('communityUploadTab.stepTwo.voice')} />
                      )}
                    />
                  </Grid>
                ) : (
                  <Grid container>
                    <Grid style={{ marginBottom: '0.5rem', marginRight: '1rem' }} item xs={12} sm={12} md={5}>
                      <div className={classes.dragAndDropHeader}>PTH File:</div>
                      {externalFile.path && (
                        <div className={classes.acceptedFileOuterContainer}>
                          <div className={classes.acceptedFileInnerContainer}>
                            <div>{externalFile.path.name}</div>
                            <DeleteIcon
                              onClick={() => setExternalFile({ ...externalFile, path: null })}
                              className={classes.acceptedFileDelete}
                            />
                          </div>
                        </div>
                      )}
                      {!externalFile.path && (
                        <>
                          <div
                            {...pathDropzone.getRootProps({
                              style: dragAndDropStyle,
                            })}
                          >
                            <input {...pathDropzone.getInputProps()} />
                            <div className={classes.dragAndDrop}>
                              <div className={classes.dragAndDropText}>
                                {t('communityUploadTab.stepTwo.dragAndDropText.0')}
                              </div>
                              <div className={classes.dragAndDropButton}>
                                {t('communityUploadTab.stepTwo.dragAndDropButton')}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </Grid>
                    <Grid style={{ marginBottom: '0.5rem', marginRight: '1rem' }} item xs={12} sm={12} md={5}>
                      <div className={classes.dragAndDropHeader}>INDEX File:</div>
                      {externalFile.index && (
                        <div className={classes.acceptedFileOuterContainer}>
                          <div className={classes.acceptedFileInnerContainer} style={{ maxWidth: 320 }}>
                            <div style={{ maxWidth: 310, overflow: 'hidden' }}>{externalFile.index.name}</div>
                            <DeleteIcon
                              onClick={() =>
                                setExternalFile({
                                  ...externalFile,
                                  index: null,
                                })
                              }
                              className={classes.acceptedFileDelete}
                            />
                          </div>
                        </div>
                      )}
                      {!externalFile.index && (
                        <div
                          {...indexDropzone.getRootProps({
                            style: dragAndDropStyle,
                          })}
                        >
                          <input {...indexDropzone.getInputProps()} />
                          <div className={classes.dragAndDrop}>
                            <div className={classes.dragAndDropText}>
                              {t('communityUploadTab.stepTwo.dragAndDropText.1')}
                            </div>
                            <div className={classes.dragAndDropButton}>
                              {t('communityUploadTab.stepTwo.dragAndDropButton')}
                            </div>
                          </div>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <div className={`${classes.separater} ${classes.noMargin}`} />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div className={classes.stepHeader}>{t('communityUploadTab.stepThree.title')}</div>
                <div className={classes.stepSubHeader}>{t('communityUploadTab.stepThree.subTitle')}</div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <OutlinedInput
                  onChange={e => {
                    if (checkValidLoginStatus(userId, dispatch)) {
                      setLabel(e.target.value);
                    }
                  }}
                  className={classes.labelInput}
                  disabled={loading}
                />
              </Grid>

              <Grid
                className={classes.anonymousContainer}
                //style={{ border: '1px solid #fafafa' }}
                item
                xs={12}
                sm={12}
                md={12}
              >
                <FormControl className={classes.radioButtonContainer}>
                  <div className={classes.radioButtonLabel}>{t('communityUploadTab.stepThree.anonymousText')}</div>
                  <RadioGroup row>
                    <FormControlLabel
                      checked={anonymous}
                      style={{
                        color: '#fff',
                      }}
                      control={
                        <Radio
                          size="small"
                          style={{
                            color: '#fff',
                          }}
                          onClick={() => setAnonymous(true)}
                        />
                      }
                      label={<Typography style={{ color: '#fff', fontSize: '0.8rem' }}>{t('yes')}</Typography>} //{t('yes')}
                    />
                    <FormControlLabel
                      checked={!anonymous}
                      style={{ color: '#fff', fontSize: '0.5rem' }}
                      control={<Radio size="small" style={{ color: '#fff' }} onClick={() => setAnonymous(false)} />}
                      label={<Typography style={{ color: '#fff', fontSize: '0.8rem' }}>{t('no')}</Typography>}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid
                style={{ marginBottom: '0.5rem' }}
                //style={{ border: '1px solid #fafafa' }}
                item
                xs={12}
                sm={12}
                md={12}
              >
                <div className={classes.stepSubHeader}>{t('communityUploadTab.stepThree.genderLabel')}</div>
                <ButtonGroup className={classes.inputSourceButtonContainer}>
                  <Button
                    className={`${classes.inputSourceButton} ${gender == 'male' ? classes.selected : ''}`}
                    onClick={() => setGender('male')}
                  >
                    {t('communityUploadTab.stepThree.gender.0')}
                  </Button>

                  <Button
                    className={`${classes.inputSourceButton} ${gender === 'female' ? classes.selected : ''}`}
                    onClick={handleFemaleClick}
                  >
                    {t('communityUploadTab.stepThree.gender.1')}
                  </Button>
                </ButtonGroup>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <div className={`${classes.separater} ${classes.noMargin}`} />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div className={classes.stepHeader}>{t('communityUploadTab.stepFour.title')}</div>
                <div className={classes.stepSubHeader}>{t('communityUploadTab.stepFour.subTitle')}</div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Stack spacing={1} sx={{ maxWidth: 320 }}>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    id="multiple-limit-tags"
                    size="small"
                    sx={{
                      borderRadius: '0.2rem',
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    }}
                    className={classes.tagAutoComplete}
                    options={genreTags}
                    getOptionLabel={option => translator(option)}
                    onChange={(event, newValues) => {
                      setSelectedGenres(newValues);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        Label={t('communityUploadTab.stepFour.tags.0')}
                        placeholder={t('communityUploadTab.stepFour.tags.0')}
                      />
                    )}
                  />
                  <Autocomplete
                    multiple
                    limitTags={2}
                    id="multiple-limit-tags"
                    size="small"
                    sx={{
                      borderRadius: '0.2rem',
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    }}
                    className={classes.tagAutoComplete}
                    options={countryTags}
                    getOptionLabel={option => translator(option)}
                    onChange={(event, newValues) => {
                      setSelectedCountries(newValues);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        Label={t('communityUploadTab.stepFour.tags.1')}
                        placeholder={t('communityUploadTab.stepFour.tags.1')}
                      />
                    )}
                  />
                </Stack>
              </Grid>

              <Grid className={classes.buttonGrid} item xs={12} sm={12} md={12}>
                {!loading && uploadType == 'external' && (
                  <div className={classes.buttonContainer}>
                    <div className={classes.button} onClick={() => handleExternalUpload()}>
                      {t('communityUploadTab.submit.upload')}
                    </div>
                  </div>
                )}

                {!loading && uploadType == 'train' && (
                  <div className={classes.buttonContainer}>
                    <div className={classes.button} onClick={handleSubmit}>
                      {t('communityUploadTab.submit.start')}
                    </div>
                  </div>
                )}
                {loading && (
                  <div className={classes.laodingContainer}>
                    <CircularProgress size="1.4rem" />
                    <span className={classes.laodingText}>{t('communityUploadTab.submit.uploading')}</span>
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        )}
        {loading && <CircularProgress className={classes.loading} />}
      </div>
    </Modal>
  );
}
