import AppBarAndDrawer from './AppBarAndDrawer/AppBarAndDrawer';

import { useLocation } from 'react-router-dom';
import { useStyles } from './Layout.modules';
import LoginModal from './LoginModal';
import MessageModalV2 from './MessageModalV2';
import LanguageModal from './LanguageModal';
import CustomMessageModal from './CustomMessageModal';
// import AnnouncementModal from "./AnnouncementModal";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const updatedPaths = ['/generate-music', '/generate-tti'];
function Layout(props) {
  const appProps = useSelector(state => state.app);
  const classes = useStyles();
  const { pathname } = useLocation();
  const useNewStyle = updatedPaths.includes(pathname);
  const { i18n } = useTranslation();
  const defaultFont = i18n.language === 'ja' ? 'MPlus1' : 'TheJamsil';
  return (
    <div className={defaultFont}>
      {/* <AnnouncementModal open={appProps.openLanguageModal} /> */}
      <LanguageModal open={appProps.openLanguageModal} />
      <LoginModal open={appProps.openLoginModal} />
      <MessageModalV2
        open={appProps.openMessageModalV2}
        message={appProps.messageModalV2Text}
        showProfileButton={appProps.showProfileButton}
        showHeaderLogo={appProps.showHeaderLogo}
        reloadOnClose={appProps.reloadOnClose}
      />
      <CustomMessageModal
        open={appProps.openCustomMessageModal}
        usage={appProps.usage}
        customMessage={appProps.customMessage}
      />
      <div className="layout">
        <AppBarAndDrawer />
        <main className={useNewStyle ? classes.main : classes.oldMain}>
          <div className={useNewStyle ? classes.innerMain : classes.oldInnerMain}>{props.children}</div>
          {/* {pathname === '/subscription' && (
            <footer className={classes.footer}>
              <span className={classes.companyInfo}>주식회사 소리소리AI</span>
              <span className={classes.companyInfo}>대표 : 신민제</span>
              <span className={classes.companyInfo}>사업자등록번호 : 610-86-36823</span>
              <span className={classes.companyInfo}>경기도 화성시 메타폴리스로 42, 9층 902호 엘14</span>
              <span className={classes.companyInfo}>TEL : 070-8018-9818</span>
            </footer>
          )} */}
        </main>
      </div>
    </div>
  );
}

export default Layout;
