import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { useStyles } from './BaseModalLayout.styles';
import textLogoWhite from '../../img/textLogo.png';

export default function BaseModalLayout(props) {
  const classes = useStyles();
  const [fontStyle, setFontStyle] = useState('TheJamsil');

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  return (
    <Modal className={fontStyle} open={props.open} onClose={props.onClose}>
      <div className={`${classes.container} ${props.sizeMax && classes.sizeMax} ${props.sizeMid && classes.sizeMid}`}>
        {props.showHeaderLogo && <img className={classes.sorisoriAIImg} src={textLogoWhite} alt="sorisoriAI" />}
        {props.children}
      </div>
    </Modal>
  );
}
