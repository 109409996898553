import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  messageContainer: {
    backgroundColor: '#191919',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '350px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '32px 0',
    wordBreak: 'keep-all!important',
    borderRadius: '24px',
    '&:active': { outline: 'none' },
    '&:focus': { outline: 'none' },
  },
  sorisoriAIImg: {
    width: '200px',
    marginBottom: '24px',
  },
  messageOneContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0 24px',
  },
  messageOne: {
    fontSize: '16px',
    fontWeight: '300',
    color: '#c4c4c4',
    textAlign: 'center',
    lineHeight: '22px',
  },
  button: {
    backgroundColor: '#CAFF73',
    color: '#191919',
    fontSize: '16px',
    padding: '12.8px 24px',
    borderRadius: '32px',
    cursor: 'pointer',
    marginTop: '30px',
  },
}));

export { useStyles };
