import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStyles } from './FaceSwap.styles';

import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Slider from '@mui/material/Slider';
import Modal from '@mui/material/Modal';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';

import FacesArrow from '../../../src/img/faceswap-arrow.svg';
import FacesPlus from '../../../src/img/faceswap-plus.svg';
import Settings from '../../../src/img/faceswap-settings.svg';
import Upload from '../../../src/img/faceswap-upload.svg';
import Download from '../../../src/img/faceswap-download.svg';
import WaterMarkImg from '../../../src/img/combined-logo-white.png';

import {
  uploadYoutubeVideoFile,
  detectFacesInImage,
  getYoutubeInfo,
  fetchVideoUrl,
  uploadFaceSwap,
  fetchSubscriptionInfo,
  getPreviewImage,
  getFaceSwapStatus,
  fetchRenderedVideoUrl,
  checkFaceSwapCompletion,
} from '../../services/page.services';
import { userActions } from '../../actions/user.actions.js';
import { checkValidLoginStatus } from '../../utils/user.utils';
import { showMessageV2 } from '../../utils/page.utils';

import FaceSwapSettings from '../layout/FaceSwapSettings.js';
import VideoLabelModal from '../layout/VideoLabelModal.js';
import HtmlTooltip from '../HtmlTooltip.js';
import tooltipImg from '../../img/tooltip.png';

function FaceSwapPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const userId = user?.id;
  const faceBoxRefs = useRef({});
  const isAdmin = user?.subscription?.type === 'ADMIN';
  const isCustom = user?.subscription?.type === 'CUSTOM';
  const intervalId = React.useRef(null);
  const videoRef = useRef(null);
  const videoContainerRef = useRef(null);
  const [fps, setFPS] = useState(30);
  const finalVideoRef = useRef(null);

  // State Initialization
  const [content, setContent] = useState({});

  const [uploadState, setUploadState] = useState({
    uploadedVideoUrl: null,
    videoLoading: false,
    finalVideoUrl: null,
    finalVideoLoading: false,
    distance: 0.73,
    leastFrame: 4,
    objectKey: null,
  });

  const [videoState, setVideoState] = useState({
    currentFrame: 0,
    currentTime: 0,
    duration: 0,
  });
  const [editedDuration, setEditedDuration] = useState(0);

  const [sliderState, setSliderState] = useState({
    sliderValue: 0,
    range: [0, 0, 100],
  });

  const [finalSliderState, setFinalSliderState] = useState({
    range: 0,
  });
  const [isFinalVideoPlaying, setIsFinalVideoPlaying] = useState(false);

  const [settingsState, setSettingsState] = useState({
    isSharpnessOn: false,
    isWatermarkOn: true,
    blendRatio: 70,
  });

  const [originalFaceData, setOriginalFaceData] = useState([]);
  const [faceData, setFaceData] = useState([]);
  // {
  //   index: Number, // Index of the face
  //   data: Object, // { face: [Object], frame: number } (preview endpoint request data: originalFaceData)
  //   croppedFace: String, // Data URL of the face image extracted from the video
  //   selected: Boolean, // Whether the face is selected
  //   selectedNewFace: String, // Selected new face; if not selected, null
  //   newImage: File,  // Original image file of the selected new face (preview endpoint request data)
  //   newFaceData: Object, // Index data of the selected new face (preview endpoint request data)
  //   bboxLength: Number,  // For testing
  // }

  const [modalImageIndex, setModalImageIndex] = useState(null);
  const [croppedFacesInImage, setCroppedFacesInImage] = useState([]);
  const [croppedFacesData, setCroppedFacesData] = useState([]);
  const [previewImageURL, setPreviewImageURL] = useState(null);
  const [exportName, setExportName] = useState('');
  const [rendering, setRendering] = useState(false);

  const [videoReady, setVideoReady] = useState(false);
  const [isFaceSelectionModalOpen, setIsFaceSelectionModalOpen] = useState(false);
  const [isProcessingFaces, setIsProcessingFaces] = useState(false);
  const [isImageProcessing, setIsImageProcessing] = useState(false);

  const [requiredCoins, setRequiredCoins] = useState(0);
  const tierToShowOutputFormat = ['PREMIUM', 'PRO', 'ADMIN', 'CUSTOM'];
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [showCoinUpgrade, setShowCoinUpgrade] = useState(false);
  const [showRenderDelay, setShowRenderDelay] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [faceSwapRemainingcount, setFaceSwapRemainingcount] = useState(0);
  const [faceSwapInProgress, setFaceSwapInProgress] = useState(false);
  const [loadingText, setLoadingText] = useState([]);
  const [videoExportModalOpen, setVideoExportModalOpen] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [showReloadDialog, setShowReloadDialog] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (userId) (async () => initPage())();
  }, [userId]);

  // Effect to manage subscription-based UI disabling
  useEffect(() => {
    if (!tierToShowOutputFormat.includes(user.subscription?.type)) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [user.subscription?.type]);

  // price logic
  useEffect(() => {
    const selectedDuration = Math.ceil(editedDuration / 15);
    const requiredCoins = selectedDuration * 15 * faceData.filter(face => face.selected).length;
    setRequiredCoins(requiredCoins);
  }, [editedDuration, faceData.filter(face => face.selected)]);

  useEffect(() => {
    const hasNewFaceData = faceData.filter(face => face.newFaceData !== null);
    if (hasNewFaceData.length > 0) {
      //showPreview();
    }
  }, [faceData.filter(face => face.newFaceData !== null).join(',')]);

  useEffect(() => {
    if (rendering) {
      handleStart();
    }
  }, [rendering]);

  // After faceData, videoReady are ready, detect faces
  useEffect(() => {
    if (originalFaceData.length > 0 && videoReady && !isProcessingFaces) {
      processFaces();
    }
  }, [originalFaceData, videoReady]);

  // Monitor video playback progress
  useEffect(() => {
    let progressInterval = null;
    if (uploadState.uploadedVideoUrl) {
      progressInterval = setInterval(() => {
        const videoElement = videoRef.current;
        if (videoElement && videoElement.duration > 0) {
          const newProgress = (videoElement.currentTime / videoElement.duration) * 100;
          setSliderState(prev => ({
            ...prev,
            sliderValue: newProgress,
          }));
          setVideoState(prev => ({
            ...prev,
            currentFrame: Math.floor(videoElement.currentTime * 30),
            currentTime: videoElement.currentTime,
            duration: videoElement.duration,
          }));
        }
      }, 500);
    }
    return () => {
      if (progressInterval) clearInterval(progressInterval);
    };
  }, [uploadState.uploadedVideoUrl]);

  useEffect(() => {
    let finalInterval = null;
    if (uploadState.finalVideoUrl && finalVideoRef.current) {
      const finalVideoElement = finalVideoRef.current;
      finalInterval = setInterval(() => {
        if (finalVideoElement.duration > 0 && !finalVideoElement.paused) {
          const percentage = (finalVideoElement.currentTime / finalVideoElement.duration) * 100;
          setFinalSliderState({ range: percentage });
        }
      }, 300);
    }
    return () => {
      if (finalInterval) clearInterval(finalInterval);
    };
  }, [uploadState.finalVideoUrl]);

  useEffect(() => {
    return () => {
      if (previewImageURL) {
        URL.revokeObjectURL(previewImageURL);
      }
    };
  }, [previewImageURL]);

  const initPage = async () => {
    try {
      //if (!isAdmin && !isCustom) {
      // TODO
      // to Haesol: please develop a function checks if the face swap is in progress or not
      await checkFaceSwapInProgress();
      //}
      await checkRemainingCount();
    } catch (e) {
      console.log(e);
      showMessageV2(dispatch, t('modal.pageLoadFail'), { reloadOnClose: true });
    }
  };

  const checkFaceSwapInProgress = async () => {
    const faceSwaps = await checkFaceSwapCompletion();
    const inProgress = _.some(faceSwaps, { status: 'PENDING' });
    // console.log('faceSwaps: ', faceSwaps);
    // console.log('inProgress: ', inProgress);
    setFaceSwapInProgress(inProgress);
  };

  const checkRemainingCount = async () => {
    let subscriptionInfo = await fetchSubscriptionInfo();
    dispatch(userActions.updateSubscription(subscriptionInfo));
    // console.log('subscriptionInfo: ', subscriptionInfo);
    setFaceSwapRemainingcount(subscriptionInfo?.faceSwapRemaining || 0);
    return subscriptionInfo;
  };

  // update the size of videoContainer for Watermark
  useEffect(() => {
    const videoElement = videoRef.current;
    const containerElement = videoContainerRef.current;
    if (videoElement && containerElement) {
      const updateContainerSize = () => {
        const videoRect = videoElement.getBoundingClientRect();
        containerElement.style.width = `${videoRect.width}px`;
        containerElement.style.height = `${videoRect.height}px`;
      };
      updateContainerSize();
      const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          if (entry.target === videoElement) {
            updateContainerSize();
          }
        }
      });
      resizeObserver.observe(videoElement);
      return () => {
        resizeObserver.unobserve(videoElement);
      };
    }
  }, []);

  // Handle video upload
  const handleYoutubeUpload = async () => {
    setUploadState(prev => ({ ...prev, videoLoading: true }));
    try {
      const { duration, valid, name } = await getYoutubeInfo(content.youtubeLink);
      if (!valid) {
        setContent({ ...content, youtubeLink: '', name: null });
        showMessageV2(dispatch, t('addSourceModal.youtube.invalidYoutubeLink'), { reloadOnClose: true });
      } else if (duration > 300) {
        setContent({ ...content, youtubeLink: '', name: null });
        showMessageV2(dispatch, t('faceSwapTab.error.youtubeLinkTooLong'), { reloadOnClose: true });
      } else {
        // setUploadState(prev => ({ ...prev, videoLoading: false }));
        setContent({
          ...content,
          name,
        });
        setExportName(name);
        const distanceNum = uploadState.distance;
        const leastFrameNum = uploadState.leastFrame;
        const response = await uploadYoutubeVideoFile(content.youtubeLink, name, distanceNum, leastFrameNum);
        if (response) {
          setUploadState(prev => ({ ...prev, uploadedVideoUrl: response.signedUrl, objectKey: response.objectKey }));
          setEditedDuration(duration);
          setOriginalFaceData(response.faceData.faces);
          setFPS(response.faceData.fps);
        } else {
          showMessageV2(dispatch, t('inferenceTab.modal.uploadFail'), { reloadOnClose: true });
        }
      }
    } catch (error) {
      showMessageV2(dispatch, t('inferenceTab.submit.tryAgain'), { reloadOnClose: true });
      console.log('YouTube video upload error:', error);
    } finally {
      setUploadState(prev => ({ ...prev, videoLoading: false }));
    }
  };

  // Handle video deletion
  const handleDeleteVideo = () => {
    setUploadState({
      uploadedVideoUrl: null,
      videoLoading: false,
      finalVideoUrl: null,
      distance: 0.73,
      leastFrame: 4,
      objectKey: null,
    });
    setSliderState({
      sliderValue: 0,
      range: [0, 0, 100],
    });
    setSettingsState({
      isSharpnessOn: false,
      isWatermarkOn: true,
      blendRatio: 70,
    });
    setVideoReady(false);
    setEditedDuration(0);
    setRequiredCoins(0);
    setPreviewImageURL(null);
    setFaceData([]);
    setOriginalFaceData([]);
  };

  // Handle blend ratio change
  const handleBlendRatioChange = (event, newValue) => {
    if (newValue >= 0 && newValue <= 100) {
      setSettingsState(prev => ({ ...prev, blendRatio: newValue }));
    }
  };

  // Handle range slider change
  const handleRangeChange = (event, newValue, activeThumb) => {
    setPreviewImageURL(null);

    let [start, mid, end] = newValue;

    if (activeThumb === 0) {
      start = Math.min(start, mid);
    } else if (activeThumb === 1) {
      mid = Math.max(start, Math.min(mid, end));
    } else if (activeThumb === 2) {
      end = Math.max(end, mid);
    }

    const newDuration = Math.ceil(((end - start) / 100.0) * videoState.duration);
    setEditedDuration(newDuration);
    setSliderState(prev => ({ ...prev, range: [start, mid, end] }));
    updateVideoCurrentTime(activeThumb, start, mid, end);
  };

  const handleSliderPointerDown = event => {
    if (event.target.classList.contains('MuiSlider-thumb')) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    setPreviewImageURL(null);

    const slider = event.currentTarget;
    const rect = slider.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const trackWidth = rect.width;

    const clickValue = (x / trackWidth) * 100;

    const [start, middle, end] = sliderState.range;

    if (clickValue >= start && clickValue <= end) {
      const newRange = [start, clickValue, end];
      setSliderState(prev => ({ ...prev, range: newRange }));

      const video = videoRef.current;
      if (video && video.duration) {
        const newTime = (clickValue / 100) * video.duration;
        video.currentTime = newTime;
      }
    }
  };

  // Update video current time based on slider
  const updateVideoCurrentTime = (activeThumb, start, mid, end) => {
    const videoElement = videoRef.current;
    if (videoElement && videoElement.duration) {
      const duration = videoElement.duration;
      let newTime;

      switch (activeThumb) {
        case 0:
          newTime = (start / 100) * duration;
          break;
        case 1:
          newTime = (mid / 100) * duration;
          break;
        case 2:
          newTime = (end / 100) * duration;
          break;
        default:
          newTime = 0;
      }

      videoElement.currentTime = newTime;
      videoElement.pause();

      videoElement.ontimeupdate = () => {
        if (videoElement.currentTime >= (end / 100) * duration) {
          videoElement.pause();
        }
      };
    }
  };

  // Handle video time update
  const handleTimeUpdate = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      setVideoState(prev => ({
        ...prev,
        currentTime: videoElement.currentTime,
        duration: videoElement.duration,
        currentFrame: Math.floor(videoElement.currentTime * 30),
      }));
      setSliderState(prev => ({
        ...prev,
        sliderValue: (videoElement.currentTime / videoElement.duration) * 100,
      }));
    }
  };

  // Handle video metadata loaded
  const handleLoadedMetadata = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      setVideoState(prev => ({
        ...prev,
        duration: videoElement.duration,
      }));
    }
    setVideoReady(true);
  };

  const processFaces = async () => {
    setIsProcessingFaces(true);
    try {
      const videoElement = videoRef.current;

      const processedFaceData = [];
      for (let i = 0; i < originalFaceData.length; i++) {
        const faceObj = originalFaceData[i];
        const frameNumber = faceObj.frame;
        const time = frameNumber / fps;
        await seekVideoToTime(videoElement, time);

        // crop faces
        const imageDataUrl = await captureAndCropFace(videoElement, faceObj.face.bbox);
        processedFaceData.push({
          index: i,
          data: faceObj,
          croppedFace: imageDataUrl,
          selected: false,
          selectedNewFace: null,
          newImage: null,
          newFaceData: null,
          bboxLength: faceObj.appearance_count,
          appearance_count: faceObj.appearance_count,
        });
      }
      setFaceData(processedFaceData);
    } catch (error) {
      console.error('Error processing faces:', error);
      showMessageV2(dispatch, t('faceSwapTab.error.facesInImage'), { reloadOnClose: true });
    } finally {
      setIsProcessingFaces(false);
    }
  };

  const captureOriginalImage = videoElement => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

      canvas.toBlob(blob => {
        if (blob) {
          const file = new File([blob], 'captured_frame.png', { type: 'image/png' });
          resolve(file);
        } else {
          reject(new Error('Canvas is empty'));
        }
      }, 'image/png');
    });
  };

  const showPreview = async () => {
    try {
      const videoElement = videoRef.current;
      const imageBuffer = await captureOriginalImage(videoElement);
      const oldFacesDataArray = faceData.map(fd => fd.data.face.embedding);
      const newFacesDataArray = faceData.map(fd => (fd.newFaceData ? fd.newFaceData.embedding : null));
      const distanceNum = uploadState.distance;

      const selectedIndices = faceData.map((fd, idx) => (fd.selected ? idx : null)).filter(idx => idx !== null);

      const filteredOldFacesData = selectedIndices.map(idx => ({
        embedding: oldFacesDataArray[idx],
      }));

      const filteredNewFacesData = selectedIndices.map(idx => ({
        embedding: newFacesDataArray[idx],
      }));
      setPreviewImageURL(null);
      // console.log('filteredOldFacesData:', filteredOldFacesData);
      // console.log('filteredNewFacesData:', filteredNewFacesData);
      if (filteredOldFacesData.length === 0 || filteredNewFacesData.length === 0) {
        showMessageV2(dispatch, t('faceSwapTab.error.noFacesSelected'));
        return;
      }

      setPreviewLoading(true);
      const previewResponse = await getPreviewImage(
        distanceNum,
        imageBuffer,
        filteredOldFacesData,
        filteredNewFacesData,
        settingsState.isSharpnessOn,
        settingsState.blendRatio
      );

      if (previewResponse) {
        const imageUrl = URL.createObjectURL(new Blob([previewResponse], { type: 'image/jpeg' }));
        setPreviewImageURL(imageUrl);
      } else {
        showMessageV2(dispatch, t('faceSwapTab.error.invalidPreviewResponse'), { reloadOnClose: true });
      }
    } catch (error) {
      showMessageV2(dispatch, t('faceSwapTab.error.showPreview'), { reloadOnClose: true });
    } finally {
      setPreviewLoading(false);
      setIsProcessingFaces(false);
    }
  };

  const seekVideoToTime = (videoElement, time) => {
    return new Promise((resolve, reject) => {
      const onSeeked = () => {
        videoElement.removeEventListener('seeked', onSeeked);
        resolve();
      };
      videoElement.addEventListener('seeked', onSeeked);
      videoElement.currentTime = time;
    });
  };

  const captureAndCropFace = (videoElement, bbox) => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const [x1, y1, x2, y2] = bbox;
      const width = x2 - x1;
      const height = y2 - y1;
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(videoElement, x1, y1, width, height, 0, 0, width, height);
      const dataUrl = canvas.toDataURL('image/png');
      resolve(dataUrl);
    });
  };

  // Handle face deletion
  const handleDeleteSelectedFace = index => {
    setFaceData(prevFaceData =>
      prevFaceData.map(face =>
        face.index === index
          ? { ...face, selected: false, selectedNewFace: null, newImage: null, newFaceData: null }
          : face
      )
    );
  };

  // Handle slider interaction start
  const handleSliderMouseDown = () => {
    setSliderState(prev => ({ ...prev }));
  };

  // Handle slider interaction end
  const handleSliderMouseUp = () => {
    setSliderState(prev => ({ ...prev }));
    /*
    if (lastActiveThumb === 1) {
      setTimeout(() => {
        showPreview();
      }, 500);
      showPreview();
    }
    */
  };

  // Handle settings toggle
  const handleSharpnessToggle = () => {
    setSettingsState(prev => ({ ...prev, isSharpnessOn: !prev.isSharpnessOn }));
  };

  const handleWatermarkToggle = () => {
    setSettingsState(prev => ({ ...prev, isWatermarkOn: !prev.isWatermarkOn }));
    if (isDisabled) {
      setSettingsState(prev => ({ ...prev, isWatermarkOn: true }));
      setShowUpgrade(true);
    }
  };

  // Handle image change
  const handleImageChange = async (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!validTypes.includes(file.type)) {
        showMessageV2(dispatch, t('faceSwapTab.error.invalidFileType'), { reloadOnClose: true });
        return;
      }

      if (file.size > maxSize) {
        showMessageV2(dispatch, t('faceSwapTab.error.fileTooLarge'), { reloadOnClose: true });
        return;
      }

      try {
        setIsImageProcessing(true);

        const data = await detectFacesInImage(file);

        if (data.faces && data.faces.length > 0) {
          const reader = new FileReader();
          reader.onloadend = () => {
            const imageDataUrl = reader.result;
            const img = new Image();
            img.src = imageDataUrl;

            img.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');

              const croppedImages = data.faces.map(face => {
                const [x1, y1, x2, y2] = face.bbox;
                const width = x2 - x1;
                const height = y2 - y1;

                canvas.width = width;
                canvas.height = height;
                ctx.clearRect(0, 0, width, height);
                ctx.drawImage(img, x1, y1, width, height, 0, 0, width, height);
                return canvas.toDataURL('image/png');
              });

              setCroppedFacesInImage(croppedImages);
              setCroppedFacesData(data.faces);
              setIsImageProcessing(false);

              setFaceData(prevData => {
                const updatedData = [...prevData];
                updatedData[index] = { ...prevData[index], newImage: file };
                return updatedData;
              });
            };
          };
          reader.readAsDataURL(file);
        } else {
          showMessageV2(dispatch, t('faceSwapTab.error.noFacesDetected'), { reloadOnClose: true });
          setIsImageProcessing(false);
        }
      } catch (error) {
        console.error('Face detection error:', error);
        showMessageV2(dispatch, t('faceSwapTab.error.faceDetectApiError'), { reloadOnClose: true });
        setIsImageProcessing(false);
      }
    }
  };

  // Handle settings modal open
  const handleSettingsButtonClick = () => {
    setIsSettingsModalOpen(true);
  };

  const handleOpenVideoLabelModal = () => {
    if (faceSwapInProgress) {
      showMessageV2(dispatch, t('faceSwapTab.modal.inProgress'), { reloadOnClose: true });
    } else if (requiredCoins > faceSwapRemainingcount) {
      setShowCoinUpgrade(true);
    } else if (faceData.filter(face => face.selected).length === 0) {
      showMessageV2(dispatch, t('faceSwapTab.error.noFacesSelected'), { reloadOnClose: true });
      return;
    } else {
      setVideoExportModalOpen(true);
    }
  };

  // Polling for check face swap status
  const pollFaceSwapStatus = (faceSwapId, youtubeId) => {
    const interval = setInterval(async () => {
      try {
        const response = await getFaceSwapStatus(faceSwapId); //await axiosInstance.get(`/faceSwap/status/${faceSwapId}`);
        // console.log('response:', response);
        const status = response.status;
        if (status === 'COMPLETE') {
          clearInterval(interval);
          clearTimeout(timeoutId);
          setLoadingText([t('faceSwapTab.loading.5')]);
          const videoResponse = await fetchRenderedVideoUrl(faceSwapId);
          if (videoResponse && videoResponse.url) {
            setUploadState(prev => ({ ...prev, finalVideoUrl: videoResponse.url }));
          }
          setLoadingText([]);
        } else if (status === 'FAILED') {
          clearInterval(interval);
          clearTimeout(timeoutId);
          setLoadingText([]);
          showMessageV2(dispatch, t('faceSwapTab.error.renderingFailed'), { reloadOnClose: true });
        }
        // if status == 'PENDING', keep polling
      } catch (error) {
        console.error('Error polling face swap status:', error);
      }
    }, 5000);

    const timeoutId = setTimeout(
      () => {
        clearInterval(interval);
        setShowRenderDelay(true);
      },
      10 * 60 * 1000
    );
  };

  // Handle start face swap
  const handleStart = async () => {
    setUploadState({ ...uploadState, finalVideoLoading: true });
    setLoadingText([
      t('faceSwapTab.loading.finalVideoGenerating.0'),
      t('faceSwapTab.loading.finalVideoGenerating.1'),
      t('faceSwapTab.loading.finalVideoGenerating.2'),
      t('faceSwapTab.loading.finalVideoGenerating.3'),
    ]);

    const oldFacesDataArray = faceData.map(fd => fd.data.face.embedding);
    const newFacesDataArray = faceData.map(fd => (fd.newFaceData ? fd.newFaceData.embedding : null));
    const distanceNum = uploadState.distance;

    const selectedIndices = faceData.map((fd, idx) => (fd.selected ? idx : null)).filter(idx => idx !== null);

    const filteredOldFacesData = selectedIndices.map(idx => ({
      embedding: oldFacesDataArray[idx],
    }));

    const filteredNewFacesData = selectedIndices.map(idx => ({
      embedding: newFacesDataArray[idx],
    }));

    if (filteredOldFacesData.length === 0 || filteredNewFacesData.length === 0) {
      showMessageV2(dispatch, t('faceSwapTab.error.noFacesSelected'));
      return;
    }

    try {
      const label = exportName;
      const tier = user.subscription.type;
      const startTime = Math.floor((sliderState.range[0] / 100) * videoState.duration);
      const endTime = Math.ceil((sliderState.range[2] / 100) * videoState.duration);
      const faceCount = faceData.filter(face => face.selected).length;
      const watermark = settingsState.isWatermarkOn;
      // console.log('watermark:', watermark);
      const faceSwapSharpness = settingsState.isSharpnessOn;
      const faceSwapBlendRatio = settingsState.blendRatio;
      const anonymous = true;
      const userName = user.name;
      const usedCoins = Math.max(1, Math.ceil(editedDuration / 15) * 15 * faceCount);
      const youtubeLink = content.youtubeLink;

      const response = await uploadFaceSwap(
        userId,
        label,
        tier,
        startTime,
        endTime,
        faceCount,
        watermark,
        faceSwapSharpness,
        faceSwapBlendRatio,
        anonymous,
        userName,
        usedCoins,
        youtubeLink,
        distanceNum,
        filteredOldFacesData,
        filteredNewFacesData,
        settingsState.isSharpnessOn,
        settingsState.blendRatio
      );

      if (response && response.id && response.youtubeId) {
        pollFaceSwapStatus(response.id, response.youtubeId);
      } else {
        showMessageV2(dispatch, t('faceSwapTab.error.uploadFailed'));
        setLoadingText([]);
      }
    } catch (e) {
      setUploadState({ ...uploadState, finalVideoLoading: false });
      setLoadingText([]);
      setShowReloadDialog(true);
      return;
    }
  };

  // Handle distance change
  const handleDistanceChange = (event, newValue) => {
    if (newValue >= 0 && newValue <= 1) {
      setUploadState(prev => ({ ...prev, distance: newValue }));
    }
  };

  // Final video Play or Pause
  const handleFinalPlay = () => {
    const video = finalVideoRef.current;
    if (!video) return;

    video.play();
    setIsFinalVideoPlaying(true);
  };

  // Final video Stop
  const handleFinalPause = () => {
    const video = finalVideoRef.current;
    if (!video) return;

    video.pause();
    setIsFinalVideoPlaying(false);
  };

  // Final video Sider
  const handleFinalSliderChange = (e, newValue) => {
    const video = finalVideoRef.current;
    setFinalSliderState({ range: newValue });
    if (video && video.duration) {
      const seekTime = (newValue / 100) * video.duration;
      video.currentTime = seekTime;
    }
  };

  // Format time in mm:ss
  const formatTime = seconds => {
    if (isNaN(seconds)) return '00:00';
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  const handleDownload = async url => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const tempLink = document.createElement('a');
      tempLink.href = blobUrl;

      let fileName = exportName ? exportName : 'download';
      if (!fileName.toLowerCase().endsWith('.mp4')) {
        fileName += '.mp4';
      }

      tempLink.download = fileName;

      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);

      window.URL.revokeObjectURL(blobUrl);
    } catch (e) {
      console.log('Download error:', e);
      showMessageV2(dispatch, t('faceSwapTab.error.downloadFailed'));
    }
  };

  const handleApply = async newSettings => {
    setSettingsState(prevSettings => ({
      ...prevSettings,
      ...newSettings,
    }));
    setIsSettingsModalOpen(false);
    await showPreview();
  };

  const handleReloadDialog = () => {
    setShowReloadDialog(false);
    window.location.reload();
  };

  return (
    <>
      <VideoLabelModal
        open={videoExportModalOpen}
        onVideoExportModalClose={() => setVideoExportModalOpen(false)}
        exportName={exportName}
        setExportName={setExportName}
        rendering={rendering}
        setRendering={setRendering}
      />
      <div>
        <div className={classes.pageTitle}>{t('faceSwapTab.title')}</div>
        <div className={classes.separater} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {uploadState.uploadedVideoUrl ? (
              <>
                {rendering ? (
                  <>
                    <div className={classes.stepHeader}>{t('faceSwapTab.stepThree.title')}</div>
                    <div className={classes.stepSubHeader}>{t('faceSwapTab.stepThree.subTitles')}</div>
                  </>
                ) : (
                  <>
                    <div className={classes.stepHeader}>{t('faceSwapTab.stepTwo.title')}</div>
                    <div className={classes.stepSubHeader}>{t('faceSwapTab.stepTwo.subTitles')}</div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className={classes.stepHeader}>{t('faceSwapTab.stepOne.title')}</div>
                <div className={classes.stepSubHeader}>{t('faceSwapTab.stepOne.subTitles')}</div>
              </>
            )}
            <div className={classes.recordButtonContainer}>
              {uploadState.uploadedVideoUrl ? (
                <>
                  {rendering ? (
                    <>
                      {uploadState.finalVideoUrl ? (
                        <Grid container className={classes.allContainer}>
                          <Grid item id="video-container" className={classes.previewContainer}>
                            <div className={classes.tag}>{t('faceSwapTab.stepThree.tags.sourceFile')}</div>
                            <Button
                              className={classes.youtubeInfoDeleteIconBox}
                              onClick={() => handleDownload(uploadState.finalVideoUrl)}
                              aria-label={t('faceSwapTab.button.deleteVideo')}
                              style={{ backgroundColor: '#fff!important', border: '2px solid #fff' }}
                            >
                              <img src={Download} alt="download" className={classes.youtubeInfoDeleteIcon} />
                            </Button>
                            <div className={classes.videoContainer} ref={videoContainerRef}>
                              <video
                                ref={finalVideoRef}
                                id="final-video"
                                src={uploadState.finalVideoUrl + '#t=0.01'}
                                crossOrigin="anonymous"
                                className={classes.responsiveVideo}
                              />
                            </div>
                          </Grid>
                          <Grid item className={classes.customControlsContainer}>
                            <div className={classes.resultPlayBarContainer}>
                              <Box display="flex" alignItems="center">
                                {isFinalVideoPlaying ? (
                                  <PauseCircleOutlineIcon
                                    className={classes.resultPlayPauseButton}
                                    onClick={handleFinalPause}
                                  />
                                ) : (
                                  <PlayCircleOutlineIcon
                                    className={classes.resultPlayPauseButton}
                                    onClick={handleFinalPlay}
                                  />
                                )}
                              </Box>
                              <div className={classes.resultSliderWrapper}>
                                <Slider
                                  value={finalSliderState.range}
                                  onChange={handleFinalSliderChange}
                                  min={0}
                                  max={100}
                                  step={0.1}
                                  aria-labelledby="final-video-slider"
                                  classes={{ root: classes.resultSliderRoot }}
                                />
                              </div>
                            </div>
                            <div className={classes.sliderTime}>
                              <span>{formatTime((finalSliderState.range / 100) * editedDuration)}</span>
                              <span>/</span>
                              <span>{formatTime(editedDuration)}</span>
                            </div>
                          </Grid>
                          <div className={classes.createNewButton} onClick={() => window.location.reload()}>
                            {t('faceSwapTab.button.createNew')}
                          </div>
                        </Grid>
                      ) : (
                        <>
                          {uploadState.finalVideoLoading && (
                            <Grid container className={classes.resultLoadingContainer}>
                              <CircularProgress style={{ width: '2rem', height: '2rem', color: 'white' }} />
                              <div className={classes.resultLoadingText}>
                                <p>{loadingText[0]}</p>
                                <p>{loadingText[1]}</p>
                                <p>{loadingText[2]}</p>
                                <p>{loadingText[3]}</p>
                              </div>
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Box className={classes.rootContainer}>
                        {/* Video Player Section */}
                        <Grid container className={classes.allContainer}>
                          <div className={classes.previewVideoContainer}>
                            {previewLoading && (
                              <div className={classes.loadingContainer}>
                                <div className={classes.allFacesloadingText}>
                                  {t('faceSwapTab.loading.4')
                                    .split('')
                                    .map((char, index) => (
                                      <span key={index} style={{ animationDelay: `${index * 0.1}s` }}>
                                        {char}
                                      </span>
                                    ))}
                                </div>
                              </div>
                            )}
                            <Grid item id="video-container" className={classes.previewContainer}>
                              <div className={classes.tag}>{t('faceSwapTab.stepTwo.tags.preview')}</div>
                              <Button
                                className={classes.youtubeInfoDeleteIconBox}
                                onClick={handleDeleteVideo}
                                aria-label={t('faceSwapTab.button.deleteVideo')}
                              >
                                <DeleteIcon className={classes.youtubeInfoDeleteIcon} />
                              </Button>
                              <div className={classes.videoContainer} ref={videoContainerRef}>
                                <video
                                  ref={videoRef}
                                  id="uploaded-video"
                                  src={uploadState.uploadedVideoUrl + '#t=0.01'}
                                  crossOrigin="anonymous"
                                  onTimeUpdate={handleTimeUpdate}
                                  onLoadedMetadata={handleLoadedMetadata}
                                  className={classes.responsiveVideo}
                                  preload="auto"
                                  autoplay
                                />
                                {!previewLoading && previewImageURL && (
                                  <img src={previewImageURL} alt="Preview" className={classes.previewImage} />
                                )}
                                {settingsState.isWatermarkOn && videoReady && (
                                  <img src={WaterMarkImg} alt="Watermark" className={classes.watermarkImage} />
                                )}
                              </div>
                              <div className={classes.previewButton} onClick={showPreview}>
                                {t('faceSwapTab.button.preview')}
                              </div>
                            </Grid>
                            {/* Custom Controls */}
                            <Grid item className={classes.customControlsContainer}>
                              <div className={classes.sliderWrapper}>
                                <Slider
                                  value={sliderState.range}
                                  onChange={handleRangeChange}
                                  min={0}
                                  max={100}
                                  step={1}
                                  onMouseDown={handleSliderMouseDown}
                                  onMouseUp={handleSliderMouseUp}
                                  disableSwap
                                  onPointerDown={handleSliderPointerDown}
                                  valueLabelDisplay="auto"
                                  ValueLabelComponent={props => (
                                    <ThumbValueLabel {...props} index={props['data-index']} />
                                  )}
                                  valueLabelFormat={value => {
                                    const videoElement = videoRef.current;
                                    if (videoElement && videoElement.duration) {
                                      const timeInSeconds = (value / 100) * videoElement.duration;
                                      return formatTime(timeInSeconds);
                                    }
                                    return formatTime(0);
                                  }}
                                  classes={{
                                    root: classes.sliderRoot,
                                  }}
                                  aria-labelledby="range-slider"
                                />
                              </div>
                              <div className={classes.sliderTime}>
                                <span>
                                  {formatTime(
                                    ((sliderState.range[1] - sliderState.range[0]) / 100) * videoState.duration
                                  )}
                                </span>
                                <span>/</span>
                                <span>
                                  {formatTime(
                                    ((sliderState.range[2] - sliderState.range[0]) / 100) * videoState.duration
                                  )}
                                </span>
                              </div>
                            </Grid>
                          </div>
                          <Grid item className={classes.facesContainer}>
                            <div className={classes.tag}>{t('faceSwapTab.stepTwo.tags.detectedFaces')}</div>
                            <Grid className={classes.scrollContainer}>
                              {isProcessingFaces ? (
                                <>
                                  <div />
                                  <div className={classes.allFacesloadingText}>
                                    {t('faceSwapTab.loading.1')
                                      .split('')
                                      .map((char, index) => (
                                        <span key={index} style={{ animationDelay: `${index * 0.1}s` }}>
                                          {char}
                                        </span>
                                      ))}
                                  </div>
                                </>
                              ) : (
                                <>
                                  {faceData.length === 0 ? (
                                    <div className={classes.noFacesFoundText}>
                                      {t('faceSwapTab.error.noFacesFound')}
                                    </div>
                                  ) : (
                                    <>
                                      {faceData
                                        .filter(face => face.selected)
                                        .map((face, idx) => (
                                          <div
                                            key={idx}
                                            className={classes.faceBox}
                                            ref={el => (faceBoxRefs.current[idx] = el)}
                                          >
                                            <DeleteIcon
                                              className={classes.faceDeleteIcon}
                                              onClick={() => handleDeleteSelectedFace(idx)}
                                            />
                                            <img
                                              src={FacesArrow}
                                              alt="arrow"
                                              className={`${classes.facesArrowOne} ${classes.facesArrowSelected}`}
                                            />
                                            <img
                                              className={`${classes.faceFromFrame} ${classes.selectedFaceSelected}`}
                                              src={face.croppedFace}
                                              alt={`Face from frame ${face.index}`}
                                            />
                                            <div
                                              className={classes.facesInputContainer}
                                              onClick={() => {
                                                setModalImageIndex(face.index);
                                                setIsFaceSelectionModalOpen(true);
                                              }}
                                            >
                                              <img
                                                src={face.selectedNewFace}
                                                alt="Selected Face"
                                                className={`${classes.selectedFace} ${classes.selectedFaceSelected}`}
                                              />
                                            </div>
                                            <img
                                              src={FacesArrow}
                                              alt="arrow"
                                              className={`${classes.facesArrowTwo} ${classes.facesArrowSelected}`}
                                            />
                                          </div>
                                        ))}

                                      {faceData.filter(face => face.selected).length > 0 && faceData.length > 0 && (
                                        <div className={classes.verticalDivider} />
                                      )}

                                      {faceData
                                        .filter(face => !face.selected)
                                        .map((face, idx) => (
                                          <div className={classes.faceBox} key={idx}>
                                            <img src={FacesArrow} alt="arrow" className={classes.facesArrowOne} />
                                            <img
                                              className={classes.faceFromFrame}
                                              src={face.croppedFace}
                                              alt={`Face from frame ${face.index}`}
                                            />
                                            <div
                                              className={classes.facesInputContainer}
                                              onClick={() => {
                                                setModalImageIndex(face.index);
                                                setIsFaceSelectionModalOpen(true);
                                              }}
                                            >
                                              <img src={FacesPlus} alt="Add Face" className={classes.facesPlus} />
                                            </div>
                                            <img src={FacesArrow} alt="arrow" className={classes.facesArrowTwo} />
                                            {/* <div className={classes.bboxLengthText}>{face.bboxLength}</div> */}
                                          </div>
                                        ))}
                                    </>
                                  )}
                                </>
                              )}
                            </Grid>
                          </Grid>
                          <Grid item className={classes.buttonContainer}>
                            <div
                              className={classes.button}
                              onClick={handleSettingsButtonClick}
                              aria-label={t('faceSwapTab.button.settings')}
                            >
                              <img src={Settings} alt="Settings" style={{ width: '1rem' }} />
                              {t('faceSwapTab.button.settings')}
                            </div>
                            <div className={classes.startContainer}>
                              <div className={classes.tooltipContainer}>
                                {faceSwapRemainingcount < requiredCoins ? (
                                  <div className={classes.redCoin}>
                                    {t('faceSwapTab.stepTwo.settings.leftCoin.0')}
                                    {faceSwapRemainingcount}
                                    {t('faceSwapTab.stepTwo.settings.leftCoin.1')}
                                  </div>
                                ) : (
                                  <div className={classes.coin}>
                                    {t('faceSwapTab.stepTwo.settings.leftCoin.0')}
                                    {faceSwapRemainingcount}
                                    {t('faceSwapTab.stepTwo.settings.leftCoin.1')}
                                  </div>
                                )}
                                <div className={classes.coin}>
                                  {requiredCoins}
                                  {t('faceSwapTab.stepTwo.settings.requiredCoin')}
                                  <HtmlTooltip
                                    title={
                                      <div className={'globalTooltipContainer'}>
                                        <div className={'globalTooltipTextContainer'}>
                                          <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                                          <div className={'globalTooltipText'}>
                                            {t('faceSwapTab.tooltip.coinLogic.0')}
                                          </div>
                                        </div>
                                        <div className={'globalTooltipTextContainer'}>
                                          <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                                          <div className={'globalTooltipText'}>
                                            {t('faceSwapTab.tooltip.coinLogic.1')}
                                          </div>
                                        </div>
                                        <div className={'globalTooltipTextContainer'}>
                                          <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                                          <div className={'globalTooltipText'}>
                                            {t('faceSwapTab.tooltip.coinLogic.2')}
                                          </div>
                                        </div>
                                        <div className={'globalTooltipTextContainer'}>
                                          <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                                          <div className={'globalTooltipText'}>
                                            {t('faceSwapTab.tooltip.coinCal.0')}
                                            <strong>{editedDuration}</strong>
                                            {t('faceSwapTab.tooltip.coinCal.1')}
                                            <strong>{Math.ceil(editedDuration / 15) * 15}</strong>
                                            {t('faceSwapTab.tooltip.coinCal.2')}
                                            <strong>{faceData.filter(face => face.selected).length}</strong>
                                            {t('faceSwapTab.tooltip.coinCal.3')}
                                            {requiredCoins}
                                            {t('faceSwapTab.tooltip.coinCal.4')}
                                          </div>
                                        </div>
                                      </div>
                                    }
                                  >
                                    <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
                                  </HtmlTooltip>
                                </div>
                              </div>
                              <div
                                className={classes.button}
                                style={{ backgroundColor: '#CAFF73' }}
                                onClick={handleOpenVideoLabelModal}
                              >
                                {faceSwapInProgress
                                  ? t('faceSwapTab.button.inProgress')
                                  : t('faceSwapTab.button.start')}
                              </div>
                            </div>
                          </Grid>
                        </Grid>

                        {/* Settings Box */}
                        <Box className={classes.settingsContainer} width="22rem" style={{ color: 'white' }}>
                          {previewLoading && <div className={classes.loadingContainer} />}
                          <FaceSwapSettings
                            t={t}
                            isSharpnessOn={settingsState.isSharpnessOn}
                            blendRatio={settingsState.blendRatio}
                            isWatermarkOn={settingsState.isWatermarkOn}
                            handleWatermarkToggle={handleWatermarkToggle}
                            coinCreditLeft={faceSwapRemainingcount}
                            requiredCoins={requiredCoins}
                            handleStart={handleOpenVideoLabelModal}
                            tierToShowOutputFormat={tierToShowOutputFormat}
                            user={user}
                            navigate={navigate}
                            onApply={handleApply}
                            duration={editedDuration}
                            faceCount={faceData.filter(face => face.selected).length}
                            previewLoading={previewLoading}
                          />
                        </Box>
                      </Box>
                    </>
                  )}
                </>
              ) : (
                <div className={classes.sourceFileContainer}>
                  <div className={classes.tag}>{t('faceSwapTab.stepOne.tags.sourceFile')}</div>
                  {uploadState.videoLoading && (
                    <div className={classes.loadingContainer}>
                      <CircularProgress style={{ width: '2rem', height: '2rem', color: 'white' }} />
                      <div className={classes.loadingAnimText}>
                        <p>{t('faceSwapTab.loading.youtubeLinkUpload.1')}</p>
                        <p>{t('faceSwapTab.loading.youtubeLinkUpload.2')}</p>
                      </div>
                    </div>
                  )}
                  <p className={classes.youtubeLinkText}>{t('faceSwapTab.stepOne.youtubeLinkText')}</p>
                  <OutlinedInput
                    placeholder={t('faceSwapTab.stepOne.youtubeLinkInput')}
                    onChange={e => setContent({ ...content, youtubeLink: e.target.value })}
                    value={content.youtubeLink}
                    className={classes.youtubeLinkInput}
                    onFocus={e => {
                      if (!checkValidLoginStatus(userId, dispatch)) {
                        e.target.blur();
                      }
                    }}
                  />
                  {/* Only for testing --- from here (After testing, comment out here only) */}
                  {/* <Box display="flex" flexDirection="column" width="100%" mb={2} className={classes.distanceContainter}>
                    <Box className={classes.settingsInnerBoxSub}>
                      <div className={classes.settingsText}>{t('distance')}</div>
                      <div className={classes.settingsRatio}>{uploadState.distance}</div>
                    </Box>
                    <Slider
                      value={uploadState.distance}
                      onChange={handleDistanceChange}
                      aria-labelledby="blend-ratio-slider"
                      min={0}
                      max={1}
                      step={0.01}
                      classes={{
                        root: classes.settingsRoot,
                      }}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" width="100%" mb={2} className={classes.distanceContainter}>
                    <Box className={classes.settingsInnerBoxSub}>
                      <div className={classes.settingsText}>{t('Least Frame')}</div>
                      <input
                        className={classes.leastFrameInput}
                        placeholder={t('0')}
                        onChange={e => setUploadState({ ...uploadState, leastFrame: e.target.value })}
                        value={uploadState.leastFrame}
                      />
                    </Box>
                  </Box> */}
                  {/* Only for testing --- to here */}
                  <Button
                    className={classes.searchButton}
                    disabled={!content.youtubeLink || uploadState.videoLoading}
                    onClick={handleYoutubeUpload}
                  >
                    <div className={classes.buttonText}>{t('inferenceTab.stepOne.tabs.youtube.linkbutton')}</div>
                  </Button>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid className={classes.footer}>
          <div
            className={classes.progressIndicator}
            style={uploadState.uploadedVideoUrl ? {} : { backgroundColor: '#9F9F9F' }}
          />
          <div
            className={classes.progressIndicator}
            style={uploadState.uploadedVideoUrl && !rendering ? { backgroundColor: '#9F9F9F' } : {}}
          />
          <div className={classes.progressIndicator} style={rendering ? { backgroundColor: '#9F9F9F' } : {}} />
        </Grid>
        <Modal open={isSettingsModalOpen} onClose={() => setIsSettingsModalOpen(false)} className={classes.modal}>
          <div className={classes.modalContent}>
            {/* Settings Box Modal */}
            <Box className={classes.settingsContainerModal} width="22rem" style={{ color: 'white' }}>
              {previewLoading && (
                <div className={classes.loadingContainer}>
                  <div className={classes.allFacesloadingText}>
                    {t('faceSwapTab.loading.4')
                      .split('')
                      .map((char, index) => (
                        <span key={index} style={{ animationDelay: `${index * 0.1}s` }}>
                          {char}
                        </span>
                      ))}
                  </div>
                </div>
              )}
              <FaceSwapSettings
                t={t}
                isSharpnessOn={settingsState.isSharpnessOn}
                blendRatio={settingsState.blendRatio}
                isWatermarkOn={settingsState.isWatermarkOn}
                handleWatermarkToggle={handleWatermarkToggle}
                coinCreditLeft={faceSwapRemainingcount}
                requiredCoins={requiredCoins}
                handleStart={handleOpenVideoLabelModal}
                tierToShowOutputFormat={tierToShowOutputFormat}
                user={user}
                navigate={navigate}
                onApply={handleApply}
                duration={editedDuration}
                faceCount={faceData.filter(face => face.selected).length}
                previewLoading={previewLoading}
              />
            </Box>
          </div>
        </Modal>
      </div>

      {/* Face Selection Modal */}
      <Dialog
        open={isFaceSelectionModalOpen}
        onClose={() => {
          setIsFaceSelectionModalOpen(false);
          setModalImageIndex(null);
          setIsImageProcessing(false);
          setCroppedFacesInImage([]);
        }}
        className={classes.faceSelectionModal}
      >
        <Grid className={classes.faceSelectionModalTitle}>{t('faceSwapTab.dialog.faceSelectionModal')}</Grid>
        <Grid className={classes.faceSelectionModalContent}>
          {isImageProcessing ? (
            <div className={classes.allFacesloadingText}>
              {t('faceSwapTab.loading.2')
                .split('')
                .map((char, index) => (
                  <span key={index} style={{ animationDelay: `${index * 0.1}s` }}>
                    {char}
                  </span>
                ))}
            </div>
          ) : (
            <>
              {croppedFacesInImage.length === 0 ? (
                <Grid style={{ margin: 'auto' }}>
                  <input
                    accept="image/*"
                    id={`image-upload-modal-${modalImageIndex}`}
                    type="file"
                    onChange={event => handleImageChange(event, modalImageIndex)}
                    className={classes.facesInput}
                    style={{ display: 'none' }}
                  />
                  <label htmlFor={`image-upload-modal-${modalImageIndex}`}>
                    <Button component="span" className={classes.faceSelectionBtn}>
                      <img src={Upload} alt="select image" className={classes.uploadImage} />
                      <span>{t('faceSwapTab.button.selectImage')}</span>
                    </Button>
                  </label>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  {croppedFacesInImage.map((faceImg, index) => (
                    <Grid item xs={2.5} key={index} className={classes.croppedFaceBox}>
                      <img
                        src={faceImg}
                        alt={`Cropped Face ${index + 1}`}
                        className={classes.croppedFaceImage}
                        onClick={() => {
                          setFaceData(prevFaceData =>
                            prevFaceData.map(f =>
                              f.index === modalImageIndex
                                ? {
                                    ...f,
                                    selected: true,
                                    selectedNewFace: faceImg,
                                    newFaceData: croppedFacesData[index],
                                  }
                                : f
                            )
                          );
                          setIsFaceSelectionModalOpen(false);
                          setCroppedFacesInImage([]);
                          setModalImageIndex(null);
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Dialog>
      <Dialog open={showUpgrade} onClose={() => setShowUpgrade(false)} className={classes.upgradeDialog}>
        <div className={classes.dialogTitle}>{t('mixingModal.dialog.title')}</div>
        <div className={classes.dialogText}>{t('faceSwapTab.dialog.upgradeText')}</div>
        <div className={classes.upgradeButton} onClick={() => navigate('/subscription')}>
          {t('mixingModal.upgradeButton.0')}
          <strong>{t('mixingModal.upgradeButton.1')}</strong>
        </div>
      </Dialog>
      <Dialog open={showCoinUpgrade} onClose={() => setShowCoinUpgrade(false)} className={classes.upgradeDialog}>
        <div className={classes.dialogTitle}>{t('faceSwapTab.modal.noRemaining.0')}</div>
        <div className={classes.dialogText}>{t('faceSwapTab.modal.noRemaining.1')}</div>
        <div className={classes.upgradeButton} onClick={() => navigate('/subscription')}>
          {t('mixingModal.upgradeButton.0')}
          <strong>{t('mixingModal.upgradeButton.1')}</strong>
        </div>
      </Dialog>
      <Dialog open={showRenderDelay} onClose={() => setShowRenderDelay(false)} className={classes.upgradeDialog}>
        <div className={classes.dialogTitle}>{t('faceSwapTab.error.pollingTimeout.0')}</div>
        <div className={classes.dialogText}>{t('faceSwapTab.error.pollingTimeout.1')}</div>
        <div className={classes.dialogText}>{t('faceSwapTab.error.pollingTimeout.2')}</div>
        <div className={classes.dialogText}>{t('faceSwapTab.error.pollingTimeout.3')}</div>
        <div className={classes.upgradeButton} onClick={() => navigate('/my-voices')}>
          {t('faceSwapTab.button.goToHistory.1')}
          <strong>{t('faceSwapTab.button.goToHistory.0')}</strong>
        </div>
      </Dialog>
      <Dialog open={showReloadDialog} onClose={handleReloadDialog} className={classes.upgradeDialog}>
        <div className={classes.dialogText}>{t('faceSwapTab.dialog.renderingError.0')}</div>
        <div className={classes.dialogText}>{t('faceSwapTab.dialog.renderingError.1')}</div>
        <div className={classes.upgradeButton} onClick={() => window.location.reload()}>
          {t('faceSwapTab.dialog.reload')}
        </div>
      </Dialog>
    </>
  );
}

export default FaceSwapPage;
