import * as momentTz from 'moment-timezone';
import getBlobDuration from 'get-blob-duration';
import JSZip from 'jszip';
import axios from 'axios';

import { Currency, SubscriptionAction, SubscriptionType } from '../constants/app.constants';
import { appActions } from '../actions/app.actions';

import visaImg from '../img/card-visa.png';
import mastercardImg from '../img/card-mastercard.png';
import unionpayImg from '../img/card-unionpay.png';
import amexImg from '../img/card-amex.png';
import discoverImg from '../img/card-discover.jpg';
import jcbImg from '../img/card-jcb.png';

export const getFilesDuration = async files => {
  let max = 0;
  for (let file of files) {
    max += await getBlobDuration(file);
  }
  return max;
};

export const getAudioDuration = async url => {
  return new Promise((resolve, reject) => {
    const audio = new Audio(url);
    const onLoadedMetadata = () => {
      resolve(audio.duration);
      cleanup();
    };
    const onError = error => {
      reject(error);
      cleanup();
    };
    const cleanup = () => {
      audio.removeEventListener('loadedmetadata', onLoadedMetadata);
      audio.removeEventListener('error', onError);
    };
    audio.addEventListener('loadedmetadata', onLoadedMetadata);
    audio.addEventListener('error', onError);
  });
};

export const zipModelFiles = async (id, files) => {
  const zip = new JSZip();
  const folderZip = zip.folder(id);
  files.forEach(f => folderZip.file(`${id}.${f.name.split('.')[f.name.split('.').length - 1]}`, f));
  return zip.generateAsync({ type: 'blob' });
};

export const zipFiles = async (id, files) => {
  const zip = new JSZip();
  const folderZip = zip.folder(id);
  files.forEach(f => folderZip.file(f.name, f));
  return await zip.generateAsync({ type: 'blob' });
};

export const convertS3ObjectToUrl = async s3Object => {
  const blob = new Blob([new Uint8Array(await s3Object.Body.transformToByteArray())], { type: 'audio/mp3' });
  return URL.createObjectURL(blob);
};

export const getTrainingDuration = (tier, pastDue) => {
  if (tier === 'ADMIN') {
    return { min: 1, max: 90 };
  } else if (tier === 'FREE' || pastDue) {
    return { min: 5, max: 10 };
  } else if (tier === 'PREMIUM') {
    return { min: 5, max: 90 };
  } else if (tier === 'PRO') {
    return { min: 5, max: 45 };
  } else if (tier === 'CUSTOM') {
    return { min: 5, max: 90 };
  } else {
    return { min: 5, max: 30 };
  }
};

export const getTTSTrainingDuration = (tier, pastDue) => {
  if (tier === 'ADMIN') {
    return { min: 15, max: 600 };
  } else if (tier === 'FREE' || pastDue) {
    return { min: 15, max: 600 };
  } else if (tier === 'PREMIUM') {
    return { min: 15, max: 600 };
  } else if (tier === 'PRO') {
    return { min: 15, max: 600 };
  } else if (tier === 'CUSTOM') {
    return { min: 15, max: 600 };
  } else {
    return { min: 15, max: 600 };
  }
};

export const getFormattedLanguage = () => {
  const selectedLanguage = localStorage.getItem('selectedLanguage');
  switch (selectedLanguage) {
    case 'ko':
      return '한국어';
    case 'th':
      return 'แบบไทย';
    case 'ja':
      return '日本語';
    default:
      return 'English';
  }
};

const getCurrencyFromIp = async () => {
  try {
    const res = await axios.get('https://ipapi.co/json/');
    if (res?.status === 200) {
      const { currency } = res.data;
      if (currency == 'KRW') return Currency.KRW;
      else if (currency === 'THB') return Currency.THB;
      else if (currency === 'USD') return Currency.USD;
      else if (currency === 'JPY') return Currency.JPY;
    }
  } catch (e) {}
  return null;
};

const getCurrencyFromMoment = () => {
  try {
    const guess = momentTz.tz.guess();
    const krZones = momentTz.tz.zonesForCountry('KR');
    const thZones = momentTz.tz.zonesForCountry('TH');
    const usZones = momentTz.tz.zonesForCountry('US');
    const jpZones = momentTz.tz.zonesForCountry('JP');
    if (krZones.includes(guess)) return Currency.KRW;
    else if (thZones.includes(guess)) return Currency.THB;
    else if (usZones.includes(guess)) return Currency.USD;
    else if (jpZones.includes(guess)) return Currency.JPY;
  } catch (e) {}
  return null;
};

const getCurrencyFromDefaultLangugage = () => {
  const defaultLanguage = navigator.language.split('-')[0];
  if (defaultLanguage === 'ko') return Currency.KRW;
  else if (defaultLanguage === 'th') return Currency.THB;
  else if (defaultLanguage === 'ja') return Currency.JPY;
  else return Currency.USD;
};

export const getCurrency = async () => {
  // 1. try fecthcing currency using ip
  const currencyFromIp = await getCurrencyFromIp();
  if (currencyFromIp) return currencyFromIp;
  // 2. try fetching currency using moment
  const currencyFromMoment = getCurrencyFromMoment();
  if (currencyFromMoment) return currencyFromMoment;
  // 3. get currency using user system language
  return getCurrencyFromDefaultLangugage();
};

const SubscriptionPlans = {
  [SubscriptionType.FREE]: { weight: 0 },
  [SubscriptionType.BASIC]: { weight: 1 },
  [SubscriptionType.PRO]: { weight: 2 },
  [SubscriptionType.PREMIUM]: { weight: 3 },
};

export const getSubscriptionAction = (currentPlan, newPlan) => {
  // 1. cancel
  if (currentPlan !== SubscriptionType.FREE && newPlan === SubscriptionType.FREE) {
    return SubscriptionAction.CANCEL;
    // 2. initial sign up
  } else if (currentPlan === SubscriptionType.FREE && newPlan !== SubscriptionType.FREE) {
    return SubscriptionAction.INITIATE;
    // 3. downgrade
  } else if (SubscriptionPlans[currentPlan].weight > SubscriptionPlans[newPlan].weight) {
    return SubscriptionAction.DOWNGRADE;
    // 4. upgrade
  } else if (SubscriptionPlans[currentPlan].weight < SubscriptionPlans[newPlan].weight) {
    return SubscriptionAction.UPGRADE;
    // 5. cancel downgrade
  } else if (SubscriptionPlans[currentPlan].weight === SubscriptionPlans[newPlan].weight) {
    return SubscriptionAction.CANCEL_DOWNGRADE;
  }
  return null;
};

export const showMessageV2 = (dispatch, message, config = {}) => {
  const messageConfig = { reloadOnClose: false, showHeaderLogo: false, showProfileButton: false, ...config };
  dispatch(appActions.openMessageModalV2({ ...messageConfig, message }));
};

export const cardTypeToImg = {
  visa: visaImg,
  mastercard: mastercardImg,
  unionpay: unionpayImg,
  jcb: jcbImg,
  discover: discoverImg,
  amex: amexImg,
};

export const getCurrencySymbol = currency => {
  if (currency === Currency.KRW) {
    return '₩';
  } else if (currency === Currency.USD) {
    return '$';
  } else if (currency === Currency.THB) {
    return '฿';
  } else if (currency === Currency.JPY) {
    return '¥';
  }
  return '';
};
