import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStyles } from './LoginModal.styles.js';
import { appActions } from '../../actions/app.actions';

import kakaoLoginButton from '../../img/kakaoLoginButton.png';
import googleLoginButton from '../../img/googleLoginButton.png';
import { backendBaseUrl } from '../../constants/app.constants.js';
import { getCurrency } from '../../utils/page.utils.js';
import BaseModalLayout from './BaseModalLayout.js';

export default function LoginModal(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [currency, setCurrency] = useState();

  useEffect(() => {
    (async () => {
      const userCurrency = await getCurrency();
      setCurrency(userCurrency);
    })();
  }, []);

  return (
    <BaseModalLayout open={!!props.open} onClose={() => dispatch(appActions.closeLoginModal())} showHeaderLogo>
      <img
        src={googleLoginButton}
        className={classes.loginButton}
        alt="google login"
        onClick={() => window.location.replace(`${backendBaseUrl}/auth/google?loc=${currency}`)}
      />
      <img
        src={kakaoLoginButton}
        className={classes.loginButton}
        alt="kakao login"
        onClick={() => window.location.replace(`${backendBaseUrl}/auth/kakao?loc=${currency}`)}
      />
    </BaseModalLayout>
  );
}
