import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  sorisoriAIImg: {
    width: '200px',
    marginBottom: '24px',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '15px',
  },
  tossButton: {
    color: '#fff',
    fontWeight: '200',
    border: '0.5px solid',
    padding: '6px',
    width: '176px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '11px',
    fontSize: '16px',
    cursor: 'pointer',
    marginTop: '1rem',
  },
  stripeButton: {
    color: '#fff',
    fontWeight: '200',
    border: '0.5px solid',
    width: '176px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '11px',
    fontSize: '16px',
    cursor: 'pointer',
  },
  orderInfo: {
    marginBottom: '8px',
    fontSize: '17px',
  },
  promoCodeContainer: {
    display: 'flex',
    marginTop: '20px',
    width: '200px',
  },
  usePromocode: {
    fontSize: '12px',
    marginTop: '16px',
    cursor: 'pointer',
  },
  promoCodeApplied: {
    marginTop: '16px',
    display: 'flex',
    alignItems: 'center',
  },
  promoCodeAppliedText: {
    fontSize: '14px',
  },
  deleteButtonImg: {
    width: '19px!important',
    height: '18px!important',
    marginBottom: '3px',
    marginLeft: '2px',
    cursor: 'pointer',
  },
  appliedPromocode: {
    marginLeft: '3px',
    color: '#D1FE75',
  },
  promoCodeInput: {
    maxWidth: '320px',
    width: '100%',
    height: '35px',
    backgroundColor: '#fff',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 9,
    borderBottomLeftRadius: 9,
  },
}));

export { useStyles };
