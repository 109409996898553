import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '350px',
    height: 'fit-content',
    borderRadius: '24px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#1E1E1E',
    padding: '32px',
    wordBreak: 'keep-all!important',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  modalTitle: {
    fontSize: '24px',
    color: '#fff',
    width: '100%',
    textAlign: 'center',
  },
  separater: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    marginTop: '24px',
  },
  mixSeparater: {
    flex: '1',
    height: '1px',
    backgroundColor: '#A4A4A4',
  },
  pitchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '24px',
  },
  inputContainer: {
    marginBottom: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputNameContainer: {
    marginRight: '16px',
    width: '160px',
    display: 'flex',
    alignItems: 'center',
  },
  inputName: {
    lineHeight: '16px',
    color: '#fff',
    fontSize: '12px',
  },
  tooltipImg: {
    marginRight: '16px',
    width: '20px',
  },
  alertOuterContainer: {
    backgroundColor: '#160B0B',
    borderRadius: '6px',
    padding: '16px',
    marginTop: '16px',
  },
  alertContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#F4C7C7',
    fontSize: '14px',
    fontWeight: '200',
  },
  mixToggleOuterContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '24px 0',
  },
  mixToggleContainer: {
    color: '#fff',
    fontSize: '14px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    margin: '0 16px',
  },
  mixToggle: {
    width: '28px',
    height: '16px',
    marginLeft: '8px',
    padding: '0',
    boxSizing: 'border-box',
    backgroundColor: '#818181',
    borderRadius: '16px',
    border: 'none',
    display: 'inline-block',
    '&[data-state="checked"]': {
      backgroundColor: '#CAFF73',
    },
  },
  mixToggleThumb: {
    boxSizing: 'border-box',
    display: 'block',
    width: '12px',
    height: '12px',
    left: '2px',
    borderRadius: '16px',
    backgroundColor: '#FFF',
    position: 'relative',
    transition: 'all 120ms',
    '&[data-state="checked"]': {
      left: '14px',
      backgroundColor: '#1E1E1E',
    },
  },
  numberFieldRoot: {
    fontSize: '14px',
  },
  numberFieldGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  numberFieldInput: {
    [theme.breakpoints.down('xs')]: {
      width: '48px',
    },
    padding: '4px 8px',
    lineHeight: '1.5',
    border: '2px solid #818181',
    borderRadius: '8px',
    backgroundColor: '#fff',
    color: 'black',
    boxShadow: '0 1px 2px 0 rgba(0 0 0 / 0.05)',
    overflow: 'hidden',
    width: '64px',
    margin: '0 8px',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    textAlign: 'center',
    transition: 'all 150ms',
    '&:focus': {
      outline: '2px solid #CAFF73',
    },
  },
  numberFieldButton: {
    background: '#F0F0F0',
    border: '2px solid #818181',
    borderRadius: '999px',
    width: '24px',
    height: '24px',
    display: 'flex',
    flexDirection: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 150ms',
    '&:hover': {
      cursor: 'pointer',
      borderColor: '#CAFF73',
      background: '#CAFF73',
    },
  },
  disabledButton: {
    color: '#818181 !important',
    '&:hover': {
      cursor: 'pointer',
      borderColor: '#818181',
      background: '#F0F0F0',
    },
  },
  disabledInput: {
    color: '#818181 !important',
  },
  demoOuterContainer: {
    backgroundColor: '#141414',
    borderRadius: '7px',
    padding: '16px 8px 14px 8px',
    margin: '8px 0',
  },
  demoContainer: {
    color: '#fff',
    fontSize: '14px',
    fontWeight: '200',
    textAlign: 'center',
  },
  mixStatus: {
    color: '#AAAAAA',
    paddingTop: '8px',
    fontSize: '10px',
  },
  demoLabel: {
    color: '#AAAAAA',
    fontSize: '12px',
    fontWeight: '300',
    textAlign: 'center',
    paddingTop: '8px',
    width: '240px',
    margin: '0 auto',
  },
  upgradeButton: {
    width: 'fit-content',
    padding: '10px 16px',
    fontSize: '12px',
    backgroundColor: '#CAFF73',
    borderRadius: '3px',
    cursor: 'pointer',
    margin: '0 auto',
    marginTop: '24px',
  },
  upgradeDialog: {
    '& .MuiPaper-root': {
      backgroundColor: '#1E1E1E',
      padding: '19px 24px',
      borderRadius: '24px',
    },
  },
  dialogTitle: {
    color: '#fff',
    textAlign: 'center',
    fontSize: '15px',
    paddingTop: '8px',
  },
  dialogText: {
    color: '#AAAAAA',
    textAlign: 'center',
    fontWeight: 300,
    fontSize: '14pz',
    paddingTop: '9px',
    maxWidth: '288px',
  },
}));

export { useStyles };
