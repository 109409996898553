import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '85%',
    },
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: 'fit-content',
    borderRadius: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#1E1E1E;',
    padding: '1.5rem',
    wordBreak: 'keep-all!important',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 1px rgba(0,0,0,0.1)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
    },
  },
  modalTitle: {
    color: '#fff',
    fontSize: '1.3rem',
    marginBottom: '1.2rem',
  },
  selectContainer: {
    backgroundColor: '#0E0E0E',
    color: '#fff',
    padding: '0.1rem 0.5rem',
    borderRadius: '0.25rem',
    marginRight: '0.5rem',
    '& .MuiSelect-icon': {
      color: '#fff!important',
    },
  },
  selectIcon: {
    color: '#fff!important',
  },
  iconButton: {
    backgroundColor: '#0E0E0E',
    color: '#fff',
    minWidth: '16px',
    padding: '0.3rem',
    transition: '150ms',
    '&:hover': {
      backgroundColor: '#0E0E0E',
    },
  },
  colorPreview: {
    border: '2px solid #818181',
    borderRadius: '0.1rem',
    padding: '11px',
  },
  colorPicker: {
    background: '#1E1E1E!important',
  },
  textFieldContainer: {
    width: '100%',
    display: 'flex',
    marginBottom: '1rem',
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#818181',
      },
      '&:hover fieldset': {
        borderColor: '#818181',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#818181',
      },
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '0.8rem',
  },
  addButton: {
    fontSize: '0.8rem',
    fontWeight: 400,
    fontFamily: 'inherit',
    textTransform: 'none',
    backgroundColor: '#CAFF73',
    transition: 'all 150ms',
    '&:hover': {
      backgroundColor: '#CAFF73',
    },
    '&.Mui-disabled': {
      backgroundColor: '#CAFF7375!important',
    },
  },
  cancelButton: {
    fontSize: '0.8rem',
    fontWeight: 400,
    fontFamily: 'inherit',
    textTransform: 'none',
    marginLeft: '0.5rem',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
}));

export { useStyles };
