import Modal from '@material-ui/core/Modal';
import { useStyles } from './VideoExportModal.styles.js';

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';

import HtmlTooltip from '../HtmlTooltip';
import tooltipImg from '../../img/tooltip.png';

export default function VideoLabelModal(props) {
  const { t } = useTranslation();

  const { open, onVideoExportModalClose, exportName, setExportName, rendering, setRendering } = props;

  const [fontStyle, setFontStyle] = useState('TheJamsil');

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  const handleStart = () => {
    if (exportName && exportName.length > 0) {
      setRendering(true);
      onVideoExportModalClose?.();
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      onVideoExportModalClose?.();
      return;
    }
    if (rendering) {
      onVideoExportModalClose?.();
    }
  };

  const classes = useStyles();
  return (
    <>
      <Modal open={!!open} onClose={handleClose} className={`${classes.modal} ${fontStyle}`}>
        <div className={classes.container}>
          <div className={classes.titleContainer}>
            <div className={classes.pageTitle}>{t('videoExportModal.title')}</div>
            <div className={classes.startButtonContainer}>
              <HtmlTooltip
                title={
                  <div className={'globalTooltipContainer'}>
                    <div className={'globalTooltipTextContainer'}>
                      <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                      <div className={'globalTooltipText'}>{t('videoExportModal.tooltip.contents.0')}</div>
                    </div>
                  </div>
                }
              >
                <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
              </HtmlTooltip>
              <Button
                className={classes.startButton}
                disabled={exportName.length <= 0 || rendering}
                onClick={handleStart}
                disableRipple
              >
                {t('videoExportModal.start')}
              </Button>
            </div>
          </div>
          <div className={classes.separater} />
          <input
            className={classes.labelInput}
            placeholder={t('faceSwapTab.dialog.videoLabelPlaceholder')}
            value={exportName}
            onChange={label => setExportName(label.target.value)}
          />
        </div>
      </Modal>
    </>
  );
}
