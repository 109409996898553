import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { useTranslation } from 'react-i18next';
import { useStyles } from './EditCommunityModal.styles.js';
import CircularProgress from '@mui/material/CircularProgress';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Grid } from '@material-ui/core';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { dragAndDropWhiteStyle } from '../pages/shared.styles';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { labelLength } from '../../constants/app.constants.js';
import { showMessageV2 } from '../../utils/page.utils.js';

export default function EditCommunityModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [label, setLabel] = useState('');
  const [image, setImage] = useState(null);
  const [anonymous, setAnonymous] = useState(false);
  const [fontStyle, setFontStyle] = useState('TheJamsil');
  const { open, loading } = props;

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  useEffect(() => {
    setAnonymous(props.anonymous);
    setLabel(props.label);
  }, [props.label, props.anonymous]);

  const onDropRejected = async input => {
    const error = input[0].errors[0];
    if (error.code === 'file-invalid-type') {
      showMessageV2(dispatch, t('trainingTab.modal.notSupportedFileType'));
    }
  };

  const onImageDropAccepted = async acceptedFiles => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setImage(file);
    }
  };

  const imgDropzone = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/webp': [],
      'image/heic': [],
      'image/jfif': [],
      'image/jpg': [],
    },
    maxFiles: 1,
    onDropAccepted: onImageDropAccepted,
    onDropRejected,
    disabled: loading,
  });

  const onClick = res => {
    if (!res) return props.onEditCommunityModalClose();

    if (label.length < labelLength.voice) {
      props.onEdit(label, anonymous, image);
    } else {
      showMessageV2(dispatch, t('communityUploadTab.modal.longLabel'));
    }
    setImage(null);
  };
  return (
    <Modal className={fontStyle} open={!!open} onClose={() => props.onEditCommunityModalClose()}>
      <div className={classes.container}>
        {!loading && (
          <>
            <div className={classes.header}>{t('historyTab.modal.label')}</div>
            <div className={classes.label}>
              <OutlinedInput onChange={e => setLabel(e.target.value)} value={label} className={classes.labelInput} />
            </div>

            <div className={`${classes.separater} ${classes.noMargin}`} />

            <div className={classes.header}>{t('historyTab.modal.username')}</div>

            <div className={classes.anonymousContainer}>
              <FormControl className={classes.radioButtonContainer}>
                <div className={classes.radioButtonLabel}>{t('communityUploadTab.stepThree.anonymousText')}</div>
                <RadioGroup row>
                  <FormControlLabel
                    checked={anonymous}
                    control={<Radio size="small" onClick={() => setAnonymous(true)} />}
                    label={<Typography style={{ fontSize: '0.8rem' }}>{t('yes')}</Typography>} //{t('yes')}
                  />
                  <FormControlLabel
                    checked={!anonymous}
                    style={{ fontSize: '0.5rem' }}
                    control={<Radio size="small" onClick={() => setAnonymous(false)} />}
                    label={<Typography style={{ fontSize: '0.8rem' }}>{t('no')}</Typography>}
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className={`${classes.separater} ${classes.noMargin}`} />

            <div className={classes.header}>{t('historyTab.modal.image')}</div>

            {!image && (
              <div className={classes.recordButtonContainer}>
                <div {...imgDropzone.getRootProps({ style: dragAndDropWhiteStyle })}>
                  <input {...imgDropzone.getInputProps()} />
                  <div className={classes.dragAndDrop}>
                    <div className={classes.dragAndDropText}>
                      {t('communityUploadTab.stepOne.tabs.fileUpload.dragAndDropText')}
                    </div>
                    <div className={classes.dragAndDropText}>{'jpeg/png/webp/heic/jfif/jpg'}</div>
                    <div className={classes.dragAndDropButton}>
                      {t('inferenceTab.stepOne.tabs.fileUpload.dragAndDropButton')}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {image && (
              <div className={classes.imageContainer}>
                <img src={URL.createObjectURL(image)} className={classes.coverImg} />
                <DeleteIcon onClick={() => setImage(null)} className={classes.deleteButtonImg} />
              </div>
            )}

            <div className={classes.buttonContainer}>
              <div className={classes.yesButton} onClick={() => onClick(true)}>
                {t('historyTab.changeButton')}
              </div>
              <div className={classes.noButton} onClick={() => onClick(false)}>
                {t('historyTab.cancelButton')}
              </div>
            </div>
          </>
        )}
        {loading && <CircularProgress className={classes.loading} />}
      </div>
    </Modal>
  );
}
