import { makeStyles } from '@material-ui/core/styles';
import zIndex from '@material-ui/core/styles/zIndex';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '75%',
    borderRadius: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#1E1E1E;',
    padding: '2rem 2rem',
    wordBreak: 'keep-all!important',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 1px rgba(0,0,0,0.1)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
    },
  },
  smallContainer: {
    height: 'fit-content!important',
  },
  progressContainer: {
    color: '#fff',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.65)',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
  },
  loadingText: {
    maxWidth: '80%',
    textAlign: 'center',
  },
  pageTitle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.75rem',
    },
    fontSize: '2rem',
    color: '#fff',
    alignItems: 'center',
    display: 'flex',
  },
  addSourceButton: {
    backgroundColor: '#CAFF73!important',
    color: '#000!important',
    fontSize: '0.8rem!important',
    fontFamily: 'inherit!important',
    fontWeight: '400!important',
    padding: '0.4rem 0.8rem!important',
  },
  disabledButton: {
    backgroundColor: '#A4A4A4!important',
    color: '#818181!important',
  },
  separater: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    margin: '1.5rem 0',
  },
  inputSourceButtonContainer: {
    [theme.breakpoints.down('553')]: {
      width: '100%',
      fontSize: '13px!important',
    },
    [theme.breakpoints.down('476')]: {
      width: '100%',
      fontSize: '11px!important',
    },
    width: '25rem',
    fontSize: '13px!important',
    marginTop: '.3rem',
    backgroundColor: '#818181',
    padding: '0.2rem',
    alignSelf: 'start',
  },
  inputSourceButton: {
    [theme.breakpoints.down('xs')]: {
      padding: '0.2rem 1rem!important',
    },
    [theme.breakpoints.down('553')]: {
      fontSize: '13px!important',
    },
    [theme.breakpoints.down('476')]: {
      fontSize: '11px!important',
    },
    fontSize: '13px!important',
    flex: 1,
    fontWeight: '400',
    backgroundColor: '#818181!important',
    color: '#000!important',
    padding: '0.2rem 1rem!important',
    border: 'none!important',
  },
  selected: {
    backgroundColor: '#F0F0F0!important',
    borderRadius: '0.2rem!important',
  },
  activeCard: {
    backgroundColor: '#2F3329!important',
    borderColor: '#CAFF73!important',
  },
  card: {
    justifyContent: 'stretch',
    display: 'flex',
    border: '0.1rem solid #fafafa',
    backgroundColor: '#161616',
    padding: '1rem',
    borderRadius: '0.5rem',
    color: '#fff',
    fontSize: '0.9rem',
    fontWeight: '400',
    maxWidth: '50rem',
    marginBottom: '1rem',
    marginRight: '3rem',
    minHeight: '4rem',
    transition: ' background-color 150ms, border-color 150ms',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'none',
      marginRight: '0',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
    },
  },
  cardLabel: {
    flex: 1,
    marginRight: '0.7rem',
    display: 'flex',
    alignItems: 'center',
  },
  cardTimestamp: {
    width: '9.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '4rem',
    },
  },
  cardStatus: {
    textAlign: 'center',
    marginLeft: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: '0.8rem',

    [theme.breakpoints.down('xs')]: {
      maxWidth: '4rem',
      marginLeft: '0rem',
    },
  },
  searchbar: {
    [theme.breakpoints.down('544')]: {
      width: '100%',
    },
    '&::placeholder': {
      color: '#818181',
      fontSize: '0.9rem',
      fontWeight: '400',
      border: 'none!important',
    },
    marginTop: '0.7rem',
    width: '25rem',
    maxWidth: '-webkit-fill-available',
    height: '2.1rem',
    padding: '1rem',
    borderRadius: '0.2rem',
    border: 'none!important',
    color: '#000',
    fontSize: '16px',
    fontWeight: '400',
    outline: 'none',
  },
  voiceAutoComplete: {
    [theme.breakpoints.down('s')]: {
      padding: '0.2rem 0.5rem!important',
      width: '10rem',
      height: '5rem',
    },
    '&::placeholder': {
      color: '#818181',
      fontSize: '0.9rem',
      fontWeight: '400',
      border: 'none!important',
    },
    width: '25rem',
    maxWidth: '-webkit-fill-available',
    borderRadius: '0.2rem',
    border: 'none!important',
    color: '#000',
    fontSize: '16px',
    fontWeight: '400',
    outline: 'none',
    backgroundColor: '#fff',
    marginTop: '0.7rem',
  },
  infoIconAction: {
    width: '2.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '0.3rem',
    [theme.breakpoints.down('xs')]: {
      width: '2.5rem',
    },
  },
  infoIconImg: {
    userSelect: 'none',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fill: 'currentcolor',
    flexShrink: '0',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontSize: '1.5rem',
  },
  infoIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '0.2rem',
    color: '#161616',
  },
  infoIconTextContainer: {
    flexDirection: 'column',
    alignItems: 'stretch',
    color: '#161616',
  },
  infoIconTextLine: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.2rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  infoIconTextBold: {
    fontSize: '0.9rem',
    fontWeight: '500',
  },
  infoIconText: {
    fontSize: '0.9rem',
    fontWeight: '400',
  },
  pagination: {
    fontSize: '1.5rem',
    fontWeight: '500',
    color: '#ffffff',
    margin: '1rem 0',
  },
  infiniteScroll: {
    marginTop: '1rem',
  },
  dragAndDropContainer: {
    marginTop: '0.7rem',
  },
  dragAndDrop: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem 0',
    justifyContent: 'center',
  },
  dragAndDropText: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '300',
    fontSize: '1rem',
    color: '#8A8A8A',
    paddingTop: '0.5rem',
  },
  dragAndDropButton: {
    marginTop: '0.8rem',
    fontWeight: '300',
    fontSize: '1rem',
    color: '#1D1D1D',
    backgroundColor: '#F0F0F0',
    borderRadius: '0.4rem',
    padding: '0.5rem 1.5rem',
  },
  uploadedFileContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '2.5rem 1.5rem',
    marginTop: '0.7rem',
    borderWidth: 2,
    borderRadius: '8px',
    borderColor: '#303030',
    borderStyle: 'dashed',
    backgroundColor: '#141414',
    color: '#bdbdbd',
  },
  uploadedFileName: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  youtubeLinkInputContainer: {
    width: '100%',
    display: 'flex',
    paddingBottom: '1rem',
    marginTop: '0.7rem',
  },
  youtubeLinkInput: {
    maxWidth: '20rem',
    width: '100%',
    height: '2.4rem',
    backgroundColor: '#fff',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 9,
    borderBottomLeftRadius: 9,
  },
}));

export { useStyles };
