import Modal from '@material-ui/core/Modal';
import { useStyles } from './AddSourceModal.styles.js';

import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';

import { dragAndDropStyle } from '../pages/shared.styles';
import { ButtonGroup, Button } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  checkInferenceCompletion,
  fetchInferencesChunk,
  fetchTTSInferencesChunk,
  fetchTrainingsLabel,
  getTTSInferenceBlob,
  uploadSource,
  getInferenceSignedUrl,
  getYoutubeInfo,
  uploadYoutubeVideo,
  uploadSplitYoutubeVideo,
} from '../../services/page.services';

import { getAudioDuration } from '../../utils/page.utils.js';

import HtmlInfoIcon from '../HtmlInfoIcon.js';
import infoIconImg from '../../img/infoIcon.png';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import ImageFileIcon from '@mui/icons-material/Image';
import YoutubeIcon from '@mui/icons-material/YouTube';
import { showMessageV2 } from '../../utils/page.utils.js';

export default function AddSourceModal(props) {
  const { t } = useTranslation();
  const user = useSelector(state => state.user);
  const userId = user?.id;
  const dispatch = useDispatch();
  const { open, loading } = props;

  const [processing, setProcessing] = useState(false);
  const [processingYt, setProcessingYt] = useState(false);

  const [alignment, setAlignment] = useState('music');
  const [fontStyle, setFontStyle] = useState('TheJamsil');

  //pagination
  const chunk = 50;
  const [chunkPage, setChunkPage] = useState(0);
  const [length, setLength] = useState(0);
  const controllerRef = useRef(null);
  //initData
  const [data, setData] = useState([]);

  //filter
  const [query, setQuery] = useState('');
  const [voice, setVoice] = useState([]);
  const [voiceId, setVoiceId] = useState([]);

  //select
  const [activeIndex, setActiveIndex] = useState(null);

  //upload
  const [content, setContent] = useState();

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  useEffect(() => {
    if (open) {
      const debounced = _.debounce(() => initData(false), 300);
      if (userId) {
        debounced();
      }
      return () => {
        debounced.cancel();
      };
    }
  }, [userId, alignment, query, voiceId, open]);

  const resetModalData = () => {
    setData([]);
    setQuery('');
    setVoiceId([]);
    setContent({});
    setActiveIndex(null);
    setProcessing(false);
    setProcessingYt(false);
  };

  const initVoice = async () => {
    try {
      const initialVoice = await fetchTrainingsLabel();
      const copiedVoice = initialVoice.map(item => ({
        label: item.voice.label,
        count: parseInt(item.count),
        trainingId: item.trainingId,
      }));
      setVoice(copiedVoice);
    } catch (err) {
      console.log(err);
      showMessageV2(dispatch, t('historyTab.modal.filterError'));
      setVoice([]);
    }
  };

  const initData = async scroll => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();
    const signal = controllerRef.current.signal;

    try {
      if (alignment === 'music') {
        let inferencesChunk;
        if (scroll) {
          inferencesChunk = await fetchInferencesChunk(chunk, chunkPage, query, signal, voiceId);
        } else {
          inferencesChunk = await fetchInferencesChunk(chunk, 0, query, signal, voiceId);
        }
        let inferences = inferencesChunk.rows;

        const incompleteList = _.filter(inferences, { status: 'PENDING' });
        if (_.size(incompleteList) > 0) {
          await checkInferenceCompletion(userId, incompleteList);
          if (scroll) {
            inferencesChunk = await fetchInferencesChunk(chunk, chunkPage, query, signal, voiceId);
          } else {
            inferencesChunk = await fetchInferencesChunk(chunk, 0, query, signal, voiceId);
          }
          inferences = inferencesChunk.rows;
        }
        const cleanedData = inferences.map(
          ({
            pitch,
            mixerSettings,
            id,
            label,
            status,
            createdAt,
            outputFormat,
            tier,
            sourceLabel,
            voice,
            watermark,
          }) => {
            let decodedMixerSettings;
            try {
              const decodedString = atob(mixerSettings);
              decodedMixerSettings = JSON.parse(decodedString);
            } catch (error) {
              decodedMixerSettings = null;
            }
            return {
              pitch,
              mixerSettings: decodedMixerSettings,
              id,
              label,
              createdAt: moment(createdAt).format('MM/DD/YY hh:mm A'),
              status,
              outputFormat,
              tag: tier === 'FREE' && (watermark || watermark === null) ? `[${t('historyTab.freePrefix')}]` : '',
              sourceLabel,
              trainingLabel: voice ? voice.label : 'AI voice not found',
            };
          }
        );

        if (scroll) {
          setData([...data, ...cleanedData]);
          setChunkPage(prevChunkPage => prevChunkPage + 1);
        } else {
          setData(cleanedData);
          setChunkPage(1);
        }
        setLength(inferencesChunk.count);
      } else if (alignment === 'speech') {
        let inferencesChunk;
        if (scroll) {
          inferencesChunk = await fetchTTSInferencesChunk(chunk, chunkPage, query, signal);
        } else {
          inferencesChunk = await fetchTTSInferencesChunk(chunk, 0, query, signal);
        }
        let inferences = inferencesChunk.rows;
        console.log(inferences);
        const cleanedData = inferences.map(({ id, label, status, createdAt, ttsVoice, stability, similarity }) => {
          return {
            id,
            label,
            createdAt: moment(createdAt).format('MM/DD/YY hh:mm A'),
            status,
            tag: '',
            trainingLabel: ttsVoice ? ttsVoice.label : 'AI voice not found',
            stability,
            similarity,
          };
        });
        if (scroll) {
          setData([...data, ...cleanedData]);
          setChunkPage(prevChunkPage => prevChunkPage + 1);
        } else {
          setData(cleanedData);
          setChunkPage(1);
        }
        setLength(inferencesChunk.count);
      }
    } catch (err) {
      if (err.name != 'CanceledError') {
        showMessageV2(dispatch, t('historyTab.modal.error'), { reloadOnClose: true });
      }
    }
  };

  const onDropAccepted = async acceptedFiles => {
    setProcessing(true);
    if (acceptedFiles.length > 0) {
      try {
        const file = acceptedFiles[0];
        const name = file.name;
        const type = file.type.startsWith('video')
          ? 'video'
          : file.type.startsWith('audio')
            ? 'audio'
            : file.type.startsWith('image')
              ? 'image'
              : '';
        if (type === 'video') {
          const url = await uploadSource({ file, name }, type);
          const duration = await getAudioDuration(url);
          props.onAddSource(url, name, duration, type);
        } else {
          let duration;
          const url = await uploadSource({ file, name }, type);
          if (type === 'image') {
            duration = 60;
          } else {
            duration = await getAudioDuration(url);
          }
          console.log(url);
          props.onAddSource(url, name, duration, type);
        }
      } catch (e) {
        showMessageV2(dispatch, t('inferenceTab.submit.tryAgain'));
        console.log(e);
      } finally {
        resetModalData();
      }
    }
  };

  const onDropRejected = async input => {
    const message = (() => {
      switch (input[0].errors[0].code) {
        case 'file-invalid-type':
          return t('addSourceModal.upload.notSupportedFileType');
        case 'too-many-files':
          return t('addSourceModal.upload.tooManyFiles');
        case 'file-too-large':
          return t('addSourceModal.upload.exceedMaxSize');
      }
    })();
    if (message) showMessageV2(dispatch, message);
  };

  const handleUploadYoutube = async () => {
    setProcessingYt(true);
    try {
      // fix for mobile links
      const youtubeLink = content.youtubeLink.replace('m.youtube.com', 'youtube.com');
      const { duration: durationStr, valid, name } = await getYoutubeInfo(youtubeLink);
      const duration = parseFloat(durationStr);
      if (!valid) {
        setContent({ ...content, youtubeLink: '', name: null });
        showMessageV2(dispatch, t('addSourceModal.youtube.invalidYoutubeLink'));
      } else if (duration > 600) {
        setContent({ ...content, youtubeLink: '', name: null });
        showMessageV2(dispatch, t('addSourceModal.youtube.youtubeLinkTooLong'));
      } else {
        setProcessing(true);
        setContent({
          ...content,
          name,
        });
        const url = await uploadYoutubeVideo(content.youtubeLink);
        props.onAddSource(url, `${name} (Video)`, duration, 'video');
        setAlignment('music');
        resetModalData();
      }
    } catch (e) {
      showMessageV2(dispatch, t('inferenceTab.submit.tryAgain'));
      console.log(e);
    }
    setProcessing(false);
    setProcessingYt(false);
    setContent({});
  };

  const handleAddInference = async index => {
    setProcessing(true);
    if (alignment === 'music') {
      const file = data[index];
      const fileName = `${file.id}.${file.outputFormat}`;
      const url = await getInferenceSignedUrl(file.id, fileName, '');
      const duration = await getAudioDuration(url);
      console.log('duration:', duration);
      props.onAddSource(url, file.label, duration, 'audio');
    } else if (alignment === 'speech') {
      const file = data[index];
      const name = `${file.label}.mp3`;
      const inferenceBlob = await getTTSInferenceBlob(file.id);
      const blobFile = new File([inferenceBlob], name, { type: 'audio/mpeg' });
      const url = await uploadSource({ file: blobFile, name }, 'audio');
      const duration = await getAudioDuration(url);
      props.onAddSource(url, file.label, duration, 'audio');
    }
    setAlignment('music');
    resetModalData();
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'audio/mp3': ['.mp3'],
      'audio/wav': ['.wav'],
      'audio/flac': ['.flac'],
      'audio/ogg': ['.ogg'],
      'video/mpeg-4': ['.mp4'],
      'video/x-matroska': ['.mkv'],
      'image/jpeg': ['.jpeg'],
      'image/png': ['.png'],
      'image/webp': ['.webp'],
    },
    maxFiles: 1,
    onDropAccepted,
    onDropRejected,
    maxSize: 78643200,
    maxFiles: 1,
  });

  const classes = useStyles();
  return (
    <>
      <Modal
        open={!!open}
        onClose={() => {
          if (processing) return;
          resetModalData();
          props.onAddSourceModalClose();
        }}
        className={`${classes.modal} ${fontStyle}`}
      >
        <div
          className={`${classes.container} ${alignment === 'youtube' || alignment === 'upload' ? classes.smallContainer : ''}`}
        >
          {processing && (
            <div className={classes.progressContainer}>
              <CircularProgress
                color="inherit"
                sx={{
                  backdropFilter: 'blur(25px)',
                }}
              />
              <div className={classes.loadingText}>
                {t('addSourceModal.loading.0')}
                {alignment === 'youtube' || alignment === 'upload' ? t('addSourceModal.loading.1') : ''}
              </div>
            </div>
          )}
          <div className={classes.pageTitle}>{t('addSourceModal.title')}</div>
          <div className={classes.separater} />

          <ButtonGroup className={classes.inputSourceButtonContainer}>
            <Button
              className={`${classes.inputSourceButton} ${alignment === 'music' && classes.selected}`}
              onClick={() => {
                if (alignment != 'music') {
                  setAlignment('music');
                  resetModalData();
                }
              }}
              style={{ fontSize: 'inherit' }}
            >
              {t('addSourceModal.sourceOptions.0')}
            </Button>
            <Button
              className={`${classes.inputSourceButton} ${alignment === 'speech' && classes.selected}`}
              onClick={() => {
                if (alignment != 'speech') {
                  setAlignment('speech');
                  resetModalData();
                }
              }}
              style={{ fontSize: 'inherit' }}
            >
              {t('addSourceModal.sourceOptions.1')}
            </Button>
            <Button
              className={`${classes.inputSourceButton} ${alignment === 'youtube' && classes.selected}`}
              onClick={() => {
                if (alignment != 'youtube') {
                  setAlignment('youtube');
                  resetModalData();
                }
              }}
              style={{ fontSize: 'inherit' }}
            >
              {t('addSourceModal.sourceOptions.2')}
            </Button>
            <Button
              className={`${classes.inputSourceButton} ${alignment === 'upload' && classes.selected}`}
              onClick={() => {
                if (alignment != 'upload') {
                  setAlignment('upload');
                  resetModalData();
                }
              }}
              style={{ fontSize: 'inherit' }}
            >
              {t('addSourceModal.sourceOptions.3')}
            </Button>
          </ButtonGroup>

          {alignment !== 'youtube' && alignment !== 'upload' && (
            <input
              className={classes.searchbar}
              placeholder={t('addSourceModal.searchbar')}
              value={query}
              onChange={query => setQuery(query.target.value)}
            />
          )}

          {alignment === 'music' && (
            <>
              <Autocomplete
                multiple
                limitTags={2}
                id="multiple-limit-tags"
                size="small"
                sx={theme => ({
                  borderRadius: '0.2rem',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '& .MuiInputBase-sizeSmall': {
                    padding: '0.2rem 3.5rem 0.2rem 1rem !important',
                    minHeight: '2.5rem',
                  },
                  '& .MuiInputBase-input': {
                    '&::placeholder': {
                      color: '#818181!important',
                      opacity: '1!important',
                    },
                    fontSize: '16px!important',
                    padding: '0!important',
                    color: '#000!important',
                  },
                })}
                className={classes.voiceAutoComplete}
                options={voice}
                onChange={(event, newValues) => {
                  setVoiceId(newValues.map(voice => voice.trainingId));
                }}
                loading={true}
                loadingText={t('addSourceModal.pagination.loader')}
                onOpen={() => initVoice()}
                renderInput={params =>
                  voiceId.length > 0 ? (
                    <TextField {...params} />
                  ) : (
                    <TextField {...params} placeholder={t('addSourceModal.voice')} />
                  )
                }
              />
              <InfiniteScroll
                className={classes.infiniteScroll}
                dataLength={data.length}
                next={() => initData(true)}
                hasMore={data.length < length}
                loader={<div className={classes.pagination}>{t('addSourceModal.pagination.loader')}</div>}
              >
                {data
                  .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
                  .map(({ pitch, mixerSettings, label, createdAt, status, tag, sourceLabel, trainingLabel }, index) => {
                    return (
                      <div
                        className={`${classes.card} ${activeIndex === index && classes.activeCard}`}
                        onClick={() => {
                          handleAddInference(index);
                        }}
                      >
                        <div className={classes.cardLabel}>
                          {label} {tag}
                        </div>
                        <div className={classes.cardTimestamp}>{createdAt}</div>
                        {status === 'FAILED' && <div className={classes.cardStatus}>{t('historyTab.failed')}</div>}
                        {status === 'PENDING' && <div className={classes.cardStatus}>{t('historyTab.inProgress')}</div>}
                        {alignment == 'music' && status === 'COMPLETE' && (
                          <div className={classes.infoIconAction}>
                            <HtmlInfoIcon
                              title={
                                <div className={classes.infoIconContainer}>
                                  <div className={classes.infoIconTextContainer}>
                                    <div className={classes.infoIconTextLine}>
                                      <div className={classes.infoIconTextBold}>{t('historyTab.infoIcon.source')}</div>
                                      <div className={classes.infoIconText}>: {sourceLabel}</div>
                                    </div>
                                    <div className={classes.infoIconTextLine}>
                                      <div className={classes.infoIconTextBold}>{t('historyTab.infoIcon.voice')}</div>
                                      <div className={classes.infoIconText}>: {trainingLabel}</div>
                                    </div>
                                    <div className={classes.infoIconTextLine}>
                                      <div className={classes.infoIconTextBold}>{t('historyTab.infoIcon.pitch')}</div>
                                      <div className={classes.infoIconText}>: {pitch}</div>
                                    </div>
                                    {mixerSettings?.mixerUsed ? (
                                      <div>
                                        <div className={classes.infoIconTextLine}>
                                          <div className={classes.infoIconTextBold}>
                                            {t('historyTab.infoIcon.mixing.title')}:
                                          </div>
                                        </div>
                                        <div className={classes.infoIconTextLine}>
                                          <ul className={classes.infoIconList}>
                                            <li className={classes.infoIconListItem}>
                                              {t('historyTab.infoIcon.mixing.vocalLevel')}: {mixerSettings.vocalLevel}dB
                                            </li>
                                            <li className={classes.infoIconListItem}>
                                              {t('historyTab.infoIcon.mixing.reverbLevel')}: {mixerSettings.reverbLevel}
                                            </li>
                                            <li className={classes.infoIconListItem}>
                                              {t('historyTab.infoIcon.mixing.reverbLength')}:{' '}
                                              {mixerSettings.reverbLength}s
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              }
                            >
                              <img className={classes.infoIconImg} src={infoIconImg} alt="tooltip-img" />
                            </HtmlInfoIcon>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </InfiniteScroll>
            </>
          )}

          {alignment === 'speech' && (
            <InfiniteScroll
              className={classes.infiniteScroll}
              dataLength={data.length}
              next={() => initData(true)}
              hasMore={data.length < length}
              loader={<div className={classes.pagination}>{t('historyTab.pagination.loader')}</div>}
            >
              {data
                .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
                .map(({ label, createdAt, status, tag, trainingLabel, stability, similarity }, index) => {
                  return (
                    <div
                      className={`${classes.card} ${activeIndex === index && classes.activeCard}`}
                      onClick={() => handleAddInference(index)}
                    >
                      <div className={classes.cardLabel}>
                        {label} {tag}
                      </div>
                      <div className={classes.cardTimestamp}>{createdAt}</div>
                      {status === 'FAILED' && <div className={classes.cardStatus}>{t('historyTab.failed')}</div>}
                      {status === 'PENDING' && <div className={classes.cardStatus}>{t('historyTab.inProgress')}</div>}
                      {alignment == 'speech' && (
                        <div className={classes.infoIconAction}>
                          <HtmlInfoIcon
                            title={
                              <div className={classes.infoIconContainer}>
                                <div className={classes.infoIconTextContainer}>
                                  <div className={classes.infoIconTextLine}>
                                    <div className={classes.infoIconTextBold}>{t('ttsHistoryTab.infoIcon.voice')}</div>
                                    <div className={classes.infoIconText}>: {trainingLabel}</div>
                                  </div>
                                  <div className={classes.infoIconTextLine}>
                                    <div className={classes.infoIconTextBold}>
                                      {t('ttsHistoryTab.infoIcon.stability')}
                                    </div>
                                    <div className={classes.infoIconText}>: {stability}%</div>
                                  </div>
                                  <div className={classes.infoIconTextLine}>
                                    <div className={classes.infoIconTextBold}>
                                      {t('ttsHistoryTab.infoIcon.similarity')}
                                    </div>
                                    <div className={classes.infoIconText}>: {similarity}%</div>
                                  </div>
                                </div>
                              </div>
                            }
                          >
                            <img className={classes.infoIconImg} src={infoIconImg} alt="tooltip-img" />
                          </HtmlInfoIcon>
                        </div>
                      )}
                    </div>
                  );
                })}
            </InfiniteScroll>
          )}

          {alignment === 'youtube' && (
            <>
              {content.name ? (
                <div className={classes.uploadedFileContainer}>
                  <div className={classes.uploadedFileName}>
                    <YoutubeIcon sx={{ color: '#FF0000', marginRight: '0.6rem' }} />
                    {content.name}
                  </div>
                </div>
              ) : (
                <div className={classes.youtubeLinkInputContainer}>
                  <OutlinedInput
                    placeholder={t('inferenceTab.stepOne.tabs.youtube.linkPlaceholder')}
                    onChange={e => setContent({ ...content, youtubeLink: e.target.value })}
                    value={content.youtubeLink}
                    className={classes.youtubeLinkInput}
                  />
                  <Button
                    // defining style here because for some reason some styles
                    // doesn't get applied correctly on production
                    style={{
                      backgroundColor: '#242A2D',
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      borderTopRightRadius: 9,
                      borderBottomRightRadius: 9,
                      boxShadow: 'none',
                      width: '7.5rem',
                      cursor: 'pointer',
                      color: '#fff',
                    }}
                    disabled={!content.youtubeLink || loading}
                    onClick={handleUploadYoutube}
                  >
                    {processingYt ? (
                      <CircularProgress style={{ width: '1rem', height: '1rem' }} />
                    ) : (
                      <div className={classes.buttonText}>{t('inferenceTab.stepOne.tabs.youtube.linkbutton')}</div>
                    )}
                  </Button>
                </div>
              )}
            </>
          )}

          {alignment === 'upload' && (
            <>
              <div className={classes.dragAndDropContainer}>
                <div {...getRootProps({ style: dragAndDropStyle })}>
                  <input {...getInputProps()} />
                  <div className={classes.dragAndDrop}>
                    <div className={classes.dragAndDropText}>
                      {t('addSourceModal.upload.dragAndDropText')}
                      {t('addSourceModal.upload.sizeLimitText')}
                    </div>
                    <div className={classes.dragAndDropText}>
                      <AudioFileIcon sx={{ marginRight: '0.3rem' }} />
                      {t('addSourceModal.upload.acceptedAudio')}
                    </div>
                    <div className={classes.dragAndDropText}>
                      <ImageFileIcon sx={{ marginRight: '0.3rem' }} />
                      {t('addSourceModal.upload.acceptedImage')}
                    </div>
                    <div className={classes.dragAndDropText}>
                      <VideoFileIcon sx={{ marginRight: '0.3rem' }} />
                      {t('addSourceModal.upload.acceptedVideo')}
                    </div>
                    <div className={classes.dragAndDropButton}>{t('addSourceModal.upload.button')}</div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}
