import React from 'react';
import { useStyles } from './FreeVocalExtractorMessageModal.styles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BaseModalLayout from './BaseModalLayout';

export default function FreeVocalExtractorMessageModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const userType = user?.subscription?.type;

  const getMessages = () => {
    switch (userType) {
      case 'FREE':
        return t('freeVocalExtractorMessageModal.free.message', { returnObjects: true });
      case 'BASIC':
        return t('freeVocalExtractorMessageModal.basic.message', { returnObjects: true });
      default:
        return [];
    }
  };

  const messages = getMessages();

  return (
    <BaseModalLayout {...props} showHeaderLogo sizeMid>
      <div className={classes.messageContainer}>
        {messages.map(message => (
          <div className={classes.message}>{message}</div>
        ))}
      </div>
      <div className={classes.button} onClick={() => navigate('/subscription')}>
        {t('freeVocalExtractorMessageModal.button')}
      </div>
    </BaseModalLayout>
  );
}
