import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  customMessageContainer: {
    backgroundColor: '#191919',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '350px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '32px 8px',
    wordBreak: 'keep-all!important',
    borderRadius: '24px',
    '&:active': { outline: 'none' },
    '&:focus': { outline: 'none' },
  },
  customMessage: {
    fontSize: '16px',
    fontWeight: '300',
    color: '#c4c4c4',
    textAlign: 'center',
    lineHeight: '22px',
  },
}));

export { useStyles };
