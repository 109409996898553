import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    width: '350px',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 36px',
    wordBreak: 'keep-all!important',
  },
  label: {
    fontSize: '19px',
    color: '#fff',
    width: '100%',
  },
  separater: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    margin: '16px 0',
  },
  accordion: {
    color: '#fff !important',
    backgroundColor: '#191919 !important',
    marginBottom: '11px',
    boxShadow: 'none !important',
    border: 'none',
  },
  accordionIcon: {
    color: '#fff',
  },
  accordionTitle: {
    fontSize: '14px',
    flexDirection: 'row-reverse',
    padding: '0 !important',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
  },
  accordionContent: {
    flexDirection: 'column',
    maxHeight: '192px',
    overflowY: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  fileNames: {
    fontSize: '12px',
    fontWeight: '300',
    display: 'block',
    padding: '10px 0',
    borderTop: '1px solid #5C5C5C',
  },
  info: {
    display: 'flex',
    marginTop: '8px',
  },
  infoTitle: {
    color: '#fff',
    fontSize: '15px',
    fontWeight: '400',
  },
  infoValue: {
    color: '#fff',
    fontSize: '15px',
    fontWeight: '300',
    paddingLeft: '5px',
  },
}));

export { useStyles };
