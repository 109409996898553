import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AppBar, Toolbar, CircularProgress } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import AccountBox from '@mui/icons-material/AccountBox';
import LanguageIcon from '@mui/icons-material/Language';
import ExpandIcon from '@mui/icons-material/ExpandMore';

import Drawer from './ResponsiveDrawer';
import { useStyles } from './AppBarAndDrawer.styles';

import logo from '../../../img/textLogo.png';

import { useSelector, useDispatch } from 'react-redux';
import { appActions } from '../../../actions/app.actions';
import { getFormattedLanguage } from '../../../utils/page.utils';
import { Link } from 'react-router-dom';

const ResponsiveDrawer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useSelector(state => state.user);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const formattedLanguage = getFormattedLanguage();
  const toggeleDrawer = () => setMobileOpen(!mobileOpen);
  const openLanguageModal = () => dispatch(appActions.openLanguageModal());

  return (
    <div>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <MenuIcon onClick={toggeleDrawer} className={classes.menuButton} />
          <Link to={'/'}>
            <img className={classes.logo} src={logo} alt="Logo" />
          </Link>
          <div className={classes.space}></div>
          <div className={classes.languageContainer} onClick={openLanguageModal}>
            <LanguageIcon />
            <div className={classes.selectedLanguage}>{formattedLanguage}</div>
            <ExpandIcon />
          </div>
          {user.loading && <CircularProgress />}
          {!user.loading &&
            (user.loggedIn ? (
              <div>
                <div className={classes.userNameContainer} onClick={() => navigate('/profile')}>
                  <AccountBox className={classes.userImg} />
                  <div className={classes.userName}>{user.name.toUpperCase()}</div>
                  <div style={{ display: 'none' }} id="user-id">
                    {user.id}
                  </div>
                </div>
              </div>
            ) : (
              <div className={classes.userNameContainer} onClick={() => dispatch(appActions.openLoginModal())}>
                <AccountBox className={classes.userImg} />
                <span className={classes.headerLoginText}>{t('signIn')}</span>
              </div>
            ))}
        </Toolbar>
      </AppBar>
      <Drawer open={mobileOpen} handleDrawerToggle={toggeleDrawer} />
    </div>
  );
};

export default ResponsiveDrawer;
