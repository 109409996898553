import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  messageOneContainer: {
    marginBottom: '16px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  messageOne: {
    fontSize: '16px',
    fontWeight: '300',
    color: '#c4c4c4',
    marginBottom: '5px',
    width: '250px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  messageTwoContainer: {
    marginBottom: '24px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  messageTwo: {
    fontSize: '14px',
    fontWeight: '300',
    color: '#fff',
    marginBottom: '5px',
    textDecoration: 'underline',
  },
  button: {
    backgroundColor: '#CAFF73',
    padding: '12px 24px',
    fontSize: '16px',
    borderRadius: '32px',
    cursor: 'pointer',
    color: '#191919',
  },
}));

export { useStyles };
