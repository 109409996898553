import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  loginButton: {
    width: '192px',
    height: '41.6px',
    cursor: 'pointer',
    margin: '8px 0',
  },
}));

export { useStyles };
