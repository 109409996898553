import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  tossButton: {
    color: '#fff',
    fontWeight: '200',
    border: '0.5px solid',
    padding: '5.6px',
    width: '176px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '11px',
    fontSize: '16px',
    cursor: 'pointer',
    marginTop: '16px',
  },
  stripeButton: {
    color: '#fff',
    fontWeight: '200',
    border: '0.5px solid',
    width: '176px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '11px',
    fontSize: '16px',
    cursor: 'pointer',
  },
}));

export { useStyles };
