import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useStyles } from './GenerateMusicPage.styles';

import { ReactMic } from 'react-mic';
import _ from 'lodash';
import AudioPlayer from 'material-ui-audio-player';
import ReactJkMusicPlayer from 'react-jinke-music-player';
import 'react-jinke-music-player/assets/index.css';
import ReactTimeAgo from 'react-time-ago';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import ClearIcon from '@mui/icons-material/Clear';

import addIcon from '../../img/addIcon.png';

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import FavoriteIcon from '@mui/icons-material/Favorite';
import TuneIcon from '@mui/icons-material/Tune';
import Checkbox from '@mui/material/Checkbox';

import {
  checkTrainingCompletion,
  fetchSubscriptionInfo,
  checkInferenceCompletion,
  checkInferenceCompletionById,
  getInferenceSignedUrl,
  uploadInference,
  getYoutubeInfo,
  fetchTrainings,
  getCommunitySampleSignedUrl,
} from '../../services/page.services';

import { dragAndDropStyleNew } from './shared.styles';
import { checkValidLoginStatus } from '../../utils/user.utils';
import { appActions } from '../../actions/app.actions.js';
import { userActions } from '../../actions/user.actions.js';
import { getDefaultModel } from '../../constants/model.constants';
import SelectVoiceModal from '../layout/SelectVoiceModal';
import MixingModal from '../layout/MixingModal.js';

import HtmlTooltip from '../HtmlTooltip';
import coverImg from '../../img/cover.png';
import tooltipImg from '../../img/tooltip.png';
import FreeInferenceMessageModal from '../layout/FreeInferenceMessageModal.js';
import { labelLength, statusCheckInterval } from '../../constants/app.constants.js';
import { showMessageV2 } from '../../utils/page.utils.js';

function GenerateMusicPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const app = useSelector(state => state.app);
  const userId = user?.id;
  const tierToShowOutputFormat = ['PREMIUM', 'PRO', 'ADMIN', 'CUSTOM'];
  const isAdmin = user?.subscription?.type === 'ADMIN';
  const isCustom = user?.subscription?.type === 'CUSTOM';

  const intervalIDRef = React.useRef(null);
  const [loadingText, setLoadingText] = useState('');
  const bottomRef = useRef(null); // ref to reference the bottom position
  const [submitSubText, setSubmitSubText] = useState('');
  const [alignment, setAlignment] = React.useState('youtube');
  const [outputFormat, setOutputFormat] = React.useState('mp3');

  const [selectedVoiceType, setSelectedVoiceType] = useState(app.selectedModel ? 'community' : '');

  const [selectedModel, setSelectedModel] = useState(app.selectedModel);

  const [pitch, setPitch] = useState(0);

  const [mixerSettings, setMixerSettings] = useState({
    vocalLevel: 0,
    reverbLevel: 3,
    reverbLength: 2.6,
    mixerUsed: false,
  });

  const [needMdx, setNeedMdx] = useState(true);
  const [inferenceRemainingCount, setInferenceRemainingcount] = useState();
  const [inferenceInProgress, setInferenceInProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState({});
  const [recordData, setRecordData] = useState();
  const [selectVoiceModalProps, setSelectVoiceModalProps] = useState({});
  const [mixingModalProps, setMixingModalProps] = useState({});
  const [completedAudio, setCompletedAudio] = React.useState();
  const [showOutputFormatTooltip, setShowOutputFormatTooltip] = useState(false);
  const [openFreeInferenceModal, setOpenFreeInferenceModal] = useState(false);
  const [mic, setMic] = useState(false);

  const [label, setLabel] = useState('');

  //play
  const [selectedAudio, setSelectedAudio] = React.useState();
  const [playing, setPlaying] = useState(false);
  const [selectedCommunityId, setSelectedCommunityId] = useState('');

  useEffect(() => () => intervalIDRef.current && clearInterval(intervalIDRef.current), []);

  useEffect(() => {
    return () => {
      dispatch(appActions.clearSelectedCommunityItem());
    };
  }, []);

  useEffect(() => {
    if (userId) (async () => initPage())();
  }, [userId]);

  //add event listened to be triggered whenever the played song ends
  useEffect(() => {
    if (selectedAudio) {
      selectedAudio.addEventListener('ended', () => {
        setSelectedCommunityId('');
        setPlaying(false);
      });
    }
    return () => {
      if (selectedAudio) {
        selectedAudio.pause();
        selectedAudio.removeEventListener('ended', () => {
          setSelectedCommunityId('');
          setPlaying(false);
        });
      }
    };
  }, [selectedAudio]);

  //when people click play button
  useEffect(() => {
    if (selectedAudio) {
      playing ? selectedAudio.play() : selectedAudio.pause();
    }
  }, [playing]);

  useEffect(() => {
    if (content?.name && selectedModel) {
      const modelLabel = selectedModel.label.substring(0, labelLength.voice).trim();
      const contentLabel = content.name.substring(0, 50).trim();
      setLabel(`${modelLabel}-[Key: ${pitch > 0 ? '+' + pitch : pitch}]-${contentLabel}`);
    } else {
      setLabel('');
    }
  }, [content, selectedModel, pitch]);

  // when loadingtext exists, scroll to the very bottom
  useEffect(() => {
    if (loadingText !== '') {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [loadingText]);

  const defaultModel = getDefaultModel(t);

  const checkInferenceInProgress = async () => {
    let inferences = await checkInferenceCompletion();
    const inProgress = _.some(inferences, { status: 'PENDING' });
    setInferenceInProgress(inProgress);
  };

  const getBlobFromS3Url = async url => {
    const res = await fetch(url);
    return res.blob();
  };

  const initPage = async () => {
    try {
      if (!isAdmin && !isCustom) {
        await checkInferenceInProgress();
      }
      await checkRemainingCount();
      await checkTrainingCompletion();
      const trainings = await fetchTrainings();
      const communityTrainings = await fetchTrainings();
      const availableTraining = _.sortBy(
        _.map(_.filter(trainings, { status: 'COMPLETE' }), v => ({
          ...v,
          category: 'my voices',
        })),
        ['label']
      );
      const availableCommunityTraining = _.sortBy(
        _.map(_.filter(communityTrainings, { status: 'COMPLETE' }), v => ({
          ...v,
          category: 'community',
        })),
        ['label']
      );
      availableTraining.concat(availableCommunityTraining);
    } catch (e) {
      showMessageV2(dispatch, t('modal.pageLoadFail'), { reloadOnClose: true });
    }
  };

  const checkRemainingCount = async () => {
    let subscriptionInfo = await fetchSubscriptionInfo();
    dispatch(userActions.updateSubscription(subscriptionInfo));
    setInferenceRemainingcount(subscriptionInfo?.inferenceRemaining || 0);
    return subscriptionInfo;
  };

  const onDropAccepted = async acceptedFiles => {
    if (checkValidLoginStatus(userId, dispatch) && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const fileName = file.name.replace(/\.[^/.]+$/, '');
      setContent({ ...content, file, name: fileName });
    }
  };
  const onDropRejected = async input => {
    if (checkValidLoginStatus(userId, dispatch)) {
      const message = (() => {
        switch (input[0].errors[0].code) {
          case 'file-invalid-type':
            return t('inferenceTab.modal.notSupportedFileType');
          case 'too-many-files':
            return t('inferenceTab.modal.tooManyFiles');
          case 'file-too-large':
            return t('inferenceTab.modal.exceedMaxSize');
        }
      })();
      if (message) showMessageV2(dispatch, message);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'audio/mp3': ['.mp3'],
      'audio/wav': ['.wav'],
      'audio/mpeg-4': ['.m4a'],
      'audio/flac': ['.flac'],
      'audio/ogg': ['.ogg'],
    },
    maxFiles: 1,
    onDropAccepted,
    onDropRejected,
    disabled: loadingText,
    maxSize: 78643200,
  });

  const checkCompleteForm = () => {
    let message = '';
    if (inferenceInProgress) {
      message = t('inferenceTab.modal.inProgress');
    } else if (inferenceRemainingCount <= 0) {
      message = t('inferenceTab.modal.noRemaining');
    } else if (!content.file && !content.youtubeInfo) {
      message = t('inferenceTab.modal.emptyYoutubeLink');
    } else if (!selectedModel?.id) {
      message = t('inferenceTab.modal.noModalSelected');
    } else if (label.trim().length === 0) {
      message = t('inferenceTab.modal.noLabel');
    } else if (label.trim().length > labelLength.music) {
      message = t('inferenceTab.modal.longLabel');
    }
    if (message) {
      showMessageV2(dispatch, message);
      return false;
    }
    return true;
  };

  const checkYoutubeLink = async () => {
    setLoading(true);
    try {
      const { duration, valid, name } = await getYoutubeInfo(content.youtubeLink);
      if (!valid) {
        setContent({ ...content, youtubeLink: '', name: null });
        showMessageV2(dispatch, t('inferenceTab.modal.invalidYoutubeLink'));
      } else if (duration > 600) {
        setContent({ ...content, youtubeLink: '', name: null });
        showMessageV2(dispatch, t('inferenceTab.modal.youtubeLinkTooLong'));
      } else {
        setContent({
          ...content,
          youtubeInfo: { duration, name },
          name,
        });
      }
    } catch (e) {
      showMessageV2(dispatch, t('inferenceTab.submit.tryAgain'));
    } finally {
      setLoading(false);
    }
  };

  const setUploadType = uploadType => {
    setAlignment(uploadType);
    setNeedMdx(uploadType != 'record');
    setContent({
      ...content,
      youtubeLink: '',
      file: null,
      youtubeInfo: null,
      name: null,
    });
  };

  const handleSubmit = async e => {
    if (!checkCompleteForm()) return;
    setCompletedAudio(null);
    setLoadingText(t('inferenceTab.submit.uploading'));
    let inference;
    const community = selectedVoiceType === 'community';
    try {
      const mixerSettingsString = JSON.stringify(mixerSettings);
      const encodedMixerSettings = btoa(mixerSettingsString);
      inference = await uploadInference(
        userId,
        content,
        pitch,
        encodedMixerSettings,
        needMdx,
        selectedModel,
        user.subscription.type,
        outputFormat,
        community,
        label
      );
    } catch (e) {
      showMessageV2(dispatch, t('inferenceTab.submit.tryAgain'));
      setLoadingText('');
      return;
    }
    setLoadingText(t('inferenceTab.submit.inProgress'));
    if (
      user.subscription.type === 'FREE' &&
      user.subscription.baseInferenceRemaining === user.subscription.inferenceRemaining
    ) {
      setOpenFreeInferenceModal(true);
    }

    clearInterval(intervalIDRef.current);
    const tick = async () => {
      const id = inference.id;
      const combinedFileName = `${id}.${inference.outputFormat}`;
      const voiceFileName = `${id}-voice.${inference.outputFormat}`;
      const instrumentalFileName = `${id}-instrument.${inference.outputFormat}`;
      try {
        const { status, pipelineStatus } = await checkInferenceCompletionById(id);
        setLoadingText(
          pipelineStatus ? t(`pipeline.inferenceStatusMessage.${pipelineStatus}`) : t('inferenceTab.submit.inProgress')
        );
        if (status === 'COMPLETE') {
          clearInterval(intervalIDRef.current);
          const voiceUrl = await getInferenceSignedUrl(id, voiceFileName);
          let combinedUrl;
          let instrumentalUrl;
          if (needMdx) {
            combinedUrl = await getInferenceSignedUrl(id, combinedFileName);
            instrumentalUrl = await getInferenceSignedUrl(id, instrumentalFileName);
          }
          setLoadingText('');
          setSubmitSubText(t('inferenceTab.submit.completedAndLoadingPlayer'));
          const voiceBlob = await getBlobFromS3Url(voiceUrl);
          const combinedBlob = combinedUrl && (await getBlobFromS3Url(combinedUrl));
          const instrumentalBlob = instrumentalUrl && (await getBlobFromS3Url(instrumentalUrl));

          await checkRemainingCount();
          setCompletedAudio(
            _.compact([
              combinedBlob && {
                name: label,
                musicSrc: URL.createObjectURL(
                  combinedBlob.slice(0, combinedBlob.size, `audio/${inference.outputFormat}`)
                ),
                extension: inference.outputFormat,
              },
              {
                name: `${label}-voice`,
                musicSrc: URL.createObjectURL(voiceBlob.slice(0, voiceBlob.size, `audio/${inference.outputFormat}`)),
                extension: inference.outputFormat,
              },
              instrumentalBlob && {
                name: `${label}-instrument`,
                musicSrc: URL.createObjectURL(
                  instrumentalBlob.slice(0, instrumentalBlob.size, `audio/${inference.outputFormat}`)
                ),
                extension: inference.outputFormat,
              },
            ])
          );
          setSubmitSubText(t(''));
        } else if (status === 'FAILED') {
          showMessageV2(
            dispatch,
            pipelineStatus
              ? t(`pipeline.inferenceStatusMessage.${pipelineStatus}`)
              : t('pipeline.inferenceStatusMessage.generalMessage')
          );
          clearInterval(intervalIDRef.current);
          setLoadingText('');
        }
      } catch (e) {}
    };
    intervalIDRef.current = setInterval(tick, statusCheckInterval);
  };

  const onStop = audioData => {
    if (audioData.blob.size < 100) {
      return;
    }
    setRecordData(audioData);
    setContent({ ...content, file: audioData.blob, name: t('inferenceTab.stepOne.tabs.recording.userRecording') });
  };

  const customDownloader = downloadInfo => {
    const downloadingAudio = _.find(completedAudio, {
      musicSrc: downloadInfo.src,
    });
    const link = document.createElement('a');
    link.href = downloadInfo.src;
    link.download = `${downloadingAudio.name}.${downloadingAudio.extension}`;
    document.body.appendChild(link);
    link.click();
  };

  const onBeforeDestroy = () => {
    return new Promise((_resolve, reject) => {
      setCompletedAudio(null);
      reject();
    });
  };

  const handleWavButtonClick = () => {
    if (checkValidLoginStatus(userId, dispatch)) {
      if (!tierToShowOutputFormat.includes(user.subscription?.type)) {
        setShowOutputFormatTooltip(true);
      } else {
        setOutputFormat('wav');
        setShowOutputFormatTooltip(false);
      }
    }
  };

  const handleSelectVoice = async () => {
    if (checkValidLoginStatus(userId, dispatch)) {
      setSelectVoiceModalProps({
        open: true,
        onSelectVoice: async (selectedVoice, selectedVoiceType) => {
          setSelectedModel(selectedVoice);
          setSelectedVoiceType(selectedVoiceType);
          setSelectVoiceModalProps({ open: false });
        },
        onSelectVoiceModalClose: () => {
          setSelectVoiceModalProps({ open: false });
        },
      });
    }
  };

  const handleMixing = async () => {
    setMixingModalProps({
      open: true,
      onMixingModalClose: async (pitch, vocalLevel, reverbLevel, reverbLength, isToggled) => {
        setPitch(pitch);
        if (tierToShowOutputFormat.includes(user.subscription?.type)) {
          setMixerSettings(() => ({
            vocalLevel: vocalLevel,
            reverbLevel: reverbLevel,
            reverbLength: reverbLength,
            mixerUsed: isToggled,
          }));
        }
        setMixingModalProps({ open: false });
      },
    });
  };

  const handlePlay = async communityItemId => {
    if (selectedCommunityId === communityItemId) {
      setPlaying(true);
    } else {
      setPlaying(false);
      const sampleVoiceUrl = await getCommunitySampleSignedUrl(communityItemId);
      const sampleVoiceBlob = await getBlobFromS3Url(sampleVoiceUrl);
      if (sampleVoiceBlob.size > 2000) {
        const audio = new Audio(URL.createObjectURL(sampleVoiceBlob.slice(0, sampleVoiceBlob.size, `audio/mp3`)));

        setSelectedAudio(audio);
        setPlaying(true);
        setSelectedCommunityId(communityItemId);
      }
    }
  };

  const handlePlayDebounce = _.debounce(handlePlay, 500, { leading: true });

  const classes = useStyles();
  return (
    <>
      <FreeInferenceMessageModal open={openFreeInferenceModal} onClose={() => setOpenFreeInferenceModal(false)} />
      <SelectVoiceModal {...selectVoiceModalProps} />
      <MixingModal {...mixingModalProps} />
      <div className={classes.container}>
        <div className={classes.pageTitle}>{t('inferenceTab.title')}</div>
        <div className={classes.section}>
          <div className={`${classes.stepHeader}`}>
            <span className={classes.stepHeaderNum}>01</span>
            <span>{t('inferenceTab.stepTwo.title')}</span>
          </div>
          {selectedModel && (
            <div className={classes.card} key={selectedModel.id}>
              <img
                className={classes.coverImg}
                src={selectedModel.image ? selectedModel.image : coverImg}
                alt="cover-img"
              />
              <div className={classes.cardSecondColumn}>
                <div className={classes.cardLabel}>{selectedModel.label}</div>
                <div className={classes.cardUsername}>{selectedModel.username || ''}</div>

                <div className={classes.cardDetails}>
                  {!selectedModel.liked && <FavoriteBorderRoundedIcon className={classes.unLikedImg} />}
                  {selectedModel.liked && <FavoriteIcon className={classes.likedImg} />}
                  <div className={classes.numberLikes}>
                    {selectedModel.likedNumber ? selectedModel.likedNumber : ''}
                  </div>
                  <div className={classes.cardDetailSeparator}>•</div>
                  <ReactTimeAgo date={selectedModel.createdAt} locale="en-US" />
                </div>
              </div>

              <div className={classes.playButtonGrid}>
                <ClearIcon
                  onClick={() => {
                    setSelectedCommunityId('');
                    setPlaying(false);
                    setSelectedModel(null);
                  }}
                  className={classes.clearButtonImgXS}
                />
                {playing && selectedModel.id === selectedCommunityId ? (
                  <PauseCircleOutlineIcon
                    className={classes.playButtonImg}
                    onClick={event => {
                      event.stopPropagation();
                      setPlaying(false);
                    }}
                  />
                ) : (
                  <PlayCircleOutlineIcon
                    className={classes.playButtonImg}
                    onClick={event => {
                      event.stopPropagation();
                      handlePlayDebounce(selectedModel.id);
                    }}
                  />
                )}
              </div>
              <ClearIcon
                onClick={() => {
                  setSelectedCommunityId('');
                  setPlaying(false);
                  setSelectedModel(null);
                }}
                className={classes.clearButtonImg}
              />
            </div>
          )}
          {!selectedModel && (
            <div className={classes.selectModelOuterContainer} onClick={() => handleSelectVoice()}>
              <div className={classes.selectModelContainer}>
                <img src={addIcon} className={classes.addButtonImg} />
                <div className={classes.dragAndDropText}>{t('inferenceTab.stepTwo.dragAndDropText')}</div>
              </div>
            </div>
          )}
        </div>

        <div className={classes.section}>
          <div className={`${classes.stepHeader}`}>
            <span className={classes.stepHeaderNum}>02</span>
            <span>{t('inferenceTab.stepOne.title')}</span>
            <HtmlTooltip
              title={
                <div className={'globalTooltipContainer'}>
                  <div className={'globalTooltipHeader'}>{t('inferenceTab.stepOne.tooltip.title')}</div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className="globalTooltipText globalTooltipTextBullet">1.</div>
                    <div className={'globalTooltipText'}>{t('inferenceTab.stepOne.tooltip.contents.0')}</div>
                  </div>

                  <div className={'globalTooltipTextContainer'}>
                    <div className="globalTooltipText globalTooltipTextBullet">2.</div>
                    <div className={'globalTooltipText'}>{t('inferenceTab.stepOne.tooltip.contents.1')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className="globalTooltipText globalTooltipTextBullet">3.</div>
                    <div className={'globalTooltipText'}>{t('inferenceTab.stepOne.tooltip.contents.2')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className="globalTooltipText globalTooltipTextBullet">4.</div>
                    <div className={'globalTooltipText'}>{t('inferenceTab.stepOne.tooltip.contents.1')}</div>
                  </div>
                </div>
              }
            >
              <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
            </HtmlTooltip>
          </div>
          <div className={classes.uploadContainer}>
            <div className={classes.inputSourceButtonContainer}>
              <div
                className={`${classes.inputSourceButton} ${alignment == 'youtube' ? classes.selected : ''}`}
                onClick={() => setUploadType('youtube')}
              >
                {t('inferenceTab.stepOne.tabs.youtube.title')}
              </div>
              <div
                className={`${classes.inputSourceButton} ${alignment == 'upload' ? classes.selected : ''}`}
                onClick={() => setUploadType('upload')}
              >
                {t('inferenceTab.stepOne.tabs.fileUpload.title')}
              </div>
              <div
                className={`${classes.inputSourceButton} ${alignment == 'record' ? classes.selected : ''}`}
                onClick={() => setUploadType('record')}
              >
                {t('inferenceTab.stepOne.tabs.recording.title')}
              </div>
            </div>
            {alignment === 'youtube' && (
              <div className={classes.recordButtonContainer}>
                {content.youtubeInfo ? (
                  <div className={classes.youtubeInfoContainer}>
                    <div className={classes.youtubeInfo}>
                      {content.youtubeInfo.name} ({Math.floor(content.youtubeInfo.duration / 60)}
                      {t('inferenceTab.stepOne.tabs.youtube.durationUnit.0')}
                      {content.youtubeInfo.duration % 60}
                      {t('inferenceTab.stepOne.tabs.youtube.durationUnit.1')})
                      <DeleteIcon
                        className={classes.youtubeInfoDeleteIcon}
                        onClick={() =>
                          setContent({
                            ...content,
                            youtubeInfo: null,
                            youtubeLink: '',
                            name: null,
                          })
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <OutlinedInput
                      placeholder={t('inferenceTab.stepOne.tabs.youtube.linkPlaceholder')}
                      onChange={e => setContent({ ...content, youtubeLink: e.target.value })}
                      value={content.youtubeLink}
                      className={classes.youtubeLinkInput}
                      endAdornment={
                        <Button
                          // defining style here because for some reason some styles
                          // doesn't get applied correctly on production
                          style={{
                            backgroundColor: '#B9FF66',
                            border: '1px solid #B9FF66',
                            borderRadius: '8px',
                            boxShadow: 'none',
                            padding: 0,
                            height: '90%',
                            cursor: 'pointer',
                            color: '#292929',
                            lineHeight: '1',
                            fontFamily: 'TheJamsil',
                          }}
                          className={classes.youtubeLinkButton}
                          disabled={!content.youtubeLink || loading}
                          onClick={checkYoutubeLink}
                        >
                          {loading ? (
                            <CircularProgress
                              // defining style here because for some reason some styles
                              // doesn't get applied correctly on production
                              style={{ width: '1rem', height: '1rem' }}
                            />
                          ) : (
                            <div className={classes.buttonText}>
                              {t('inferenceTab.stepOne.tabs.youtube.linkbutton')}
                            </div>
                          )}
                        </Button>
                        // </InputAdornment>
                      }
                      onFocus={e => {
                        if (!checkValidLoginStatus(userId, dispatch)) {
                          e.target.blur();
                        }
                      }}
                    />
                  </>
                )}
              </div>
            )}
            {alignment === 'upload' && (
              <>
                {!content.file && (
                  <div {...getRootProps({ style: dragAndDropStyleNew })}>
                    <input {...getInputProps()} />
                    <div className={classes.dragAndDropText}>
                      {t('inferenceTab.stepOne.tabs.fileUpload.dragAndDropText')}
                      {t('inferenceTab.stepOne.tabs.fileUpload.dragAndDropDuration')}
                    </div>
                    <div className={classes.dragAndDropButton}>
                      {t('inferenceTab.stepOne.tabs.fileUpload.dragAndDropButton')}
                    </div>
                  </div>
                )}

                {content.file && (
                  <AudioPlayer
                    icons={{ CloseIcon: DeleteIcon }}
                    useStyles={useStyles}
                    onClose={() => setContent({ ...content, file: null, name: null })}
                    displayCloseButton
                    volume={false}
                    rounded
                    src={URL.createObjectURL(content.file)}
                  />
                )}
                <div className={classes.mdxContainer} item xs={12} sm={12} md={12}>
                  <div className={classes.radioButtonLabel}>
                    {t('inferenceTab.stepOne.tabs.fileUpload.vocalExtractionText')}
                    <HtmlTooltip
                      title={
                        <div className={'globalTooltipContainer'}>
                          <div className={'globalTooltipHeader'}>
                            {t('inferenceTab.stepOne.tabs.fileUpload.vocalExtractionTooltip.title')}
                          </div>
                          <div className={'globalTooltipTextContainer'}>
                            <div className={`globalTooltipText globalTooltipTextBullet`}>1.</div>
                            <div className={'globalTooltipText'}>
                              {t('inferenceTab.stepOne.tabs.fileUpload.vocalExtractionTooltip.contents.0')}
                            </div>
                          </div>
                          <div className={'globalTooltipTextContainer'}>
                            <div className={`globalTooltipText globalTooltipTextBullet`}>2.</div>
                            <div className={'globalTooltipText'}>
                              {t('inferenceTab.stepOne.tabs.fileUpload.vocalExtractionTooltip.contents.1')}
                            </div>
                          </div>
                        </div>
                      }
                    >
                      <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
                    </HtmlTooltip>
                  </div>
                  <Checkbox className={classes.checkbox} checked={!needMdx} onChange={() => setNeedMdx(!needMdx)} />
                </div>
              </>
            )}
            {alignment === 'record' && (
              <>
                <div className={classes.recordContainer}>
                  {!recordData && (
                    <div>
                      <ReactMic
                        record={mic}
                        className={classes.mic}
                        onStop={onStop}
                        strokeColor="#fff"
                        backgroundColor="#1e1e1e"
                        mimeType="audio/mp3"
                        visualSetting="sinewave"
                      />
                      <div className={classes.recordButtonContainer}>
                        {mic ? (
                          <div className={classes.onRecordingButton}>
                            {' '}
                            {t('inferenceTab.stepOne.tabs.recording.recordingButton')}
                          </div>
                        ) : (
                          <button className={classes.recordingButton} onClick={() => setMic(true)} type="button">
                            {t('inferenceTab.stepOne.tabs.recording.startButton')}
                          </button>
                        )}
                        <button className={classes.recordingButton} onClick={() => setMic(false)} type="button">
                          {t('inferenceTab.stepOne.tabs.recording.saveButton')}
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                {recordData && (
                  <AudioPlayer
                    icons={{ CloseIcon: DeleteIcon }}
                    useStyles={useStyles}
                    onClose={() => {
                      setRecordData(null);
                      setContent({ ...content, file: null, name: null });
                    }}
                    volume={false}
                    displayCloseButton
                    src={recordData && recordData.blobURL}
                  />
                )}
              </>
            )}
          </div>
        </div>

        <div className={classes.section}>
          <div className={`${classes.stepHeader}`}>
            <span className={classes.stepHeaderNum}>03</span>
            <span>{t('inferenceTab.stepThree.labelInput.placeHolder')}</span>
          </div>
          <input
            onChange={e => {
              if (checkValidLoginStatus(userId, dispatch)) {
                setLabel(e.target.value);
              }
            }}
            placeholder={t('inferenceTab.stepThree.labelInput.placeHolder')}
            disabled={loading}
            className={classes.labelInput}
            value={label}
          />
        </div>

        <div className={classes.section}>
          <div className={`${classes.stepHeader}`}>
            <span className={classes.stepHeaderNum}>04</span>
            <span>{t('inferenceTab.stepThree.title')}</span>
          </div>
          <div className={classes.mixButtonContainer}>
            <div
              className={classes.mixButton}
              onClick={() => {
                if (checkValidLoginStatus(userId, dispatch)) {
                  handleMixing();
                }
              }}
            >
              <TuneIcon className={classes.mixIcon} />
              <div className={classes.mixLabel}>{t('mixingModal.label')}</div>
            </div>
            <div
              className={`${classes.extensionButton} ${outputFormat == 'mp3' ? classes.extensionSelected : ''}`}
              onClick={() => setOutputFormat('mp3')}
            >
              {'MP3'}
            </div>
            <HtmlTooltip
              title={
                <div className={'globalTooltipContainer'}>
                  <div className={'globalTooltipTextContainer'}>
                    <div className={'globalTooltipText'}>{t('inferenceTab.stepThree.tooltip.outputFormatMessage')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <Link to="/subscription" className={'globalTooltipTextLink'}>
                      {user.subscription?.type === 'BASIC'
                        ? t('inferenceTab.stepThree.tooltip.upgradePrompt')
                        : t('inferenceTab.stepThree.tooltip.subscribePrompt')}
                    </Link>
                  </div>
                </div>
              }
              interactive
              leaveDelay={200}
              open={showOutputFormatTooltip && !tierToShowOutputFormat.includes(user.subscription?.type)}
              onClose={() => setShowOutputFormatTooltip(false)}
            >
              <div
                className={`${classes.extensionButton} ${outputFormat === 'wav' ? classes.extensionSelected : ''}`}
                onClick={handleWavButtonClick}
              >
                {'WAV'}
              </div>
            </HtmlTooltip>
          </div>
        </div>

        {((!submitSubText && !loadingText) || isAdmin || isCustom) && (
          <div className={classes.buttonContainer}>
            <div>
              {userId &&
                (user.subscription?.status === 'past_due' || user.subscription?.type !== 'PREMIUM') &&
                inferenceRemainingCount > 0 && (
                  <div className={classes.buttonRemainingText}>
                    {t('inferenceTab.submit.remainingCountText')}: {inferenceRemainingCount}
                    {t('inferenceTab.submit.remainingCountUnit')}
                  </div>
                )}
            </div>
            <div>
              <div className={classes.submitContainer}>
                <HtmlTooltip
                  title={
                    <div className={'globalTooltipContainer'}>
                      <div className={'globalTooltipTextContainer'}>
                        <div className={'globalTooltipText globalTooltipTextBullet'}>&#x2022;</div>
                        <div className={'globalTooltipText'}>{t('inferenceTab.submit.tooltip.contents.0')}</div>
                      </div>
                      <div className={'globalTooltipTextContainer'}>
                        <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                        <div className={'globalTooltipText'}>{t('inferenceTab.submit.tooltip.contents.1')}</div>
                      </div>
                      <div className={'globalTooltipTextContainer'}>
                        <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                        <div className={'globalTooltipText'}>{t('inferenceTab.submit.tooltip.contents.2')}</div>
                      </div>
                    </div>
                  }
                >
                  <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
                </HtmlTooltip>
                <div className={classes.button} onClick={handleSubmit}>
                  {inferenceInProgress
                    ? t('inferenceTab.submit.alreadyInProgress')
                    : userId && inferenceRemainingCount <= 0
                      ? t('inferenceTab.submit.noRemaining')
                      : t('inferenceTab.submit.start')}
                </div>
              </div>
            </div>
          </div>
        )}
        {!submitSubText && <div className={classes.buttonSubText}>{submitSubText}</div>}
        {loadingText && (
          <div className={classes.loadingContainer}>
            <CircularProgress size="1.4rem" />
            <span className={classes.loadingText}>{loadingText}</span>
          </div>
        )}
        <div ref={bottomRef} />
        {completedAudio && (
          <ReactJkMusicPlayer
            mode="full"
            theme="light"
            autoPlay={false}
            showPlayMode={false}
            showThemeSwitch={false}
            showMiniModeCover={false}
            autoHiddenCover
            onBeforeDestroy={onBeforeDestroy}
            spaceBar={true}
            showDestroy={true}
            responsive={false}
            showReload={false}
            toggleMode={false}
            remove={false}
            customDownloader={customDownloader}
            audioLists={completedAudio}
          />
        )}
      </div>
    </>
  );
}

export default GenerateMusicPage;
