import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  pageHeaderContainer: {
    [theme.breakpoints.down('md')]: {
      marginTop: '3rem',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  pageHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '4rem',
    },
    fontSize: '4rem',
    color: '#fff',
  },
  purchaseTypeButtonContainer: {
    marginBottom: '1.5rem',
    backgroundColor: '#818181',
    padding: '0.2rem!important',
  },
  purchaseTypeButton: {
    [theme.breakpoints.down('xs')]: {
      padding: '0.2rem 1rem!important',
    },
    width: '12rem',
    fontWeight: '400',
    backgroundColor: '#818181!important',
    color: '#000!important',
    padding: '0.2rem 2rem!important',
    border: 'none!important',
  },
  selected: {
    backgroundColor: '#F0F0F0!important',
    borderRadius: '0.2rem!important',
  },
  cardHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardHeader: {
    fontSize: '1.5em',
    whiteSpace: 'nowrap',
  },
  cardHeaderCurrent: {
    fontWeight: '300',
    fontSize: '0.8em',
    border: '0.1rem solid #D1FE75',
    borderRadius: '0.5rem',
    padding: '0.4rem 0.5rem',
  },
  cardHeaderPastDue: {
    fontWeight: '300',
    fontSize: '0.8em',
    border: '0.1rem solid #e34c4c',
    borderRadius: '0.5rem',
    padding: '0.4rem 0.5rem',
  },
  cardFee: {
    fontSize: '2rem',
    marginTop: '0.5rem',
  },
  strikethroughPrice: {
    position: 'relative',
    marginRight: '0.7em',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: '0',
      top: '50%',
      width: '105%',
      height: '0.1em',
      backgroundColor: 'red',
      transform: 'translateY(-50%)',
    },
  },
  cardHeaderBadgeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginLeft: '1rem',
    gap: '0.5rem',
  },
  promoBadge: {
    backgroundColor: '#D1FE75!important',
    color: 'black!important',
    border: 'none!important',
    padding: '0.4rem 0.5rem',
    borderRadius: '0.5rem',
    textAlign: 'center',
    minWidth: '6.5rem',
  },
  cardFeeSubText: {
    fontSize: '1rem',
    marginLeft: '0.3rem',
  },
  cardDescription: {
    fontSize: '1rem',
    color: '#71717A',
    marginTop: '1rem',
    lineHeight: '1.5rem',
  },
  divider: {
    borderTop: 'solid 0.05rem #71717A',
    margin: '1rem 0',
    color: '#71717A',
  },
  featureContainer: {
    margin: '1rem 0',
    display: 'flex',
    alignItems: 'center',
  },
  featureTextThai: {
    fontSize: '0.8rem!important',
  },
  featureText: {
    fontSize: '0.9rem',
    lineHeight: '1rem',
    fontWeight: '300',
    marginLeft: '0.5rem',
    display: 'flex',
    alignItems: 'center',
  },
  cardButton: {
    border: '0.1rem solid #D1FE75',
    borderRadius: '0.3rem',
    padding: '0.7rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    margin: '1rem 0',
    color: '#fff!important',
    height: '2.6rem',
  },
  cardButtonDisabled: {
    cursor: 'not-allowed',
    borderColor: '#71717A',
    color: '#71717A',
  },
  cardGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '20.5rem',
    maxWidth: '23rem!important',
    padding: '1rem 0 0 0!important',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    marginTop: '5%',
  },
  tooltipImg: {
    marginLeft: '0.5rem',
    width: '1rem',
  },
  separater: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    margin: '1.5rem 0',
  },
  noMargin: {
    margin: '0',
  },
  stepHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.4rem',
    },
    fontSize: '1.6rem',
    marginBottom: '0.5rem',
    color: '#fff',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    justifyContent: 'stretch',
    display: 'flex',
    border: '0.1rem solid #fafafa',
    backgroundColor: '#161616',
    padding: '1rem',
    borderRadius: '0.5rem',
    color: '#fff',
    fontSize: '0.9rem',
    fontWeight: '400',
    maxWidth: '50rem',
    marginBottom: '2rem',
    marginRight: '3rem',
    minHeight: '4rem',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'none',
      marginRight: '0',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
    },
  },
  cardLabel: {
    flex: 1,
    marginRight: '0.7rem',
    display: 'flex',
    alignItems: 'center',
  },
  cardTimestamp: {
    width: '9.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '4rem',
    },
  },
  cardSubscriptionHeader: {
    padding: '0.5rem',
    height: '2rem',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: '1.5rem',
    fontWeight: '400',
  },
  popularSubscription: {
    backgroundColor: '#D1FE75!important',
    boxShadow: '0 0 0.5rem #D1FE75!important',
    borderRadius: '1.1rem 1.1rem 0.5rem 0.5rem!important',
    border: '0.1rem solid #D1FE75',
  },
  normalSubscription: {
    backgroundColor: 'transparent!important',
  },
  pupularPurchaseIcon: {
    width: '2rem',
    position: 'relative',
    top: '-0.3rem',
  },
  onetimeFee: {
    marginTop: '2rem',
    fontSize: '1.4rem',
    marginLeft: '0.1rem',
  },
  onetimeCardButton: {
    border: '0.1rem solid #fff',
    borderRadius: '0.3rem',
    padding: '0.7rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1rem',
    cursor: 'pointer',
  },
}));

export { useStyles };
