import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '730px',
    minWidth: '299px',
    width: '95%',
    alignSelf: 'center',
    marginBottom: '90px',
  },
  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '23px',
    },
    fontSize: '33px',
    fontWeight: '400',
    color: '#fff',
    marginBottom: '20px',
  },
  ttiTextArea: {
    width: '100%',
    fontSize: '16px',
    backgroundColor: 'black',
    color: 'white',
    padding: '12px',
    borderRadius: '8px',
    outline: 'none',
    resize: 'vertical',
  },
  characters: {
    [theme.breakpoints.down('xs')]: {
      height: '1.2rem',
      fontSize: '0.6rem',
    },
    height: '1.3rem',
    fontSize: '0.8rem',
    color: '#fff',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  imageContainer: {
    [theme.breakpoints.down('xs')]: {
      height: '20rem',
    },
    width: '100%',
    height: '26rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000000',
    position: 'relative',
    marginBottom: '16px',
    borderRadius: '8px',
  },
  loadingContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circularProgress: {
    width: '1.4rem !important',
    height: '1.4rem !important',
    color: 'white !important',
    marginBottom: '1rem',
  },
  loadingText: {
    color: '#fff',
    fontWeight: '300',
    textAlign: 'center',
    marginBottom: '0.5rem',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    display: 'block',
    borderRadius: '8px',
  },
  smallImage: {
    [theme.breakpoints.down('xs')]: {
      maxHeight: '4rem',
      maxWidth: '4rem',
    },
    maxHeight: '6rem',
    maxWidth: '6rem',
    width: 'auto',
    height: 'auto',
    objectFit: 'contain',
    border: '0.2rem solid #fff',
    borderRadius: '0.5rem',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '#CAFF73',
    },
  },
  downloadButton: {
    [theme.breakpoints.down('xs')]: {
      width: '1.5rem',
      height: '1.5rem',
      padding: '0 !important',
    },
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    color: 'black',
    border: 'none',
    borderRadius: '0.5rem',
    width: '2.5rem',
    height: '2.5rem',
    cursor: 'pointer',
    backgroundColor: '#CAFF73',
  },
  downloadIcon: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem !important',
    },
    fontSize: '1.5rem',
  },
  overlay: {
    [theme.breakpoints.down('xs')]: {
      gap: '0.3rem',
    },
    gap: '0.5rem',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    bottom: '1%',
    backgroundColor: 'rgba(255, 255, 255, 0.1)' /* Semi-transparent background */,
    backdropFilter: 'blur(10px)' /* Blur effect for the background */,
    padding: '0.3rem' /* Add some padding for spacing */,
    borderRadius: '0.5rem' /* Smooth rounded corners */,
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)' /* Optional shadow for better contrast */,
  },
  lastLineContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  settingButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  settingButton: {
    [theme.breakpoints.down('xs')]: {
      height: '2.5rem',
      fontSize: '0.8rem',
      width: '5.5rem',
    },
    height: '2.8rem',
    width: '7rem',
    backgroundColor: '#141414',
    padding: '0.5rem',
    fontSize: '0.8rem',
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    border: '1px solid #fafafa',
  },
  settingTypo: {
    [theme.breakpoints.down(600)]: {
      fontSize: '0.7rem !important',
      marginLeft: '0.2rem',
    },
    marginLeft: '0.3rem',
    fontSize: '0.8rem !important',
    color: '#fff',
  },
  tuneIcon: {
    '& svg': {
      fontSize: '20rem',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '1.3rem',
    },
    color: '#fff',
  },
  submitContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'start',
  },
  coinContainer: { marginLeft: '4px' },
  coin: {
    [theme.breakpoints.down('xs')]: {
      height: '1rem',
      fontSize: '0.6rem',
    },
    height: '1.5rem',
    fontSize: '0.8rem',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  tooltipImg: {
    [theme.breakpoints.down('xs')]: {
      width: '14px',
      marginTop: '0',
    },
    width: '16px',
    marginTop: '2px',
  },
  submitButton: {
    [theme.breakpoints.down('xs')]: {
      padding: '0.8rem',
      fontSize: '0.8rem',
      width: '5.5rem',
      height: '2.5rem',
    },
    width: '7rem',
    height: '2.8rem',
    backgroundColor: '#CAFF73',
    padding: '0.7rem',
    fontSize: '0.9rem',
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  submitButtonInProgress: {
    [theme.breakpoints.down('xs')]: {
      padding: '0.8rem',
      fontSize: '0.8rem',
      width: '5.5rem',
      height: '2.5rem',
    },
    width: '7rem',
    height: '2.8rem',
    backgroundColor: '#767676',
    padding: '0.7rem',
    fontSize: '0.9rem',
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  submitTypo: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
    },
    fontSize: '0.8rem',
  },
  indentation: { marginLeft: '16px' },
  threshold: {
    [theme.breakpoints.down('xs')]: {
      height: '2.5rem',
      fontSize: '0.8rem',
    },
    marginLeft: '0.3rem',
    height: '2.8rem',
    width: '4rem',
    color: 'white',
    backgroundColor: '#141414',
    padding: '0.5rem',
    fontSize: '0.8rem',
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    border: '1px solid #fafafa',
  },
}));

export { useStyles };
