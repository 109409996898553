import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './DownloadModal.styles.js';
import DownloadIcon from '@mui/icons-material/FileDownload';
import CircularProgress from '@mui/material/CircularProgress';
import {
  getInferenceSignedUrl,
  getVocalExtractSignedUrl,
  getTTIInferenceSignedUrl,
} from '../../services/page.services';
import BaseModalLayout from './BaseModalLayout.js';

export default function DownloadModal(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const getNames = (id, label, type, extension, tag) => {
    const subLabel = (() => {
      if (type === 'voice') return '-voice';
      else if (type === 'instrumental') return '-instrument';
      else if (type === 'backVocal') return '-backvocal';
      else return '';
    })();
    return {
      s3FileName: `${id}${subLabel}.${extension}`,
      downloadName: `${tag}${label}${subLabel}.${extension}`,
    };
  };

  const handleDownload = async type => {
    const { id, label, alignment, outputFormat, tag } = props;
    setLoading(true);
    const tempLink = document.createElement('a');
    try {
      if (alignment == 'vocalExtract') {
        const { s3FileName, downloadName } = getNames(id, label, type, outputFormat, tag);
        tempLink.href = await getVocalExtractSignedUrl(id, s3FileName, downloadName);
      } else if (alignment == 'music') {
        const { s3FileName, downloadName } = getNames(id, label, type, outputFormat, tag);
        tempLink.href = await getInferenceSignedUrl(id, s3FileName, downloadName);
      }
      if (tempLink.href) {
        tempLink.click();
        URL.revokeObjectURL(tempLink.href);
      }
    } catch (e) {
      setLoading(false);
      console.log('e : ', e);
    }
    setLoading(false);
  };

  const handleDownloadImage = async (index, id, label) => {
    if (id === null) return;
    setLoading(true);
    try {
      const downloadName = `${label}_${index + 1}.png`;
      const ttiImageFileName = `text-to-image_0000${index + 1}_.png`;
      const tempLink = document.createElement('a');
      tempLink.href = await getTTIInferenceSignedUrl(id, ttiImageFileName, downloadName);

      if (tempLink.href) {
        tempLink.click();
        URL.revokeObjectURL(tempLink.href);
      }
    } catch (e) {
      setLoading(false);
      console.log('e : ', e);
    }
    setLoading(false);
  };

  return (
    <BaseModalLayout {...props} showHeaderLogo>
      {!loading && (
        <>
          {props.needMdx && props.alignment == 'music' && (
            <div className={classes.downloadButton} onClick={() => handleDownload('combined')}>
              {t('modal.combined')} <DownloadIcon />
            </div>
          )}
          {props.alignment !== 'image' && (
            <div className={classes.downloadButton} onClick={() => handleDownload('voice')}>
              {t('modal.vocal')} <DownloadIcon />
            </div>
          )}
          {((props.needMdx && props.alignment == 'music') || props.alignment == 'vocalExtract') && (
            <div className={classes.downloadButton} onClick={() => handleDownload('instrumental')}>
              {t('modal.instrument')}
              <DownloadIcon />
            </div>
          )}
          {props.alignment == 'vocalExtract' && (
            <div className={classes.downloadButton} onClick={() => handleDownload('backVocal')}>
              {t('modal.backVocal')}
              <DownloadIcon />
            </div>
          )}
          {props.alignment == 'image' && (
            <>
              {Array.from({ length: props.quantity }).map((_, index) => (
                <div
                  key={index}
                  className={classes.downloadButton}
                  onClick={() => handleDownloadImage(index, props.id, props.label)}
                >
                  {`${props.label}_${index + 1}`}
                  <DownloadIcon />
                </div>
              ))}
            </>
          )}
        </>
      )}
      {loading && <CircularProgress />}
    </BaseModalLayout>
  );
}
