import { useEffect } from 'react';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { userActions } from './actions/user.actions';

import Layout from './components/layout/Layout';

import ProfilePage from './components/pages/ProfilePage';
import GenerateMusicPage from './components/pages/GenerateMusicPage';
import GenerateTTSPage from './components/pages/GenerateTTSPage';
import MyVoicePage from './components/pages/MyVoicePage';
import Community from './components/pages/Community';
import TrainVoicePage from './components/pages/TrainVoicePage';
import TTSTrainVoicePage from './components/pages/TTSTrainVoicePage';
import TTSHistoryPage from './components/pages/TTSHistoryPage';
import VideoEditorPage from './components/pages/VideoEditorPage';
import customtheme from './theme';
import { ThemeProvider } from '@material-ui/core/styles';
import SubscriptionPage from './components/pages/SubscriptionPage';
import './i18n';
import { useTranslation } from 'react-i18next';
import VocalExtractorPage from './components/pages/VocalExtractorPage';
import FaqPage from './components/pages/FaqPage';
import ContactPage from './components/pages/ContactPage';
import { sendWelcomeEmail } from './services/page.services';
import TimeAgo from 'javascript-time-ago';
import EmailPreferencePage from './components/pages/EmailPreferencePage';
import FaceSwapPage from './components/pages/FaceSwap';
import GenerateTTIPage from './components/pages/GenerateTTIPage';

import en from 'javascript-time-ago/locale/en';
import { showMessageV2 } from './utils/page.utils';

export default function App() {
  const { i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector(state => state.user);
  const userId = user?.id;
  const { email, currency } = user;
  const dispatch = useDispatch();

  TimeAgo.addDefaultLocale(en);

  useEffect(() => {
    if (window.location.hostname.includes('beta.sorisori.ai')) {
      const existingMetaTag = document.querySelector('meta[name="robots"]');
      if (!existingMetaTag) {
        const metaTag = document.createElement('meta');
        metaTag.name = 'robots';
        metaTag.content = 'noindex, nofollow';
        document.head.appendChild(metaTag);
      }
    }
  }, []);

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    if (defaultLanguage === 'ko') i18n.changeLanguage('ko');
    else if (defaultLanguage === 'th') i18n.changeLanguage('th');
    else if (defaultLanguage === 'ja') i18n.changeLanguage('ja');
    else i18n.changeLanguage('en');
    localStorage.setItem('selectedLanguage', defaultLanguage);

    if (!userId) {
      userActions.getUser(dispatch);
    }
  }, []);

  useEffect(() => {
    const message = searchParams.get('message');
    const isNewUser = searchParams.get('new_user');

    if (message) {
      showMessageV2(dispatch, message);
      searchParams.delete('message');
    }

    if (isNewUser && email && currency) {
      (async () => {
        await sendWelcomeEmail(email, currency);
      })();
      searchParams.delete('new_user');
    }

    if (message || isNewUser) {
      setSearchParams(searchParams);
    }
  }, [searchParams, email, currency]);

  return (
    <ThemeProvider theme={customtheme}>
      <Layout>
        <Routes>
          <Route path="/" Component={Community} />
          <Route path="/train-voice" Component={TrainVoicePage} />
          <Route path="/generate-music" Component={GenerateMusicPage} />
          <Route path="/vocal-extract" Component={VocalExtractorPage} />
          <Route path="/my-voices" Component={MyVoicePage} />
          <Route path="/community" Component={Community} />
          <Route path="/tts-train-voice" Component={TTSTrainVoicePage} />
          <Route path="/tts-history" Component={TTSHistoryPage} />
          <Route path="/generate-tts" Component={GenerateTTSPage} />
          <Route path="/generate-tti" Component={GenerateTTIPage} />
          <Route path="/video-editor" Component={VideoEditorPage} />
          <Route path="/faceswap" Component={FaceSwapPage} />
          <Route path="/subscription" Component={SubscriptionPage} />
          <Route path="*" Component={Community} />
          <Route path="/faq" Component={FaqPage} />
          <Route path="/contact" Component={ContactPage} />
          <Route path="/email-preference" Component={EmailPreferencePage} />
          <Route path="/profile" Component={ProfilePage} />
        </Routes>
      </Layout>
    </ThemeProvider>
  );
}
