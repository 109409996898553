import { appConstants } from '../constants/app.constants';

const INITIAL_STATE = {
  openLoginModal: false,
  openMessageModal: false,
  openMessageModalV2: false,
  messageModalText: '',
  messageModalV2Text: '',
  downloadProps: {},
  initialized: false,
  selectedModel: null,
  selectedSource: null,
  openCustomMessageModal: false,
};

export function appReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case appConstants.OPEN_LOGIN_MODAL:
      return {
        ...state,
        openLoginModal: true,
        preLoginRoute: action.payload.preLoginRoute,
      };
    case appConstants.CLOSE_LOGIN_MODAL:
      return {
        ...state,
        openLoginModal: false,
      };
    case appConstants.OPEN_MESSAGE_MODAL_V2:
      return {
        ...state,
        ...action.payload,
        openMessageModalV2: true,
        messageModalV2Text: action.payload.message,
      };
    case appConstants.CLOSE_MESSAGE_MODAL_V2:
      return {
        ...state,
        openMessageModalV2: false,
      };
    case appConstants.OPEN_LANGUAGE_MODAL:
      return {
        ...state,
        openLanguageModal: true,
      };
    case appConstants.CLOSE_LANGUAGE_MODAL:
      return {
        ...state,
        openLanguageModal: false,
      };

    case appConstants.SELECT_COMMUNITY_ITEM:
      return {
        ...state,
        selectedModel: action.payload.selectedModel,
      };
    case appConstants.CLEAR_SELECTED_COMMUNITY_ITEM:
      return {
        ...state,
        selectedModel: null,
      };
    case appConstants.SELECT_EDITOR_SOURCE:
      return {
        ...state,
        selectedSource: action.payload.selectedSource,
      };
    case appConstants.CLEAR_SELECTED_EDITOR_SOURCE:
      return {
        ...state,
        selectedSource: null,
      };
    case appConstants.SET_INITIALIZED:
      return {
        ...state,
        initialized: action.payload.initialized,
      };
    case appConstants.OPEN_CUSTOM_MESSAGE_MODAL:
      return {
        ...state,
        openCustomMessageModal: true,
        usage: action.payload.usage,
        customMessage: action.payload.customMessage,
      };
    case appConstants.CLOSE_CUSTOM_MESSAGE_MODAL:
      return {
        ...state,
        openCustomMessageModal: false,
      };
    default:
      return state;
  }
}
