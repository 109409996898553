import Modal from '@material-ui/core/Modal';
import { useStyles } from './MixingModal.styles.js';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import * as NumberField from '@base_ui/react/NumberField';
import * as Switch from '@base_ui/react/Switch';
import Dialog from '@material-ui/core/Dialog';
import Waveform from './Waveform.js';
import pcm from '../../audio/pcm.json';

import HtmlTooltip from '../HtmlTooltip';
import tooltipImg from '../../img/tooltip.png';
import ErrorIcon from '@mui/icons-material/Error';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const trackUrls = [
  'https://public.sorisori.ai/audio/christmas_tree_unmixed_demo.mp3',
  'https://public.sorisori.ai/audio/christmas_tree_mixed_demo.mp3',
];

export default function MixingModal(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles();

  //Run this whenever userId(whenver the page loads for the first time), query, or alignment change
  const [fontStyle, setFontStyle] = useState('TheJamsil');

  const { open, loading } = props;

  const user = useSelector(state => state.user);

  const tierToShowOutputFormat = ['PREMIUM', 'PRO', 'ADMIN', 'CUSTOM'];

  const [isDisabled, setIsDisabled] = useState(true);

  const [pitch, setPitch] = useState(0);
  const [vocalLevel, setVocalLevel] = useState(0);
  const [reverbLevel, setReverbLevel] = useState(3);
  const [reverbLength, setReverbLength] = useState(2.6);

  const [showAlert, setShowAlert] = useState(false);
  const [showUpgrade, setShowUpgrade] = useState(false);

  const [isToggled, setIsToggled] = useState(false);

  const handleInputClick = () => {
    if (isDisabled) {
      setShowUpgrade(true);
    }
  };

  const handleModalClose = () => {
    if (isToggled) {
      if (pitch === null || vocalLevel === null || reverbLevel === null || reverbLength === null) {
        setShowAlert(true);
        return;
      }
    } else if (pitch === null) {
      setShowAlert(true);
      return;
    }
    setShowAlert(false);
    props.onMixingModalClose(pitch, vocalLevel, reverbLevel, reverbLength, isToggled);
  };

  useEffect(() => {
    if (!tierToShowOutputFormat.includes(user.subscription?.type)) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [user.subscription?.type]);

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  return (
    <>
      <Modal open={!!open} onClose={handleModalClose} className={`${fontStyle}`}>
        <div className={classes.container}>
          <div className={classes.modalTitle}>{t('mixingModal.title')}</div>

          {showAlert && (
            <Grid item container alignItems="center" className={classes.alertOuterContainer}>
              <Grid item className={classes.alertContainer} xs={12}>
                <ErrorIcon
                  fontSize="medium"
                  sx={{
                    color: '#DE3C33',
                    marginRight: '0.5rem',
                  }}
                />
                <div>{t('mixingModal.alert')}</div>
              </Grid>
            </Grid>
          )}

          <div className={classes.separater} />

          <div className={classes.pitchContainer}>
            <div className={classes.inputNameContainer}>
              <HtmlTooltip
                title={
                  <div className={'globalTooltipContainer'}>
                    <div className={'globalTooltipTextContainer'}>
                      <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                      <div className={'globalTooltipText'}>{t('mixingModal.tooltip.contents.0')}</div>
                    </div>
                    <div className={'globalTooltipTextContainer'}>
                      <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                      <div className={'globalTooltipText'}>{t('mixingModal.tooltip.contents.1')}</div>
                    </div>
                    <div className={'globalTooltipTextContainer'}>
                      <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                      <div className={'globalTooltipText'}>{t('mixingModal.tooltip.contents.2')}</div>
                    </div>
                  </div>
                }
              >
                <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
              </HtmlTooltip>
              <div className={classes.inputName}>{t('mixingModal.sliders.contents.0')}</div>
            </div>
            <NumberField.Root
              className={classes.numberFieldRoot}
              min={-12}
              max={12}
              step={1}
              required="true"
              value={pitch}
              onChange={setPitch}
            >
              <NumberField.Group className={classes.numberFieldGroup}>
                <NumberField.Decrement className={classes.numberFieldButton}>
                  <RemoveIcon fontSize="small" />
                </NumberField.Decrement>
                <NumberField.Input className={classes.numberFieldInput} />
                <NumberField.Increment className={classes.numberFieldButton}>
                  <AddIcon fontSize="small" />
                </NumberField.Increment>
              </NumberField.Group>
            </NumberField.Root>
          </div>

          {/* <div className={classes.separater} /> */}

          <div className={classes.mixToggleOuterContainer}>
            <div className={classes.mixSeparater} />
            <div className={classes.mixToggleContainer}>
              {t('mixingModal.demo.toggle')}
              {!isToggled ? ' OFF' : ' ON'}
              <Switch.Root className={classes.mixToggle} checked={isToggled} onChange={() => setIsToggled(!isToggled)}>
                <Switch.Thumb className={classes.mixToggleThumb} />
              </Switch.Root>
            </div>
            <div className={classes.mixSeparater} />
          </div>

          <div className={classes.inputContainer}>
            <div className={classes.inputNameContainer}>
              <HtmlTooltip
                title={
                  <div className={'globalTooltipContainer'}>
                    <div className={'globalTooltipTextContainer'}>
                      <div className={'globalTooltipText'}>{t('mixingModal.tooltip.contents.3')}</div>
                    </div>
                    <div className={'globalTooltipTextContainer'}>
                      <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                      <div className={'globalTooltipText'}>{t('mixingModal.tooltip.contents.4')}</div>
                    </div>
                  </div>
                }
              >
                <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
              </HtmlTooltip>
              <div
                className={classes.inputName}
                style={{ color: isDisabled ? '#818181' : !isToggled ? '#818181' : '' }}
              >
                {t('mixingModal.sliders.contents.1')} &#40;dB&#41;
              </div>
            </div>
            <NumberField.Root
              className={classes.numberFieldRoot}
              min={-6}
              max={6}
              step={1}
              required="true"
              value={isDisabled ? 0 : isToggled ? vocalLevel : 0}
              onChange={val => isToggled && setVocalLevel(val)}
              readOnly={isDisabled}
              onClick={handleInputClick}
            >
              <NumberField.Group className={classes.numberFieldGroup}>
                <NumberField.Decrement
                  className={`${classes.numberFieldButton} ${isDisabled ? classes.disabledButton : !isToggled ? classes.disabledButton : ''}`}
                >
                  <RemoveIcon fontSize="small" />
                </NumberField.Decrement>
                <NumberField.Input
                  className={`${classes.numberFieldInput} ${isDisabled ? classes.disabledInput : !isToggled ? classes.disabledInput : ''}`}
                />
                <NumberField.Increment
                  className={`${classes.numberFieldButton} ${isDisabled ? classes.disabledButton : !isToggled ? classes.disabledButton : ''}`}
                >
                  <AddIcon fontSize="small" />
                </NumberField.Increment>
              </NumberField.Group>
            </NumberField.Root>
          </div>
          <div className={classes.inputContainer}>
            <div className={classes.inputNameContainer}>
              <HtmlTooltip
                title={
                  <div className={'globalTooltipContainer'}>
                    <div className={'globalTooltipTextContainer'}>
                      <div className={'globalTooltipText'}>{t('mixingModal.tooltip.contents.5')}</div>
                    </div>
                    <div className={'globalTooltipTextContainer'}>
                      <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                      <div className={'globalTooltipText'}>{t('mixingModal.tooltip.contents.6')}</div>
                    </div>
                  </div>
                }
              >
                <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
              </HtmlTooltip>
              <div
                className={classes.inputName}
                style={{ color: isDisabled ? '#818181' : !isToggled ? '#818181' : '' }}
              >
                {t('mixingModal.sliders.contents.2')}
              </div>
            </div>
            <NumberField.Root
              className={classes.numberFieldRoot}
              min={0}
              max={6}
              step={1}
              value={isDisabled ? 0 : isToggled ? reverbLevel : 0}
              required="true"
              onChange={val => isToggled && setReverbLevel(val)}
              readOnly={isDisabled}
              onClick={handleInputClick}
            >
              <NumberField.Group className={classes.numberFieldGroup}>
                <NumberField.Decrement
                  className={`${classes.numberFieldButton} ${isDisabled ? classes.disabledButton : !isToggled ? classes.disabledButton : ''}`}
                >
                  <RemoveIcon fontSize="small" />
                </NumberField.Decrement>
                <NumberField.Input
                  className={`${classes.numberFieldInput} ${isDisabled ? classes.disabledInput : !isToggled ? classes.disabledInput : ''}`}
                />
                <NumberField.Increment
                  className={`${classes.numberFieldButton} ${isDisabled ? classes.disabledButton : !isToggled ? classes.disabledButton : ''}`}
                >
                  <AddIcon fontSize="small" />
                </NumberField.Increment>
              </NumberField.Group>
            </NumberField.Root>
          </div>
          <div className={classes.inputContainer}>
            <div className={classes.inputNameContainer}>
              <HtmlTooltip
                title={
                  <div className={'globalTooltipContainer'}>
                    <div className={'globalTooltipTextContainer'}>
                      <div className={'globalTooltipText'}>{t('mixingModal.tooltip.contents.7')}</div>
                    </div>
                    <div className={'globalTooltipTextContainer'}>
                      <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                      <div className={'globalTooltipText'}>{t('mixingModal.tooltip.contents.8')}</div>
                    </div>
                  </div>
                }
              >
                <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
              </HtmlTooltip>
              <div
                className={classes.inputName}
                style={{ color: isDisabled ? '#818181' : !isToggled ? '#818181' : '' }}
              >
                {t('mixingModal.sliders.contents.3')} &#40;s&#41;
              </div>
            </div>
            <NumberField.Root
              className={classes.numberFieldRoot}
              min={0}
              max={6}
              step={0.2}
              value={isDisabled ? 0 : isToggled ? reverbLength : 0}
              required="true"
              onChange={val => isToggled && setReverbLength(val)}
              readOnly={isDisabled}
              onClick={handleInputClick}
            >
              <NumberField.Group className={classes.numberFieldGroup}>
                <NumberField.Decrement
                  className={`${classes.numberFieldButton} ${isDisabled ? classes.disabledButton : !isToggled ? classes.disabledButton : ''}`}
                >
                  <RemoveIcon fontSize="small" />
                </NumberField.Decrement>
                <NumberField.Input
                  className={`${classes.numberFieldInput} ${isDisabled ? classes.disabledInput : !isToggled ? classes.disabledInput : ''}`}
                />
                <NumberField.Increment
                  className={`${classes.numberFieldButton} ${isDisabled ? classes.disabledButton : !isToggled ? classes.disabledButton : ''}`}
                >
                  <AddIcon fontSize="small" />
                </NumberField.Increment>
              </NumberField.Group>
            </NumberField.Root>
          </div>
          <div className={classes.demoOuterContainer}>
            <div className={classes.demoContainer}>
              <Waveform className={classes.waveform} tracks={trackUrls} pcm={pcm} isToggled={isToggled} />
              <div className={classes.mixStatus}>
                {isToggled ? t('mixingModal.demo.status.1') : t('mixingModal.demo.status.0')}
              </div>
            </div>
          </div>
          <div className={classes.demoLabel}>{t('mixingModal.demo.description')}</div>

          {!tierToShowOutputFormat.includes(user.subscription?.type) && (
            <div className={classes.upgradeButton} onClick={() => navigate('/subscription')}>
              {t('mixingModal.upgradeButton.0')}
              <strong>{t('mixingModal.upgradeButton.1')}</strong>
            </div>
          )}
        </div>
      </Modal>
      <Dialog
        open={showUpgrade}
        onClose={() => setShowUpgrade(false)}
        className={`${fontStyle} ${classes.upgradeDialog}`}
      >
        <div className={classes.dialogTitle}>{t('mixingModal.dialog.title')}</div>
        <div className={classes.dialogText}>{t('mixingModal.dialog.text')}</div>
        <div className={classes.upgradeButton} onClick={() => navigate('/subscription')}>
          {t('mixingModal.upgradeButton.0')}
          <strong>{t('mixingModal.upgradeButton.1')}</strong>
        </div>
      </Dialog>
    </>
  );
}
