import Modal from '@material-ui/core/Modal';
import { useStyles } from './TrainingInfoModal.styles.js';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import BaseModalLayout from './BaseModalLayout.js';

export default function TrainingInfoModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [fontStyle, setFontStyle] = useState('TheJamsil');

  const { open, loading } = props;

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  return (
    <BaseModalLayout open={!!open} onClose={() => props.onTrainingInfoModalClose()} sizeMax>
      <div className={classes.container}>
        {!loading && (
          <>
            <div className={classes.label}>{props.label}</div>
            <div className={classes.separater} />
            {!props.external && (
              <Accordion className={classes.accordion} disableGutters>
                <AccordionSummary
                  className={classes.accordionTitle}
                  expandIcon={<ChevronRightIcon className={classes.accordionIcon} />}
                >
                  {t('historyTab.trainingInfo.files')}
                </AccordionSummary>
                <AccordionDetails className={classes.accordionContent}>
                  {props.fileList?.map((file, index) => (
                    <div key={index} className={classes.fileNames}>
                      {file}
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            )}
            <div className={classes.info}>
              <div className={classes.infoTitle}>{t('historyTab.trainingInfo.extracted')}:</div>
              <div className={classes.infoValue}>
                {props.needMdx ? t('historyTab.trainingInfo.yes') : t('historyTab.trainingInfo.no')}
              </div>
            </div>
            <div className={classes.info}>
              <div className={classes.infoTitle}>{t('historyTab.trainingInfo.shared')}:</div>
              <div className={classes.infoValue}>
                {props.shared ? t('historyTab.trainingInfo.yes') : t('historyTab.trainingInfo.no')}
              </div>
            </div>
            <div className={classes.info}>
              <div className={classes.infoTitle}>{t('historyTab.trainingInfo.status')}:</div>
              {props.status === 'COMPLETE' ? (
                <div className={classes.infoValue}>{t('pipeline.trainingStatusMessage.complete')}</div>
              ) : (
                <div className={classes.infoValue}>
                  {props.pipelineStatus
                    ? t(`pipeline.trainingStatusMessage.${props.pipelineStatus}`)
                    : props.status === 'FAILED'
                      ? t('pipeline.trainingStatusMessage.generalMessage')
                      : t('pipeline.trainingStatusMessage.starting')}
                </div>
              )}
            </div>
            {props.errorFile && (
              <div className={classes.info}>
                <div className={classes.infoTitle}>{t('historyTab.trainingInfo.errorFile')}:</div>
                <div className={classes.infoValue}>{props.errorFile}</div>
              </div>
            )}
          </>
        )}
        {loading && <CircularProgress className={classes.loading} />}
      </div>
    </BaseModalLayout>
  );
}
