import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './ConfirmationModal.styles.js';
import CircularProgress from '@mui/material/CircularProgress';
import BaseModalLayout from './BaseModalLayout.js';

export default function DownloadModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <BaseModalLayout {...props} sizeMax>
      {!props.loading && (
        <>
          <div className={classes.message}>{props.message}</div>
          <div className={classes.buttonContainer}>
            <div className={`${classes.button} ${classes.yesButton}`} onClick={() => props.onConfirmation(true)}>
              {t('modal.confirmationYes')}
            </div>
            <div className={`${classes.button} ${classes.noButton}`} onClick={() => props.onConfirmation(false)}>
              {t('modal.confirmationNo')}
            </div>
          </div>
        </>
      )}
      {props.loading && <CircularProgress className={classes.loading} />}
    </BaseModalLayout>
  );
}
