import Modal from '@material-ui/core/Modal';
import { useStyles } from './VideoExportModal.styles';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  startVideoRenderJob,
  checkVideoRenderCompletionById,
  getVideoRenderSignedUrl,
} from '../../services/page.services';

import { labelLength, statusCheckInterval } from '../../constants/app.constants.js';

import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import Download from '@mui/icons-material/Download';

import HtmlTooltip from '../HtmlTooltip';
import tooltipImg from '../../img/tooltip.png';
import { showMessageV2 } from '../../utils/page.utils.js';

export default function VideoSettingsModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { open, variables } = props;
  const user = useSelector(state => state.user);
  const tierToShowOutputFormat = ['PREMIUM', 'PRO', 'ADMIN', 'CUSTOM'];

  const [exportName, setExportName] = useState('');
  const [showWatermark, setShowWatermark] = useState(true);

  const intervalIDRef = React.useRef(null);
  const [rendering, setRendering] = useState(false);
  const [result, setResult] = useState();

  const [isDisabled, setIsDisabled] = useState(true);

  const [fontStyle, setFontStyle] = useState('TheJamsil');

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  useEffect(() => () => intervalIDRef.current && clearInterval(intervalIDRef.current), []);

  const handleStart = async () => {
    if (exportName.length > 0) {
      if (exportName.trim().length > labelLength.videoEditor) {
        dispatch(appActions.openMessageModal(t('videoEditorTab.modal.longLabel')));
        return false;
      }
      let render;
      try {
        setRendering(true);
        const renderVariables = {
          ...variables,
          userId: user.id,
          exportName: exportName,
          showWatermark: showWatermark,
        };
        render = await startVideoRenderJob(renderVariables);

        clearInterval(intervalIDRef.current);
        const tick = async () => {
          const id = render.id;
          const status = await checkVideoRenderCompletionById(id);
          if (status === 'COMPLETE') {
            clearInterval(intervalIDRef.current);
            handleRenderCompletion(id);
          } else if (status === 'FAILED') {
            clearInterval(intervalIDRef.current);
            setRendering(false);
            dispatch(appActions.openMessageModal(t('videoExportModal.videoRenderError')));
          }
        };
        intervalIDRef.current = setInterval(tick, statusCheckInterval);
      } catch (e) {
        clearInterval(intervalIDRef.current);
        console.log('error during video render job: ', e);
        setRendering(false);
        showMessageV2(dispatch, t('videoExportModal.videoRenderError'), { reloadOnClose: true });
      }
    }
  };

  const handleRenderCompletion = async id => {
    const fileName = `${id}-complete.mp4`;
    const downloadName = `${exportName}.mp4`;
    const renderUrl = await getVideoRenderSignedUrl(id, fileName, downloadName);
    setRendering(false);
    setResult(renderUrl);
    handleDownload(renderUrl);
  };

  const handleDownload = url => {
    try {
      const tempLink = document.createElement('a');
      tempLink.href = url;
      if (tempLink.href) {
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
      }
    } catch (e) {
      setLoading(false);
      console.log('e : ', e);
    }
  };

  useEffect(() => {
    if (!tierToShowOutputFormat.includes(user.subscription?.type)) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [user.subscription?.type]);

  const classes = useStyles();
  return (
    <>
      <Modal
        open={!!open}
        onClose={() => {
          props.onVideoExportModalClose(showWatermark);
        }}
        className={`${classes.modal} ${fontStyle}`}
      >
        <div className={classes.container}>
          {!result && (
            <>
              <div className={classes.titleContainer}>
                <div className={classes.pageTitle}>{t('videoExportModal.title')}</div>
                <div className={classes.startButtonContainer}>
                  <HtmlTooltip
                    title={
                      <div className={'globalTooltipContainer'}>
                        <div className={'globalTooltipTextContainer'}>
                          <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                          <div className={'globalTooltipText'}>{t('videoExportModal.tooltip.contents.0')}</div>
                        </div>
                      </div>
                    }
                  >
                    <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
                  </HtmlTooltip>
                  <Button
                    className={classes.startButton}
                    disabled={exportName.length <= 0 || rendering}
                    onClick={handleStart}
                    disableRipple
                  >
                    {t('videoExportModal.start')}
                  </Button>
                </div>
              </div>
              <div className={classes.separater} />
              {rendering && (
                <div className={classes.progressContainer}>
                  <div className={classes.renderingText}>
                    <div>{t('videoExportModal.rendering.0')}</div>
                    <div>{t('videoExportModal.rendering.1')}</div>
                  </div>
                  <div>
                    <CircularProgress size={24} className={classes.progressIcon} />
                  </div>
                </div>
              )}
              {!rendering && (
                <>
                  <input
                    className={classes.labelInput}
                    placeholder={t('videoExportModal.placeholder')}
                    value={exportName}
                    onChange={label => setExportName(label.target.value)}
                  />
                  <div className={classes.optionContainer}>
                    <div className={isDisabled && classes.disabledCheckbox}>
                      {t('videoExportModal.watermark')}
                      <Checkbox
                        className={classes.checkbox}
                        disabled={isDisabled}
                        checked={showWatermark}
                        onChange={() => setShowWatermark(!showWatermark)}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {result && (
            <>
              <div className={classes.pageTitle}>{t('videoExportModal.success.0')}</div>
              <div className={classes.separater} />
              <div className={classes.success}>{t('videoExportModal.success.1')}</div>
              <div className={classes.downloadContainer}>
                {`${exportName}.mp4`}
                <Button className={classes.downloadButton} onClick={() => handleDownload(result)}>
                  <Download fontSize="small" />
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}
