import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip enterTouchDelay={0} leaveTouchDelay={300000} {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#515151',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
    backgroundColor: '#515151',
    color: '#f8f8f8',
    maxWidth: '50rem',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #515151',
    borderRadius: '15px',
  },
}));

export default HtmlTooltip;
