import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CircularProgress from '@mui/material/CircularProgress';

import tossPayWhiteLogo from '../../img/tossPayWhite.svg';

import { useStyles } from './PaymentOptionModal.styles';
import { Currency, PaymentService } from '../../constants/app.constants';
import { showMessageV2 } from '../../utils/page.utils';
import * as pageService from '../../services/page.services';
import BaseModalLayout from './BaseModalLayout';

const PaymentOptionModal = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState();

  const createOrder = async () => {
    setLoading(true);
    try {
      const order = await pageService.createOrder({ changeMethod: true });
      setOrder(order);
    } catch (e) {
      showMessageV2(dispatch, t('subscriptionTab.paymentModal.genericError'));
      props.onClose();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.open) createOrder();
  }, [props.open]);

  return (
    <BaseModalLayout open={props.open} onClose={() => props.onClose()} showHeaderLogo>
      {loading ? (
        <CircularProgress className={classes.loading} />
      ) : (
        <>
          <div className={classes.buttonContainer}>
            <div
              onClick={() => props.onClickPaymentOption(order, PaymentService.STRIPE)}
              className={classes.stripeButton}
            >
              credit/debit card
            </div>
            {user.currency === Currency.KRW && user.id === 'ee913f11-6d12-44d4-82d4-6bddbc012aa0' && (
              <img
                className={classes.tossButton}
                src={tossPayWhiteLogo}
                alt="tossPay"
                onClick={() => props.onClickPaymentOption(order, PaymentService.TOSS)}
              />
            )}
          </div>
        </>
      )}
    </BaseModalLayout>
  );
};

export default PaymentOptionModal;
