import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  messageContainer: {
    marginBottom: '24px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  message: {
    fontSize: '16px',
    fontWeight: '300',
    color: '#c4c4c4',
    marginBottom: '5px',
  },
  button: {
    backgroundColor: '#CAFF73',
    padding: '12px 24px',
    fontSize: '16px',
    borderRadius: '32px',
    cursor: 'pointer',
    color: '#191919',
  },
}));

export { useStyles };
