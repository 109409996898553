import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  downloadButton: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '400',
    cursor: 'pointer',
    backgroundColor: '#eee',
    justifyContent: 'center',
    height: '40px',
    width: '208px',
    borderRadius: '8px',
    margin: '8px 0',
    color: '#191919',
    fontSize: '15px',
    textAlign: 'center',
  },
}));

export { useStyles };
