import React from 'react';
import { useStyles } from './FreeInferenceMessageModal.styles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BaseModalLayout from './BaseModalLayout';

export default function FreeInferenceMessageModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const messageOneArr = t('freeInferenceMessageModal.messeageOne', { returnObjects: true });
  const messageTwoArr = t('freeInferenceMessageModal.messeageTwo', { returnObjects: true });

  return (
    <BaseModalLayout {...props} showHeaderLogo sizeMid>
      <div className={classes.messageOneContainer}>
        {messageOneArr.map(message => (
          <div className={classes.messageOne}>{message}</div>
        ))}
      </div>
      <div className={classes.messageTwoContainer}>
        {messageTwoArr.map(message => (
          <div className={classes.messageTwo}>{message}</div>
        ))}
      </div>
      <div className={classes.button} onClick={() => navigate('/subscription')}>
        {t('freeInferenceMessageModal.button')}
      </div>
    </BaseModalLayout>
  );
}
