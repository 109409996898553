import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useStyles } from './TTSHistoryPage.styles';

import moment from 'moment';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import ReactTimeAgo from 'react-time-ago';

import { ButtonGroup, Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import DownloadModal from '../layout/DownloadModal';

import ConfirmationModal from '../layout/ConfirmationModal';
import EditLabelModal from '../layout/EditLabelModal';
import EditCommunityModal from '../layout/EditCommunityModal';
import HtmlTooltip from '../HtmlTooltip';
import tooltipImg from '../../img/tooltip.png';
import {
  updateTTSTrainingById,
  updateTTSInferenceById,
  fetchTTSInferencesChunk,
  fetchTTSTrainingsChunk,
  getTTSInferenceBlob,
} from '../../services/page.services';
import HtmlInfoIcon from '../HtmlInfoIcon.js';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import infoIconImg from '../../img/infoIcon.png';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactJkMusicPlayer from 'react-jinke-music-player';
import { checkValidLoginStatus } from '../../utils/user.utils';
import CircularProgress from '@mui/material/CircularProgress';
import { showMessageV2 } from '../../utils/page.utils.js';

function TTSHistoryPage() {
  const { t } = useTranslation();
  const user = useSelector(state => state.user);
  const userId = user?.id;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [alignment, setAlignment] = useState('speech');
  //initData
  const [data, setData] = useState([]);
  const [communityItemsLiked, setCommunityItemsLiked] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  //query
  const [query, setQuery] = useState('');
  const [communityQuery, setCommunityQuery] = useState('');

  //play
  const [selectedAudio, setSelectedAudio] = React.useState();
  const [playing, setPlaying] = useState(false);
  const [selectedCommunityId, setSelectedCommunityId] = useState('');

  //pagination
  const chunk = 50;
  const [chunkPage, setChunkPage] = useState(0);
  const [length, setLength] = useState(0);
  const controllerRef = useRef(null);
  const [paginationInfo, setPaginationInfo] = useState({});

  //modal
  const [downloadModalProps, setDownloadModalProps] = useState({});
  const [editModalProps, setEditModalProps] = useState({});
  const [confirmationModalProps, setConfirmationModalProps] = useState({});
  const [downloadingId, setDownloadingId] = useState();

  useEffect(() => {
    const debounced = _.debounce(() => initData(false), 500);
    if (userId) {
      debounced();
    }

    return () => {
      debounced.cancel();
    };
  }, [userId, alignment, query]);

  const initData = async scroll => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();
    const signal = controllerRef.current.signal;

    try {
      if (alignment === 'voice') {
        let trainingsChunk;
        if (scroll) {
          trainingsChunk = await fetchTTSTrainingsChunk(chunk, chunkPage, query, signal);
        } else {
          trainingsChunk = await fetchTTSTrainingsChunk(chunk, 0, query, signal);
        }
        let trainings = trainingsChunk.rows;

        const cleanedData = trainings.map(({ id, label, status, createdAt, tier, shared, purchaseId }) => {
          return {
            id,
            label,
            createdAt: moment(createdAt).format('MM/DD/YY hh:mm A'),
            status,
            shared,
            tag: '',
          };
        });

        if (scroll) {
          setData([...data, ...cleanedData]);
          setChunkPage(prevChunkPage => prevChunkPage + 1);
        } else {
          setData(cleanedData);
          setChunkPage(1);
        }
        setLength(trainingsChunk.count);
      } else if (alignment === 'speech') {
        let inferencesChunk;
        if (scroll) {
          inferencesChunk = await fetchTTSInferencesChunk(chunk, chunkPage, query, signal);
        } else {
          inferencesChunk = await fetchTTSInferencesChunk(chunk, 0, query, signal);
        }
        let inferences = inferencesChunk.rows;
        console.log(inferences);
        const cleanedData = inferences.map(
          ({
            ttsVoiceId,
            id,
            label,
            status,
            createdAt,
            needMdx,
            outputFormat,
            tier,
            deletedAt,
            sourceLabel,
            purchaseId,
            ttsVoice,
            variability,
            similarity,
          }) => {
            return {
              ttsTrainingId: ttsVoiceId,
              id,
              label,
              createdAt: moment(createdAt).format('MM/DD/YY hh:mm A'),
              status,
              needMdx,
              outputFormat,
              tag: '',
              deletedAt,
              sourceLabel,
              trainingLabel: ttsVoice ? ttsVoice.label : 'AI voice not found',
              variability,
              similarity,
            };
          }
        );

        if (scroll) {
          setData([...data, ...cleanedData]);
          setChunkPage(prevChunkPage => prevChunkPage + 1);
        } else {
          setData(cleanedData);
          setChunkPage(1);
        }
        setLength(inferencesChunk.count);
      }
    } catch (err) {
      if (err.name != 'CanceledError') {
        showMessageV2(dispatch, t('historyTab.modal.error'), { reloadOnClose: true });
      }
    }
  };

  const getBlobFromS3Url = async url => {
    const res = await fetch(url);
    return res.blob();
  };

  const handleDelete = async (id, type, alignment) => {
    setConfirmationModalProps({
      open: true,
      message:
        alignment === 'voice' && type !== 'FAILED'
          ? t('historyTab.confirmModelDelete')
          : (alignment === 'speech' || alignment === 'community') && type !== 'FAILED'
            ? t('historyTab.confirmInferenceDelete')
            : t('historyTab.confirmVocalExtractDelete'),
      onConfirmation: async response => {
        if (response === true) {
          setConfirmationModalProps({ open: true, loading: true });
          if (alignment === 'voice') {
            await updateTTSTrainingById(id, { deletedAt: moment() });
          } else if (alignment === 'speech') {
            await updateTTSInferenceById(id, { deletedAt: moment() });
          }
          if (alignment !== 'community') {
            let copiedData = [...data];
            copiedData = copiedData.filter(obj => obj.id !== id);
            setData(copiedData);
          } else {
            //let copiedCommunityItems = [...communityItems];
            const copiedCommunityItems = data.filter(item => {
              return item.id !== id;
            });
            setData(copiedCommunityItems);
            setFilteredData(copiedCommunityItems);
          }
        }
        setConfirmationModalProps({ open: false });
      },
      onClose: () => setConfirmationModalProps({ open: false }),
    });
  };

  const handleEdit = async (id, label, alignment) => {
    setEditModalProps({
      open: true,
      label,
      alignment,
      onEdit: async changedLabel => {
        setEditModalProps({ open: true, loading: true });
        if (alignment === 'voice') {
          await updateTTSTrainingById(id, { label: changedLabel });
        } else if (alignment === 'speech') {
          await updateTTSInferenceById(id, { label: changedLabel });
        } else if (alignment === 'community') {
          await updateTTSCommunityItemById(id, { label: changedLabel });
        } else {
          // alignment === "vocalExtract"
          await updateVocalExtractById(id, { label: changedLabel });
        }
        let copiedData = data.map(obj => {
          if (obj.id === id) {
            return { ...obj, label: changedLabel };
          }
          return obj;
        });
        setData(copiedData);

        if (alignment === 'community') {
          setData(copiedData);
          let copiedFilteredData = filteredData.map(obj => {
            if (obj.id === id) {
              return { ...obj, label: changedLabel };
            }
            return obj;
          });
          setData(copiedData);
          setFilteredData(copiedFilteredData);
        }
        setEditModalProps({ open: false });
      },
      onClose: () => {
        setEditModalProps({ open: false });
      },
    });
  };

  const handleDownload = async (id, needMdx, label, alignment, outputFormat, tag) => {
    setDownloadingId(id);
    const tempLink = document.createElement('a');
    const inferenceBlob = await getTTSInferenceBlob(id);
    const url = URL.createObjectURL(inferenceBlob.slice(0, inferenceBlob.size, `audio/mpeg`));
    tempLink.href = url;
    tempLink.download = label.replaceAll(' ', '_') + '.mp3';
    if (tempLink.href) {
      tempLink.click();
      URL.revokeObjectURL(tempLink.href);
    }
    setDownloadingId(null);
  };

  const classes = useStyles();
  return (
    <>
      <DownloadModal {...downloadModalProps} />
      <ConfirmationModal {...confirmationModalProps} />
      <EditLabelModal {...editModalProps} />
      <div className={classes.pageTitle}>{t('ttsHistoryTab.title')}</div>

      <div className={classes.separater} />
      <div className={classes.inputSourceBarContainer}>
        <ButtonGroup className={classes.inputSourceButtonContainer}>
          <Button
            className={`${classes.inputSourceButton} ${alignment == 'speech' ? classes.selected : ''}`}
            onClick={() => {
              if (alignment != 'speech') {
                setAlignment('speech');
                setData([]);
                setQuery('');
              }
            }}
            style={{ fontSize: 'inherit' }}
          >
            {t('ttsHistoryTab.tabs.inference.title')}
          </Button>
          <Button
            className={`${classes.inputSourceButton} ${alignment == 'voice' ? classes.selected : ''}`}
            onClick={() => {
              if (alignment != 'voice') {
                setAlignment('voice');
                setData([]);
                setQuery('');
              }
            }}
            style={{ fontSize: 'inherit' }}
          >
            {t('ttsHistoryTab.tabs.training.title')}
          </Button>
        </ButtonGroup>
      </div>

      <input
        className={classes.searchbar}
        placeholder={t('historyTab.searchbar')}
        value={query}
        onChange={query => setQuery(query.target.value)}
      />

      {/*alignment === "community" && (
        <input
          className={classes.searchbar}
          placeholder={t('historyTab.searchbar')}
          value={communityQuery}
          onChange={communityQuery => searchItems(communityQuery.target.value)}
        />
      )*/}

      <InfiniteScroll
        className={classes.infiniteScroll}
        dataLength={data.length}
        next={() => initData(true)}
        hasMore={data.length < length}
        loader={<div className={classes.pagination}>{t('historyTab.pagination.loader')}</div>}
      >
        {data
          .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
          .map(
            ({ id, label, createdAt, status, needMdx, tag, outputFormat, trainingLabel, variability, similarity }) => {
              return (
                <div
                  className={`${classes.card} ${alignment === 'speech' && classes.clickableCard}`}
                  onClick={() => {
                    if (alignment === 'speech') {
                      if (status === 'COMPLETE') {
                        dispatch(appActions.selectEditorSource({ id, label, outputFormat, alignment }));
                        navigate('/video-editor');
                      }
                    }
                  }}
                >
                  <div className={classes.cardLabel}>
                    {label} {tag}
                    <EditIcon
                      onClick={event => {
                        event.stopPropagation();
                        handleEdit(id, label, alignment);
                      }}
                      className={classes.editIcon}
                    />
                  </div>
                  <div className={classes.cardTimestamp}>{createdAt}</div>
                  {status === 'FAILED' && <div className={classes.cardStatus}>{t('historyTab.failed')}</div>}
                  {status === 'PENDING' && <div className={classes.cardStatus}>{t('historyTab.inProgress')}</div>}
                  {alignment === 'speech' && status === 'COMPLETE' && downloadingId === id && (
                    <CircularProgress
                      style={{
                        width: '1rem',
                        height: '1rem',
                        marginTop: '0.4rem',
                        marginLeft: '0.75rem',
                        marginRight: '0.75rem',
                      }}
                    />
                  )}
                  {downloadingId !== id && alignment == 'speech' && status === 'COMPLETE' && (
                    <div className={classes.cardAction}>
                      <DownloadIcon
                        onClick={event => {
                          event.stopPropagation();
                          handleDownload(id, needMdx, label, alignment, outputFormat, tag);
                        }}
                      />
                    </div>
                  )}
                  {alignment == 'speech' && status === 'COMPLETE' && (
                    <div className={classes.infoIconAction}>
                      <HtmlInfoIcon
                        title={
                          <div className={classes.infoIconContainer}>
                            <div className={classes.infoIconTextContainer}>
                              <div className={classes.infoIconTextLine}>
                                <div className={classes.infoIconTextBold}>{t('ttsHistoryTab.infoIcon.voice')}</div>
                                <div className={classes.infoIconText}>: {trainingLabel}</div>
                              </div>
                              <div className={classes.infoIconTextLine}>
                                <div className={classes.infoIconTextBold}>
                                  {t('ttsHistoryTab.infoIcon.variability')}
                                </div>
                                <div className={classes.infoIconText}>: {variability}%</div>
                              </div>
                              <div className={classes.infoIconTextLine}>
                                <div className={classes.infoIconTextBold}>{t('ttsHistoryTab.infoIcon.similarity')}</div>
                                <div className={classes.infoIconText}>: {similarity}%</div>
                              </div>
                            </div>
                          </div>
                        }
                      >
                        <img className={classes.infoIconImg} src={infoIconImg} alt="tooltip-img" />
                      </HtmlInfoIcon>
                    </div>
                  )}

                  {(status === 'COMPLETE' || status === 'FAILED') && (
                    <div className={classes.cardAction}>
                      <DeleteIcon
                        onClick={event => {
                          event.stopPropagation();
                          handleDelete(id, status, alignment);
                        }}
                      />
                    </div>
                  )}
                </div>
              );
            }
          )}
      </InfiniteScroll>
    </>
  );
}
export default TTSHistoryPage;
