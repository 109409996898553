import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { useStyles } from './FaqPage.style';

import plusImg from '../../img/drawer-plus.png';
import minusImg from '../../img/drawer-minus.png';
import glassImg from '../../img/magnifying_glass.png';

import OutlinedInput from '@material-ui/core/OutlinedInput';

function FaqPage() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const [filteredFaq, setFilteredFaq] = useState([]);
  const handleLanguageChanged = function () {
    setFilteredFaq(t('faqTab.faqs', { returnObjects: true }));
  };
  useEffect(() => handleLanguageChanged(), []);
  useEffect(() => {
    i18n.on('languageChanged', handleLanguageChanged);
    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, [handleLanguageChanged]);

  function showHide(idx) {
    const newFilteredFaq = [];
    filteredFaq.forEach((faq, index) => {
      index === idx ? (faq['display'] = !faq['display']) : null;
      newFilteredFaq.push(faq);
    });
    setFilteredFaq(newFilteredFaq);
  }

  function titleIncludes(list, keyword) {
    let check = false;
    list?.forEach(m => {
      if (
        m.question.toLocaleLowerCase().includes(keyword) ||
        m.answers.toString().toLocaleLowerCase().includes(keyword)
      ) {
        check = true;
      }
    });
    return check;
  }

  const searchInfo = e => {
    var keyword = e.target.value.toLocaleLowerCase();
    const newFilteredFaq = [];
    filteredFaq?.forEach(faq => {
      if (faq.title?.toLocaleLowerCase().includes(keyword) || titleIncludes(faq.qnas, keyword)) {
        faq.display = true;
      } else {
        faq.display = false;
      }
      newFilteredFaq.push(faq);
    });
    if (keyword == '') {
      newFilteredFaq.forEach(faq => (faq['display'] = false));
    }
    setFilteredFaq(newFilteredFaq);
  };

  function answerDivs(answerList) {
    return (
      <div className={classes.answerBlock}>
        {answerList.map(answer => {
          return <li className={classes.answer}>{answer}</li>;
        })}
      </div>
    );
  }

  function faqDivs(filteredFaq) {
    return filteredFaq?.map(({ title, qnas, display }, index) => (
      <div>
        <div
          className={`${display ? classes.openedFaqTitle : classes.faqTitle}`}
          onClick={() => {
            showHide(index);
          }}
        >
          <div>{title}</div>
          <img className={classes.tooltipImg} src={display ? minusImg : plusImg} alt="expand-img" />
        </div>

        <div className={`${classes.qnaBlock} ${display ? classes.openedDiv : classes.closedDiv}`}>
          {qnas.map(({ question, answers }, index) => {
            return (
              <div>
                <div className={classes.qnaTitle}>
                  {index + 1}. {question}
                </div>
                {answerDivs(answers)}
              </div>
            );
          })}
        </div>
        <div className={classes.separater2} />
      </div>
    ));
  }

  return (
    <>
      <div className={classes.pageTitle}>
        {t('faqTab.title')} {i18n.language === 'ko' ? `(FAQ)` : null}
      </div>
      <div className={classes.separater} />

      <div className={classes.faqsBlock}>
        <div className={classes.searchFrame}>
          <OutlinedInput className={classes.searchInput} onChange={searchInfo} placeholder={t('faqTab.placeholder')} />
          <img src={glassImg} className={classes.glass} />
        </div>
        {faqDivs(filteredFaq)}
      </div>
    </>
  );
}

export default FaqPage;
