import Modal from '@material-ui/core/Modal';
import { useStyles } from './TTISettingModal.styles.js';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Slider from '@material-ui/core/Slider';
import HtmlTooltip from '../HtmlTooltip';
import tooltipImg from '../../img/tooltip.png';
import { useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

export default function TTISettingModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { open } = props;
  const [ratio, setRatio] = useState(props.ratio);
  const [size, setSize] = useState(props.size);
  const [quality, setQuality] = useState(props.quality);
  const [quantity, setQuantity] = useState(props.quantity);
  const [cfgScale, setCfgScale] = useState(props.cfgScale);
  const [watermark, setWatermark] = useState(props.watermark);

  const [showUpgrade, setShowUpgrade] = useState(false);

  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const userType = user.subscription?.type;

  // Run this whenever userId(whenver the page loads for the first time), query, or alignment change
  const [fontStyle, setFontStyle] = useState('TheJamsil');

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  const marks = [
    {
      value: 0,
    },
    {
      value: 50,
    },
    {
      value: 100,
    },
  ];

  const quantityMarks = [
    {
      value: 0,
    },
    {
      value: 35,
    },
    {
      value: 65,
    },
    {
      value: 100,
    },
  ];

  const sizeMap = [
    { value: 'small', setting: 0, label: t('ttiInferenceTab.modal.small') },
    { value: 'medium', setting: 50, label: t('ttiInferenceTab.modal.medium') },
    { value: 'large', setting: 100, label: t('ttiInferenceTab.modal.large') },
  ];

  const qualityMap = [
    { value: 'low', setting: 0, label: t('ttiInferenceTab.modal.low') },
    { value: 'moderate', setting: 50, label: t('ttiInferenceTab.modal.moderate') },
    { value: 'high', setting: 100, label: t('ttiInferenceTab.modal.high') },
  ];

  const cfgScaleMap = [
    { value: 2.0, setting: 0, label: t('ttiInferenceTab.modal.creative') },
    { value: 3.5, setting: 50, label: t('ttiInferenceTab.modal.balanced') },
    { value: 5.0, setting: 100, label: t('ttiInferenceTab.modal.strict') },
  ];

  const quantityMap = [
    { value: 1, setting: 0, label: 1 },
    { value: 2, setting: 35, label: 2 },
    { value: 3, setting: 65, label: 3 },
    { value: 4, setting: 100, label: 4 },
  ];

  const handleWatermark = setting => {
    const isRestricted = (userType === 'FREE' || userType === 'BASIC') && setting === false;
    if (isRestricted) {
      setShowUpgrade(true);
      return;
    }

    setWatermark(setting);
    props.setWatermark(setting);
  };

  const handleSize = setting => {
    const obj = sizeMap.find(obj => obj.setting === setting);
    const isRestricted = (userType === 'FREE' || userType === 'BASIC') && setting === 100;
    if (isRestricted) {
      setShowUpgrade(true);
      return;
    }

    setSize(obj);
    props.setSize(obj);
  };

  const handleSizeLabel = setting => {
    const obj = sizeMap.find(obj => obj.setting === setting);
    const label = obj ? obj.label : '';
    return <div>{label}</div>;
  };

  const handleQuality = setting => {
    const obj = qualityMap.find(obj => obj.setting === setting);
    const isRestricted = (userType === 'FREE' || userType === 'BASIC') && setting !== 0;
    if (isRestricted) {
      setShowUpgrade(true);
      return;
    }

    setQuality(obj);
    props.setQuality(obj);
  };

  const handleQualityLabel = setting => {
    const obj = qualityMap.find(obj => obj.setting === setting);
    const label = obj ? obj.label : '';
    return <div>{label}</div>;
  };

  const handleCfgScale = setting => {
    const obj = cfgScaleMap.find(obj => obj.setting === setting);
    const isRestricted = (userType === 'FREE' || userType === 'BASIC') && setting === 100;
    if (isRestricted) {
      setShowUpgrade(true);
      return;
    }

    setCfgScale(obj);
    props.setCfgScale(obj);
  };

  const handleCfgScaleLabel = setting => {
    const obj = cfgScaleMap.find(obj => obj.setting === setting);
    const label = obj ? obj.label : '';
    return <div>{label}</div>;
  };

  const handleQuantity = setting => {
    const obj = quantityMap.find(obj => obj.setting === setting);
    const isRestricted = (userType === 'FREE' || userType === 'BASIC') && setting !== 0;
    if (isRestricted) {
      setShowUpgrade(true);
      return;
    }

    setQuantity(obj);
    props.setQuantity(obj);
  };

  const handleQuantityLabel = setting => {
    const obj = quantityMap.find(obj => obj.setting === setting);
    const label = obj ? obj.label : '';
    return <div>{label}</div>;
  };

  return (
    <Modal
      open={!!open}
      onClose={() => {
        props.onSettingModalClose();
      }}
      className={`${fontStyle}`}
    >
      <div className={classes.container}>
        <div className={classes.modalTitle}>{t('ttiInferenceTab.modal.setting')}</div>
        <div className={classes.separater} />
        <div className={classes.inputContainer}>
          <div className={classes.inputNameContainer}>
            <div className={classes.inputName}>{t('ttiInferenceTab.modal.watermark')}</div>
            <HtmlTooltip
              title={
                <div className={'globalTooltipContainer'}>
                  <div className={'globalTooltipTextContainer'}>
                    <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                    <div className={'globalTooltipText'}>{t('ttiInferenceTab.tooltip.watermarkContents.0')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                    <div className={'globalTooltipText'}>{t('ttiInferenceTab.tooltip.watermarkContents.1')}</div>
                  </div>
                </div>
              }
            >
              <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
            </HtmlTooltip>
          </div>
          <ButtonGroup className={classes.inputButtonContainer}>
            <Button
              className={`${classes.inputButton} ${watermark ? classes.selected : ''}`}
              onClick={() => handleWatermark(true)}
            >
              {t('ttiInferenceTab.modal.on')}
            </Button>
            <Button
              className={`${classes.inputButton} ${!watermark ? classes.selected : ''}`}
              onClick={() => handleWatermark(false)}
            >
              {t('ttiInferenceTab.modal.off')}
            </Button>
          </ButtonGroup>
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.inputNameContainer}>
            <div className={classes.inputName}>{t('ttiInferenceTab.modal.ratio')}</div>
            <HtmlTooltip
              title={
                <div className={'globalTooltipContainer'}>
                  <div className={'globalTooltipTextContainer'}>
                    <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                    <div className={'globalTooltipText'}>{t('ttiInferenceTab.tooltip.ratioContents.0')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                    <div className={'globalTooltipText'}>{t('ttiInferenceTab.tooltip.ratioContents.1')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                    <div className={'globalTooltipText'}>{t('ttiInferenceTab.tooltip.ratioContents.2')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                    <div className={'globalTooltipText'}>{t('ttiInferenceTab.tooltip.ratioContents.3')}</div>
                  </div>
                </div>
              }
            >
              <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
            </HtmlTooltip>
          </div>
          <ButtonGroup className={classes.inputButtonContainer}>
            <Button
              className={`${classes.inputButton} ${ratio == 'horizontal' ? classes.selected : ''}`}
              onClick={() => {
                setRatio('horizontal'), props.setRatio('horizontal');
              }}
            >
              {t('ttiInferenceTab.modal.horizontal')}
            </Button>
            <Button
              className={`${classes.inputButton} ${ratio == 'vertical' ? classes.selected : ''}`}
              onClick={() => {
                setRatio('vertical'), props.setRatio('vertical');
              }}
            >
              {t('ttiInferenceTab.modal.vertical')}
            </Button>
            <Button
              className={`${classes.inputButton} ${ratio == 'square' ? classes.selected : ''}`}
              onClick={() => {
                setRatio('square'), props.setRatio('square');
              }}
            >
              {t('ttiInferenceTab.modal.square')}
            </Button>
          </ButtonGroup>
        </div>
        <div className={classes.separater} />
        <div className={classes.inputContainer}>
          <div className={classes.inputNameContainer}>
            <div className={classes.inputName}>{t('ttiInferenceTab.modal.size')}</div>
            <HtmlTooltip
              title={
                <div className={'globalTooltipContainer'}>
                  <div className={'globalTooltipTextContainer'}>
                    <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                    <div className={classes.tooltipTextCustom}>{t('ttiInferenceTab.tooltip.sizeContents.0')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                    <div className={classes.tooltipTextCustom}>{t('ttiInferenceTab.tooltip.sizeContents.1')}</div>
                  </div>
                  <div className={classes.indentation}>
                    <div className={'globalTooltipTextContainer'}>
                      <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                      <div className={classes.tooltipTextCustom}>{t('ttiInferenceTab.tooltip.sizeContents.2')}</div>
                    </div>
                    <div className={'globalTooltipTextContainer'}>
                      <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                      <div className={classes.tooltipTextCustom}>{t('ttiInferenceTab.tooltip.sizeContents.3')}</div>
                    </div>
                    <div className={'globalTooltipTextContainer'}>
                      <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                      <div className={classes.tooltipTextCustom}>{t('ttiInferenceTab.tooltip.sizeContents.4')}</div>
                    </div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                    <div className={classes.tooltipTextCustom}>{t('ttiInferenceTab.tooltip.sizeContents.5')}</div>
                  </div>
                  <div className={classes.indentation}>
                    <div className={'globalTooltipTextContainer'}>
                      <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                      <div className={classes.tooltipTextCustom}>{t('ttiInferenceTab.tooltip.sizeContents.6')}</div>
                    </div>
                    <div className={'globalTooltipTextContainer'}>
                      <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                      <div className={classes.tooltipTextCustom}>{t('ttiInferenceTab.tooltip.sizeContents.7')}</div>
                    </div>
                    <div className={'globalTooltipTextContainer'}>
                      <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                      <div className={classes.tooltipTextCustom}>{t('ttiInferenceTab.tooltip.sizeContents.8')}</div>
                    </div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                    <div className={classes.tooltipTextCustom}>{t('ttiInferenceTab.tooltip.sizeContents.9')}</div>
                  </div>
                  <div className={classes.indentation}>
                    <div className={'globalTooltipTextContainer'}>
                      <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                      <div className={classes.tooltipTextCustom}>{t('ttiInferenceTab.tooltip.sizeContents.10')}</div>
                    </div>
                    <div className={'globalTooltipTextContainer'}>
                      <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                      <div className={classes.tooltipTextCustom}>{t('ttiInferenceTab.tooltip.sizeContents.11')}</div>
                    </div>
                    <div className={'globalTooltipTextContainer'}>
                      <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                      <div className={classes.tooltipTextCustom}>{t('ttiInferenceTab.tooltip.sizeContents.12')}</div>
                    </div>
                  </div>
                </div>
              }
            >
              <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
            </HtmlTooltip>
          </div>
          <div className={classes.sliderContainer}>
            <Slider
              className={classes.slider}
              aria-label="size-values"
              step={null}
              value={size.setting}
              valueLabelDisplay="on"
              marks={marks}
              valueLabelFormat={val => handleSizeLabel(val)}
              onChange={(e, val) => handleSize(val)}
            />
          </div>
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.inputNameContainer}>
            <div className={classes.inputName}>{t('ttiInferenceTab.modal.guidance')}</div>
            <HtmlTooltip
              title={
                <div className={'globalTooltipContainer'}>
                  <div className={'globalTooltipTextContainer'}>
                    <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                    <div className={'globalTooltipText'}>{t('ttiInferenceTab.tooltip.guidanceContents.0')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                    <div className={'globalTooltipText'}>{t('ttiInferenceTab.tooltip.guidanceContents.1')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                    <div className={'globalTooltipText'}>{t('ttiInferenceTab.tooltip.guidanceContents.2')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                    <div className={'globalTooltipText'}>{t('ttiInferenceTab.tooltip.guidanceContents.3')}</div>
                  </div>
                </div>
              }
            >
              <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
            </HtmlTooltip>
          </div>
          <div className={classes.sliderContainer}>
            <Slider
              className={classes.slider}
              aria-label="guidance-values"
              step={null}
              value={cfgScale.setting}
              valueLabelDisplay="on"
              marks={marks}
              valueLabelFormat={val => handleCfgScaleLabel(val)}
              onChange={(e, val) => handleCfgScale(val)}
            />
          </div>
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.inputNameContainer}>
            <div className={classes.inputName}>{t('ttiInferenceTab.modal.quality')}</div>
            <HtmlTooltip
              title={
                <div className={'globalTooltipContainer'}>
                  <div className={'globalTooltipTextContainer'}>
                    <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                    <div className={'globalTooltipText'}>{t('ttiInferenceTab.tooltip.stepsConetents.0')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                    <div className={'globalTooltipText'}>{t('ttiInferenceTab.tooltip.stepsConetents.1')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                    <div className={'globalTooltipText'}>{t('ttiInferenceTab.tooltip.stepsConetents.2')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                    <div className={'globalTooltipText'}>{t('ttiInferenceTab.tooltip.stepsConetents.3')}</div>
                  </div>
                </div>
              }
            >
              <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
            </HtmlTooltip>
          </div>
          <div className={classes.sliderContainer}>
            <Slider
              className={classes.slider}
              aria-label="steps-values"
              step={null}
              value={quality.setting}
              valueLabelDisplay="on"
              marks={marks}
              valueLabelFormat={val => handleQualityLabel(val)}
              onChange={(e, val) => handleQuality(val)}
            />
          </div>
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.inputNameContainer}>
            <div className={classes.inputName}>{t('ttiInferenceTab.modal.quantity')}</div>
            <HtmlTooltip
              title={
                <div className={'globalTooltipContainer'}>
                  <div className={'globalTooltipTextContainer'}>
                    <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                    <div className={'globalTooltipText'}>{t('ttiInferenceTab.tooltip.batchSizeContents.0')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                    <div className={'globalTooltipText'}>{t('ttiInferenceTab.tooltip.batchSizeContents.1')}</div>
                  </div>
                  <div className={classes.indentation}>
                    <div className={'globalTooltipTextContainer'}>
                      <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                      <div className={'globalTooltipText'}>{t('ttiInferenceTab.tooltip.batchSizeContents.2')}</div>
                    </div>
                    <div className={'globalTooltipTextContainer'}>
                      <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                      <div className={'globalTooltipText'}>{t('ttiInferenceTab.tooltip.batchSizeContents.3')}</div>
                    </div>
                    <div className={'globalTooltipTextContainer'}>
                      <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                      <div className={'globalTooltipText'}>{t('ttiInferenceTab.tooltip.batchSizeContents.4')}</div>
                    </div>
                    <div className={'globalTooltipTextContainer'}>
                      <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                      <div className={'globalTooltipText'}>{t('ttiInferenceTab.tooltip.batchSizeContents.5')}</div>
                    </div>
                  </div>
                </div>
              }
            >
              <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
            </HtmlTooltip>
          </div>
          <div className={classes.sliderContainer}>
            <Slider
              className={classes.slider}
              aria-label="quantity-values"
              step={null}
              value={quantity.setting}
              valueLabelDisplay="on"
              marks={quantityMarks}
              valueLabelFormat={val => handleQuantityLabel(val)}
              onChange={(e, val) => handleQuantity(val)}
            />
          </div>
        </div>

        <Dialog
          open={showUpgrade}
          onClose={() => setShowUpgrade(false)}
          className={`${fontStyle} ${classes.upgradeDialog}`}
        >
          <div className={classes.dialogTitle}>{t('ttiInferenceTab.dialog.title')}</div>
          <div className={classes.dialogText}>{t('ttiInferenceTab.dialog.text')}</div>
          <div className={classes.upgradeButton} onClick={() => navigate('/subscription')}>
            {t('mixingModal.upgradeButton.0')}
            <strong>{t('mixingModal.upgradeButton.1')}</strong>
          </div>
        </Dialog>
      </div>
    </Modal>
  );
}
