import React, { useRef } from 'react';

import { Timeline } from '@xzdarcy/react-timeline-editor';

import Button from '@material-ui/core/Button';
import { Delete, Movie, Audiotrack, Image, TextFields } from '@material-ui/icons';

const CustomScale = props => {
  const { scale } = props;
  const min = parseInt(scale / 60 + '');
  const second = ((scale % 60) + '').padStart(2, '0');
  return <>{`${min}:${second}`}</>;
};

const TimelineEditor = ({
  timelineData: tlData,
  onPreviewTime,
  onOffsetChange,
  onDurationChange,
  getActionRender,
  timelineState,
  onClickActionOnly,
  onDelete,
  snapMode,
  scale,
  scaleWidth,
  minScaleCount,
  maxScaleCount,
  onDragging,
  classes,
}) => {
  const domRef = useRef();

  return (
    <div className={classes.timelineEditor}>
      <div
        className={classes.timelineList}
        ref={domRef}
        style={{ overflow: 'overlay' }}
        onScroll={e => {
          const target = e.target;
          timelineState.current.setScrollTop(target.scrollTop);
        }}
      >
        {tlData
          .sort((a, b) => {
            const order = ['text', 'image', 'video', 'audio'];
            return order.indexOf(a.type) - order.indexOf(b.type);
          })
          .map(track => {
            const clipIcon =
              track.type === 'video' ? (
                <Movie style={{ minWidth: 0 }} />
              ) : track.type === 'audio' ? (
                <Audiotrack style={{ minWidth: 0 }} />
              ) : track.type === 'image' ? (
                <Image style={{ minWidth: 0 }} />
              ) : track.type === 'text' ? (
                <TextFields style={{ minWidth: 0 }} />
              ) : (
                ''
              );

            return (
              <div className={classes.timelineListItem} key={track.id}>
                <Button size="small" className={classes.deleteButton} onClick={() => onDelete(track)}>
                  <Delete fontSize="small" />
                </Button>
                {clipIcon}
              </div>
            );
          })}
      </div>
      <Timeline
        className={classes.timeline}
        ref={v => {
          if (v == null) return;
          timelineState.current = v;
        }}
        onChange={() => {}}
        startLeft={12}
        scale={scale}
        scaleWidth={scaleWidth}
        minScaleCount={minScaleCount}
        maxScaleCount={maxScaleCount}
        editorData={tlData}
        rowHeight={40}
        dragLine={snapMode}
        autoScroll
        gridSnap={false}
        scaleSplitCount={4}
        getScaleRender={scale => <CustomScale scale={scale} />}
        onScroll={({ scrollTop }) => {
          domRef.current.scrollTop = scrollTop;
        }}
        onClickTimeArea={time => {
          onPreviewTime(time);
        }}
        onActionResizeEnd={({ dir, action }) => {
          onDurationChange({ dir, action });
        }}
        onActionMoveEnd={({ action }) => {
          onOffsetChange(action);
        }}
        onActionResizing={({ dir, action, start, end }) => {
          if (action.effectId === 'video' || action.effectId === 'audio') {
            if (dir === 'left') {
              if (start <= action.offset - action.trimStart) {
                return false;
              }
            }
            if (dir === 'right') {
              if (end - start + action.trimStart >= action.maxDuration) {
                return false;
              }
            }
          }
          if (end - start < 1) return false;
        }}
        onActionMoving={({ action }) => {
          if (action.end > 60) {
            return false;
          }
        }}
        onCursorDragStart={onDragging}
        onCursorDragEnd={time => {
          onPreviewTime(time);
          onDragging();
        }}
        onActionResizeStart={onDragging}
        onActionMoveStart={onDragging}
        onClickActionOnly={onClickActionOnly}
        getActionRender={getActionRender}
      />
    </div>
  );
};

export default TimelineEditor;
