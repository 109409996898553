import Modal from '@material-ui/core/Modal';
import { useStyles } from './SelectTTSVoiceModal.styles.js';

import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { appActions } from '../../actions/app.actions.js';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactTimeAgo from 'react-time-ago';
import ReactJkMusicPlayer from 'react-jinke-music-player';

import OutlinedInput from '@mui/material/OutlinedInput';
import { ButtonGroup, Button } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import { checkValidLoginStatus } from '../../utils/user.utils';
import { fetchTTSTrainingsChunk, fetchCommunityItems, fetchPagedTTSCommunityItems } from '../../services/page.services';

import HtmlInfoIcon from '../HtmlInfoIcon.js';
import infoIconImg from '../../img/infoIcon.png';
import coverImg from '../../img/cover.png';
import { showMessageV2 } from '../../utils/page.utils.js';

export default function SelectTTSVoiceModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //modal
  const { open, loading } = props;

  const user = useSelector(state => state.user);
  const userId = user?.id;
  //alignment
  const [voiceType, setVoiceType] = useState('community');

  //initData
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [communityData, setCommunityData] = useState([]);
  const [filteredCommunityData, setFilteredCommunityData] = useState([]);

  //Search
  const [query, setQuery] = useState('');
  const [communityQuery, setCommunityQuery] = useState('');

  //play
  const [selectedAudio, setSelectedAudio] = React.useState();
  const [playing, setPlaying] = useState(false);
  const [selectedCommunityId, setSelectedCommunityId] = useState('');

  //pagination
  const chunk = 50;
  const communityChunk = 150;
  const [chunkPage, setChunkPage] = useState(0);
  const [length, setLength] = useState(0);
  const controllerRef = useRef(null);
  const [paginationInfo, setPaginationInfo] = useState({});

  //Run this whenever userId(whenver the page loads for the first time), query, or alignment change
  const [fontStyle, setFontStyle] = useState('TheJamsil');

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  useEffect(() => {
    if (voiceType === 'my voices') {
      (async () => {
        await initData(false);
      })();
    }
  }, [userId, query]);

  useEffect(() => {
    if (voiceType === 'my voices') {
      (async () => {
        await initData(false);
      })();
    } else {
      (async () => {
        setCommunityQuery('');
      })();
    }
  }, [voiceType]);

  //add event listened to be triggered whenever the played song ends
  useEffect(() => {
    if (selectedAudio) {
      selectedAudio.addEventListener('ended', () => {
        setSelectedCommunityId('');
        setPlaying(false);
      });
    }
    return () => {
      if (selectedAudio) {
        selectedAudio.pause();
        selectedAudio.removeEventListener('ended', () => {
          setSelectedCommunityId = '';
          setPlaying(false);
        });
      }
    };
  }, [selectedAudio]);

  //when people click play button
  useEffect(() => {
    if (selectedAudio) {
      playing ? selectedAudio.play() : selectedAudio.pause();
    }
  }, [playing]);

  //search query for community items
  useEffect(() => {
    handleSearch();
  }, [userId, communityQuery]);

  const initPublicData = async scroll => {
    //setCommunityQuery("");

    //setSelectedTags([]);
    if (voiceType === 'community') {
      const communityItems = await fetchPagedTTSCommunityItems(1, communityChunk, communityQuery, '', '', userId);
      setCommunityData(communityItems.rows);
      setFilteredCommunityData(communityItems.rows);
      setPaginationInfo(communityItems.pagination);
    }
  };

  const fetchMoreData = async () => {
    try {
      if (voiceType === 'community') {
        const communityItems = await fetchPagedTTSCommunityItems(
          paginationInfo.next,
          communityChunk,
          communityQuery,
          selectedTags,
          selectedSortingMethod,
          userId
        );
        setCommunityData([...communityData, ...communityItems.rows]);
        setFilteredCommunityData([...communityData, ...communityItems.rows]);
        setPaginationInfo(communityItems.pagination);
      }
    } catch (err) {
      if (err.name != 'CanceledError') {
        showMessageV2(dispatch, t('historyTab.modal.error'), { reloadOnClose: true });
      }
    }
  };

  const initData = async scroll => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();
    const signal = controllerRef.current.signal;
    try {
      if (userId) {
        let trainingsChunk;
        if (scroll) {
          trainingsChunk = await fetchTTSTrainingsChunk(chunk, chunkPage, query, true, signal);
        } else {
          trainingsChunk = await fetchTTSTrainingsChunk(chunk, 0, query, true, signal);
        }
        let trainings = trainingsChunk.rows;

        const cleanedData = trainings.map(({ id, label, status, createdAt, tier, userId }) => {
          return {
            id,
            label,
            createdAt: moment(createdAt).format('MM/DD/YY hh:mm A'),
            status,
            tag: tier === 'FREE' ? `[${t('historyTab.freeVersionTag')}]` : '',
            userId,
          };
        });

        if (scroll) {
          setData([...data, ...cleanedData]);
          setChunkPage(prevChunkPage => prevChunkPage + 1);
        } else {
          setData(cleanedData);
          setChunkPage(1);
        }
        setLength(trainingsChunk.count);
      } else {
        setData([]);
        setChunkPage(0);
        setLength(0);
      }
    } catch (err) {
      console.log(err);
      if (err.name != 'CanceledError') {
        showMessageV2(dispatch, t('historyTab.modal.error'), { reloadOnClose: true });
      }
    }
  };

  //check if selected language is korean or not
  const translator = option => {
    if (t('languageDetector') === 'ko') {
      return option.korean;
    } else {
      return option.name;
    }
  };

  const getBlobFromS3Url = async url => {
    const res = await fetch(url);
    return res.blob();
  };

  const handleSearch = _.debounce(initPublicData, 300);

  //when a user clicks the voice card
  /*
  const onClick = (res) => {
    setPlaying(false);
    if (res) {
      props.onSelectVoice(label);
    } else props.onSelectVoiceModalClose();
  };
  */

  //when a user clicks the voice card

  const handleSelectVoice = (model, type) => {
    setPlaying(false);
    props.onSelectVoice(model, type);
  };

  return (
    <Modal
      open={!!open}
      onClose={() => {
        setPlaying(false);
        props.onSelectVoiceModalClose();
      }}
      disableEnforceFocus
      className={`${classes.modal} ${fontStyle}`}
    >
      <div id="scrollableDiv" className={classes.container}>
        {!loading && (
          <>
            <div className={classes.pageTitle}>{t('ttsInferenceTab.modal.selectModalTitle')}</div>
            <div className={classes.separater} />

            <ButtonGroup className={classes.inputSourceButtonContainer}>
              <Button
                className={`${classes.inputSourceButton} ${voiceType === 'community' ? classes.selected : ''}`}
                style={{ fontSize: 'inherit' }}
                onClick={() => setVoiceType('community')}
              >
                {t('communityTab.voiceOptions.0')}
              </Button>
              <Button
                className={`${classes.inputSourceButton} ${voiceType === 'my voices' ? classes.selected : ''}`}
                style={{ fontSize: 'inherit' }}
                onClick={() => setVoiceType('my voices')}
              >
                {t('communityTab.voiceOptions.1')}
              </Button>
            </ButtonGroup>

            {voiceType === 'my voices' && (
              <div className={classes.searchButtonContainer}>
                <Grid item xs={12} sm={12} md={12}>
                  <input
                    className={classes.searchbar}
                    placeholder={t('historyTab.searchbar')}
                    value={query}
                    onChange={query => setQuery(query.target.value)}
                  />
                </Grid>
              </div>
            )}

            {(voiceType === 'community' || voiceType === 'favorites') && (
              <div className={classes.searchButtonContainer}>
                <Grid item xs={12} sm={12} md={12}>
                  <input
                    className={classes.searchbar}
                    placeholder={t('historyTab.searchbar')}
                    value={communityQuery}
                    onChange={newValue => setCommunityQuery(newValue.target.value)}
                  />
                </Grid>
              </div>
            )}

            {voiceType === 'my voices' && (
              <InfiniteScroll
                className={classes.infiniteScroll}
                dataLength={data.length}
                next={() => initData(true)}
                hasMore={data.length < length}
                loader={
                  <div className={classes.pagination} key={0}>
                    {t('historyTab.pagination.loader')}
                  </div>
                }
                scrollableTarget="scrollableDiv"
              >
                <Grid container xs={12} sm={12} md={12} className={classes.cardGrid}>
                  <div className={classes.selectModelOuterContainer} onClick={() => navigate('/tts-train-voice')}>
                    <div className={classes.selectModelButton}>
                      <div className={classes.selectModelContainer}>
                        <div className={classes.dragAndDropText}>{t('ttsInferenceTab.modal.addNewModelText')}</div>
                        <AddIcon className={classes.addButtonImg} />
                      </div>
                    </div>
                  </div>
                  {data
                    .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
                    .map(
                      ({
                        id,
                        label,
                        createdAt,
                        status,
                        tag,
                        outputFormat,
                        sourceLabel,
                        trainingLabel,
                        userId,
                        username,
                        image,
                      }) => {
                        return (
                          <Grid
                            className={classes.card}
                            item
                            container
                            key={id}
                            onClick={() =>
                              handleSelectVoice(
                                {
                                  id,
                                  label,
                                  createdAt,
                                  status,
                                  trainingLabel,
                                  userId,
                                  username: user.name,
                                },
                                'my voices'
                              )
                            }
                          >
                            <Grid item xs="auto" sm={3} md={3}>
                              <img className={classes.coverImg} src={image ? image : coverImg} alt="cover-img" />
                            </Grid>
                            <Grid
                              container
                              className={classes.cardSecondColumn}
                              //style={{ border: "1px solid #fafafa" }}
                              item
                              xs
                              sm={9}
                              md={9}
                            >
                              <Grid container item>
                                <Grid item xs={10} sm={10} md={10}>
                                  <div className={classes.cardLabel}>{label}</div>
                                </Grid>
                                <Grid
                                  container
                                  item
                                  className={classes.playButtonGrid}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                  //style={{ border: "1px solid #fafafa" }}
                                ></Grid>
                              </Grid>
                              <Grid container item>
                                <div className={classes.cardUsername}>{user.name}</div>
                              </Grid>
                              <Grid container item>
                                <Grid
                                  container
                                  item
                                  xs={7}
                                  sm={7}
                                  md={7}
                                  style={{
                                    alignItems: 'flex-end',
                                    maxHeight: '2rem',
                                  }}
                                >
                                  <div
                                    className={classes.cardTimestamp}
                                    style={{
                                      marginBottom: '0.2rem',
                                    }}
                                  >
                                    <ReactTimeAgo date={createdAt} />
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      }
                    )}
                </Grid>
              </InfiniteScroll>
            )}
            {voiceType === 'community' && filteredCommunityData && (
              <InfiniteScroll
                className={classes.infiniteScroll}
                dataLength={filteredCommunityData.length}
                next={fetchMoreData}
                hasMore={paginationInfo.has_next}
                loader={<div className={classes.pagination}>{t('historyTab.pagination.loader')}</div>}
                scrollableTarget="scrollableDiv"
              >
                <Grid container className={classes.cardGrid}>
                  {filteredCommunityData.map(
                    ({
                      id,
                      label,
                      createdAt,
                      status,
                      tag,
                      outputFormat,
                      sourceLabel,
                      trainingLabel,
                      userId,
                      username,
                      image,
                    }) => {
                      return (
                        <Grid
                          className={classes.card}
                          item
                          container
                          key={id}
                          onClick={() =>
                            handleSelectVoice(
                              {
                                id,
                                label,
                                createdAt,
                                status,
                                trainingLabel,
                                userId,
                                username,
                              },
                              'my voices'
                            )
                          }
                        >
                          <Grid item xs="auto" sm={3} md={3}>
                            <img className={classes.coverImg} src={image ? image : coverImg} alt="cover-img" />
                          </Grid>
                          <Grid
                            container
                            className={classes.cardSecondColumn}
                            //style={{ border: "1px solid #fafafa" }}
                            item
                            xs
                            sm={9}
                            md={9}
                          >
                            <Grid container item>
                              <Grid item xs={10} sm={10} md={10}>
                                <div className={classes.cardLabel}>{label}</div>
                              </Grid>
                              <Grid
                                container
                                item
                                className={classes.playButtonGrid}
                                xs={2}
                                sm={2}
                                md={2}
                                //style={{ border: "1px solid #fafafa" }}
                              ></Grid>
                            </Grid>
                            <Grid container item>
                              <div className={classes.cardUsername}></div>
                            </Grid>
                            <Grid container item>
                              <Grid
                                container
                                item
                                xs={7}
                                sm={7}
                                md={7}
                                style={{
                                  alignItems: 'flex-end',
                                  maxHeight: '2rem',
                                }}
                              >
                                <div
                                  className={classes.cardTimestamp}
                                  style={{
                                    marginBottom: '0.2rem',
                                  }}
                                >
                                  <ReactTimeAgo date={createdAt} />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </InfiniteScroll>
            )}
          </>
        )}
        {loading && <CircularProgress className={classes.loading} />}
      </div>
    </Modal>
  );
}
