import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ReceiptIcon from '@mui/icons-material/Receipt';

import { getCurrencySymbol } from '../../utils/page.utils.js';

import { useStyles } from './PaymentHistoryModal.styles.js';
import BaseModalLayout from './BaseModalLayout.js';

const PaymentHistoryModal = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const text = t('paymentModal', { returnObjects: true });

  const openInvoice = url => window.open(url, '_blank');

  return (
    <BaseModalLayout open={props.open} onClose={props.onClose}>
      <div className={classes.title}>{text.title}</div>
      <div className={classes.separator} />
      <div className={classes.innerTitleContainer}>
        <div className={classes.dateElem}>{text.date}</div>
        <div className={classes.infoElem}>{text.info}</div>
        <div className={classes.amountElem}>{text.amount}</div>
      </div>
      {props.open &&
        props.payments.map(order => {
          return (
            <div className={classes.innerContainer}>
              <div className={classes.dateElem}>{moment(order.paidAt).format('MM/DD/YYYY')}</div>
              <div className={classes.infoElem}>{order.name}</div>
              <div className={classes.amountElem}>
                {getCurrencySymbol(order.currency)}
                {order.price}
                {order.receiptLink && (
                  <ReceiptIcon className={classes.downloadIcon} onClick={() => openInvoice(order.receiptLink)} />
                )}
              </div>
            </div>
          );
        })}
    </BaseModalLayout>
  );
};

export default PaymentHistoryModal;
